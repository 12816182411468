.form-add-reminder {

    .form-reminder--container {
        padding: $spc-x-small;
    }

    &--in-modal {
        .form-reminder--container {
            padding: $spc-tiny $spc-small $spc-x-small $spc-small;
        }

        .form-base__buttons .btn--tertiary, .form-base__buttons .btn--warning {
            margin-right: auto;
        }
    }
}