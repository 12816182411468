// Register

.register {
  &__account-exists {
    border-top: 2px solid rgb(227, 227, 227);
    margin-top: $spc-medium;
    padding-top: $spc-medium;
  }
}

.steps-indicator__container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  & > div {
    height: 7px;
    background-color: $b-nc-light;
    flex: 1 100%;
  }
  & > div:not(:last-child) {
    margin-right: 10px;
  }
  & > div.active {
    background-color: $b-mc-light;
  }
}
