/* .icon {
  background: red;
} */

img {
  max-width: 100%;
  height: auto;
}

.content {
  max-width: 1280px;
  margin: 0 auto;
}

.tile__icon {
  position: relative;
  min-height: 89px;
}

.notification-spot {
  display: block;
  background: $b-uc-warning;
  width: 2em;
  height: 2em;
  line-height: 2;
  border-radius: 100%;
  position: absolute;
  right: -1em;
  top: -1em;
  &--messages {
    position: inherit;
    width: 1.5em;
    height: 1.5em;
    float: left;
    line-height: 1.5;
    text-align: center;
    font-family: $secondary-font;
    font-weight: 800;
    color: #ffffff;
  }
}

.clearfix {
  @include clearfix();
}

.tile-brand {
  position: relative;
  h1 {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    text-align: center;
    font-size: 3.125rem;
    font-weight: 400;
    line-height: 1;
  }
  button {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.content-type {
  color: #ffffff;
  font-family: $secondary-font;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 2.25;
  span {
    padding: $spc-tiny;
    background: $b-mc-light;
  }
}

.dropdown {
  border: 1px solid #e0dede;
  border-left: $spc-tiny solid $b-mc-med;
  background-color: #fdfdfd;
  cursor: pointer;

  &__label {
    margin-left: 1.25rem;
    font-weight: 800;
    color: #333333;
  }
}

.tile-overlay {
  background: linear-gradient(rgba(20, 20, 20, 0.5), rgba(20, 20, 20, 0.5));
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.document__download__somrespir {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 45%;
  bottom: 0;
  h2 {
    color: black;
    font-weight: 700;
  }
  .pdf-size {
    color: #333333 !important;
    font-weight: 300 !important;
  }
}

.list-files {
  ul {
    margin-top: 0;
  }
}

.list-file {
  display: block;
  overflow: hidden;
  padding: 1rem;
  border-bottom: 1px solid #dedede;
  &__icon {
    float: left;
  }
  &__content {
    float: left;
    line-height: 4;
    margin-left: 1rem;
  }
  &__close {
    float: right;
    line-height: 4;
    cursor: pointer;
  }
}

.pdf__download {
  display: block;
  height: 6.5rem;
  width: 6.5rem;
  background: url(../assets/common/icon_pdf-download.svg) 50% 50% no-repeat;
  text-align: center;
  font-size: 75%;
  text-indent: -99999999px;
}

.document__download__item {
  padding: 1rem;
  border: 1px solid #dddddd;
  background: #fdfdfd url(../assets/common/icon_download.svg) no-repeat 95% 50%;
  min-height: 5rem;
  margin-bottom: 1rem;
  img {
    max-width: 40px;
    float: left;
    margin-right: 1em;
  }
  h2 {
    text-align: left;
    font-size: 90%;
    margin: 0;
  }
  a {
    color: $b-nc-med-dark;
    text-decoration: none;
  }
  .pdf-size {
    font-weight: bold;
    font-size: 60%;
    color: #000000;
  }
  .pdf-summary {
    font-size: 80%;
  }
}

// Contact

.contact__form {
  padding-right: 1rem;
  border-right: 1px solid $b-nc-med;
}

.contact__infos {
  line-height: 2;
  h2 {
    margin: 0;
  }
}
