$icon-colors: (
  "nc-00": $b-nc-00,
  "nc-15": $b-nc-15,
  "nc-25": $b-nc-25,
  "nc-50": $b-nc-50,
  "nc-60": $b-nc-60,
  "nc-85": $b-nc-85,
  "nc-100": $b-nc-100,
  "mc-light": $b-mc-light,
  "mc-med": $b-mc-med,
  "uc-positive": $b-uc-positive,
  "uc-caution": $b-uc-caution,
  "uc-warning": $b-uc-warning,
  "ac-3": $b-ac-3,
  "ac-6": $b-ac-6,
  "ac-7": $b-ac-7,
  "ac-8": $b-ac-8
);

@each $name, $color in $icon-colors {
  .icon-#{$name} {
    .icon-stroked {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
      stroke: $color;
      transition: all 0.5s ease;
    }
    .icon-filled {
      fill: $color;
      transition: all 0.5s ease;
    }
  }

  .icon-add-#{$name} {
    .icon-add-filled {
      fill: $color;
      transition: all 0.5s ease;
    }
  }
}

//Discussion notifications
.icon-container {
  position: relative;

  &__notifications {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 700;
    font-family: $secondary-font;
    color: $b-nc-00;
    line-height: 0.75rem;
    background: $b-uc-warning;
    border: 2px solid $b-nc-00;
    border-radius: 1rem;
    transform: translate(0.25rem, -0.25rem);
  }
}

.division-icons-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: $spc-tiny;
  width: 68px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.625rem;
    min-height: 1.625rem;
  }
}
