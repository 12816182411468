/*************
* LISTS
*************/
.bastide-sortable-row--selected-key {
  color: $b-mc-med;
}


/*************
* STATS
*************/
.button-container--underline {
  position: relative;
  margin-bottom: 1.5rem;
  
  &::after {
    content: "";
    display: block;
    background-color: $b-mc-light;
    height: 1px;
    width: calc(100% - 30px);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
} 

.alert--totalCount {
  font-size: 20px;
  font-weight: 700;
  color: $b-mc-med;
  font-family: $secondary-font;
}


/*************
* TECHNICIAL ACTIONS
*************/
.action__round--button {
  color: $b-mc-med;
  margin-top: 0.2rem;
  cursor: pointer;
}

.action__round--button .span__round {
  float: right;
  cursor: pointer;
  border: 1px solid $b-mc-med;
  border-radius: 100%;
  background-color: $b-mc-med;
  color: white;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 1.23;
  transition: all 0.2s;
}

/*************
* GABARITS
*************/
.title--half-column {
  font-size: 1.25rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid $b-mc-med;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

h3.title--half-column {
    font-size: 1rem;
    padding-bottom: 0;
}

.separate--block__title {
  color: $b-mc-med;
}