.switch {
  @media only screen and (min-width: $screen-s) {
    display: flex;
    flex-flow: row nowrap;
  }

  &--row {
    display: flex;
    flex-flow: row nowrap;

    .switch {
      &__item {
        &:first-child .switch__item__label {
          border-top-left-radius: $border-rds--l;
          border-bottom-left-radius: $border-rds--l;
        }

        &:last-child .switch__item__label {
          border-top-right-radius: $border-rds--l;
          border-bottom-right-radius: $border-rds--l;
        }

        &:not(:last-child) .switch__item__label {
          border-right: 0;
        }

        &__label {
          margin-bottom: 0;
          border-radius: 0;
        }
      }
    }
  }

  &--multiple {
    .switch__item {
      &__input {
        &:checked + label {
          &::after {
            opacity: 1;
            transition: $tr--s;
          }
        }
      }
      &__label {
        position: relative;
        padding-right: 1.9rem;

        &:before {
          content: "";
          position: absolute;
          right: 0.375rem;
          top: 50%;
          width: 0.875rem;
          height: 0.875rem;
          background: $b-nc-00;
          border: 2px solid $b-mc-med;
          border-radius: $border-rds--l;
          background-color: $b-nc-00;
          transform: translateY(-50%);
        }

        &::after {
          content: "";
          position: absolute;
          right: 0.375rem;
          top: 50%;
          width: 0.6rem;
          height: 0.6rem;
          transform: translate(-4px, -50%);
          background: url(../assets/common/icon-check--blue.svg) center center no-repeat;
          background-size: contain;
          transition: $tr--s;
          opacity: 0;
        }
      }
    }
  }

  &__item {
    &:first-child .switch__item__label {
      @media only screen and (min-width: $screen-sm) {
        border-top-left-radius: $border-rds--l;
        border-bottom-left-radius: $border-rds--l;
      }
    }

    &:last-child .switch__item__label {
      @media only screen and (min-width: $screen-sm) {
        border-top-right-radius: $border-rds--l;
        border-bottom-right-radius: $border-rds--l;
      }
    }

    &:not(:last-child) .switch__item__label {
      @media only screen and (min-width: $screen-sm) {
        border-right: 0;
      }
    }

    &__input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1; //Hide

      &:checked + label {
        color: #fff;
        background-color: $b-mc-med;
        transition: $tr--m;
      }

      &:disabled + label {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    &__label {
      display: flex;
      width: 100%;
      max-width: 13rem;
      padding: 0.4rem 0.75rem 0.3rem 0.5rem;
      margin: 0 auto 0.5rem auto;
      color: $b-mc-med;
      font-size: 0.875rem;
      align-items: center;
      line-height: 1;
      border: 2px solid $b-mc-med;
      border-radius: $border-rds--l;
      background-color: $b-nc-00;
      cursor: pointer;
      transition: none;

      &:hover,
      &:focus {
        background: rgba($b-mc-med, 0.15);
        transition: $tr--m;
      }

      @media only screen and (min-width: $screen-sm) {
        margin-bottom: 0;
        border-radius: 0;
      }

      svg {
        margin-right: 0.25rem;
      }
    }
  }
}
