.patient-informations {
  &__label-container {
    margin-top: $spc-x-small;
    @media only screen and (max-width: $screen-s) {
      &:first-child {
        margin-top: 0;
      }
      
      margin-top: $spc-tiny;
    }
  }
}


.header-patient__metrics {
  display: flex;
  column-gap: 10px;
    .label-value--big {
      justify-content: flex-start;
      border-radius: 5px;
      padding: 1rem 1.5rem;
      text-align: left;
      border-radius: 5px;
      width: 100%;
      max-width: 25%;
      box-shadow: $elevation-4-dp;

    &:not(.label-value--big--positive, .label-value--big--warning, .label-value--big--caution, .label-value--big--no-data) {
      background-color: $b-nc-white;
    }
  }
  @media only screen and (max-width: $screen-sm) {
    flex-wrap: wrap;
    gap: 10px 2%;
    .label-value--big {
      max-width: 49%;
    }
  }
}