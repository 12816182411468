.big-pdg {
  padding: $spc-xx-large calc($spc-large * 2) $spc-medium calc($spc-large * 2);
}

.card-pdg {
  padding: calc($spc-medium/2) calc($spc-medium/2) calc($spc-small / 2) calc($spc-medium/2);
  @media (min-width: $screen-l) {
    padding: $spc-medium $spc-medium $spc-small $spc-medium;
  }
}
