.global-layout {
  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 4rem;
    padding-right: 0.5rem;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: $b-nc-00;
    box-shadow: $elevation-4-dp;
    @include z-index("header");

    @media only screen and (min-width: $screen-sm) {
      position: absolute;
      padding: 1rem;
      height: auto;
    }

    &--logo svg {
      max-width: 4.5rem;
      max-height: 2.188rem;
    }

    &--connected {
      @media only screen and (min-width: $screen-sm) {
        background: none;
        box-shadow: none;
        justify-content: flex-end;
        left: 4.5rem;
      }

      @media only screen and (min-width: $screen-m) {
        left: 5.5rem;
        right: 1rem;
      }

      .burger {
        width: 2rem;
        height: 2rem;
        background: none;
        border: none;
        cursor: pointer;

        @media only screen and (min-width: $screen-sm) {
          display: none;
        }
      }
    }

    &--unconnected {
      width: 100%;
      left: 0;
      background: $b-nc-white;
      display: flex;
      justify-content: space-between;
      box-shadow: none;
      padding: 1rem;
    }

    &--prescriber {
      @media only screen and (min-width: $screen-sm) {
        position: fixed;
      }

      &-connected {
        background: $b-nc-white;

        @media only screen and (min-width: $screen-m) {
          padding: 0 1rem;
        }

        .burger {
          width: 2rem;
          height: 2rem;
          background: none;
          border: none;
          cursor: pointer;

          @media only screen and (min-width: $screen-m) {
            display: none;
          }
        }

        .logo-app {
          display: none;

          @media only screen and (min-width: $screen-m) {
            display: block;
          }
        }
      }
      &-unconnected {
        padding: 1rem;
        justify-content: space-between;

        h2 {
          @media only screen and (max-width: $screen-sm) {
            display: none;
          }
        }
      }
    }

    &__logo {
      background: $b-nc-00;

      @media only screen and (min-width: $screen-sm) {
        display: flex;
        height: 4.5rem;
        width: 4.5rem;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        box-shadow: $elevation-4-dp;
      }

      svg {
        height: 1.875rem;
        width: auto;
      }
    }

    .help {
      display: none;

      @media only screen and (min-width: $screen-sm) {
        display: inline-flex;
      }
    }

    &__profil {
      select {
        max-width: 150px;
      }
    }
  }

  &__body {
    padding: 1rem;
    padding-top: 4rem;

    @media only screen and (min-width: $screen-sm) {
      padding: 4.5rem 1.5rem 1rem 6rem;
    }

    &--prescriber {
      @media only screen and (min-width: $screen-sm) {
        padding: 6rem 1.5rem 1rem 1.5rem;
      }

      @media only screen and (min-width: $screen-ml) {
        padding: 5rem 2rem 1rem 2rem;
      }
    }

    &--extranet {
      padding: $spc-x-small;
      margin-top: $spc-xxx-large;

      @media only screen and (min-width: $screen-sm) {
        padding: $spc-large;
      }
    }
  }
}
