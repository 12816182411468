.aide-prescription__header {
  background-color: $b-nc-white;
  margin-bottom: 1.875rem;
  padding: 1rem;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
  min-height: 12rem;
  .aide-prescriptionData__header__content {
    padding: 0 1rem 1rem 1rem;
    margin-bottom: 2rem;
    .aide-prescriptionData__header__content__data {
      margin-bottom: 0.625rem;
    }
    .aide-prescriptionData__header__content__data__label {
      display: block;
      @include txt--s($main-font);
      color: $b-nc-med;
    }
    .aide-prescriptionData__header__content__data__value {
      margin-bottom: 0.625rem;
      font-weight: 800;
      color: $b-nc-dark;
    }
  }
  .aide-prescription__header__infos {
    position: relative;
    padding: 0 1rem 1rem 1rem;
    color: $b-nc-dark;
    border-bottom: 1px solid $b-nc-light-bg;
    margin-bottom: 2rem;
    &__lastname,
    &__firstname {
      font-weight: 800;
    }
    label {
      display: inline-block;
      width: 80px;
    }
  }
}

.aide-prescription__header__button-container {
  input {
    width: 100%;
    padding: 1.25rem 0;
  }
}

.aide-prescriptionData--button-recap {
  padding: 5px calc($spc-large / 2);
  opacity: 1;
  color: white;
  border-width: 0;
  background-color: $b-mc-light;
  @include txt--big($main-font);
  text-transform: uppercase;
} //didn't found it … useless class ?

.aide-prescriptionDataEmbed {
  margin-bottom: 0.625rem;
  padding: 1rem;
  display: block;
  background-color: $b-nc-white;
  color: $b-nc-dark;
  border: 1px solid $b-nc-light;
}

.aide-prescriptionDataEmbedNext {
  margin-bottom: 0.625rem;
  padding: 1rem;
  display: block;
  background-color: $b-nc-white;
  color: $b-nc-dark;
  border: 1px solid $b-nc-light;
  border-left: $spc-tiny solid $b-nc-light;
}

.aide-prescriptionData--opened {
  border-left: $spc-tiny solid $b-mc-med;
  position: relative;
  .aide-prescriptionData__header {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: block;
    color: $b-nc-dark;
    border-bottom: 1px solid $b-nc-light;
    font-weight: 800;
  }
  .aide-prescriptionData__body {
    //display: block; Responsive Enhancement 19.06.2018
    display: flex;
    flex-direction: column;
    .form-control {
      margin-bottom: 1rem;
    }
  }
  .toTheEnd {
    visibility: hidden;
    float: right;
  }
  .aide-prescriptionData__modify {
    visibility: hidden;
  }
}

.aide-prescriptionData__info {
  position: absolute;
  top: $spc-x-small;
  right: $spc-x-small;
  visibility: visible;
  background: $b-ac-3;
  border: none;
  border-radius: $border-rds--circle;
  color: $b-nc-white;
  height: 1.5625rem;
  width: 1.5625rem;
  font-family: $secondary-font;
  font-weight: 800;
  padding: 0;
  cursor: pointer;
}

.aide-prescriptionData--closed {
  background-color: $b-mc-light;
  color: $b-nc-white;
  padding: 0.4rem;
  .aide-prescriptionData__header {
    padding: 10px 0 10px 25px;
    display: block;
    font-weight: normal;
  }
  .toTheEnd {
    visibility: visible;
    float: right;
  }
  .aide-prescriptionData__modify {
    visibility: visible;
    float: right;
    &__label {
      @include txt--s($main-font);
    }
    &__icon {
      cursor: pointer;
    }
  }
  .aide-prescriptionData__info {
    visibility: hidden;
    margin-top: 0;
  }
}

.aide-prescriptionData__body {
  display: none;
  .aide-prescriptionData__body__input-label {
    margin-right: 1.5rem;
    display: flex;
  }
  input[type="radio"],
  input[type="checkbox"] {
    margin-right: 0.5rem;
    vertical-align: top;
    width: auto;
  }
  label {
    display: inline-block;
    vertical-align: text-top;
    flex-basis: 100%;
    margin-bottom: 1rem;
    span {
      position: inherit;
      display: block;
      @include txt--s($main-font);
    }
  }

  .aide-prescriptionData__body-6-next {
    margin-top: 10px;
    border-top: 2px solid $b-mc-light;
    position: relative;
    width: 100%;
    .pbold {
      font-weight: bold;
    }
  }
}

.aide-prescriptionData__body-recap {
  margin-bottom: 1rem;
  color: $b-mc-light;
  .aide-prescriptionData__body-recap-left,
  .aide-prescriptionData__body-recap-right {
    padding: 0 1rem;
    text-align: center;
  }
  .aide-prescriptionData__body-recap-left {
    padding: 0 1rem 1rem 1rem;
    border-bottom: 2px solid $b-mc-light;
  }
}

@media (min-width: $screen-xs) {
  .aide-prescriptionData__body-recap {
    .aide-prescriptionData__body-recap-left,
    .aide-prescriptionData__body-recap-left {
      border-bottom: none;
      border-right: 2px solid $b-mc-light;
    }
  }
}

.aide-prescription-recap {
  position: absolute;
  @include z-index("element--absolute");
}

.aide-prescription-alert--note {
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.aald-info--table {
  margin-top: calc($spc-tiny / 2);
  width: 100%;
  th {
    background-color: lightgray;
    border: 1px solid $b-nc-med;
    padding: 5px;
  }
  td {
    border: 1px solid $b-nc-med;
    padding: 5px;
    text-align: center;
  }
}

.aide-prescription--icon-modify {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  .ic--edit {
    fill: $b-mc-med;
  }
}

.validateError {
  border: 1px solid $b-uc-warning !important;
  background-color: rgba(237, 26, 59, 0.05);
}

.validate-error-message {
  display: block;
  color: $b-uc-warning;
  @include txt--s($main-font);
  position: relative;
  top: -1.25rem;
}

.info-button--warning {
  background-color: $b-uc-warning;
}

.decision-img {
  width: 100px;
}

.aide--labelMachine {
  padding: 0 1rem 1rem 1rem;
  min-width: 300px;
  text-align: center;
  width: 100%;
}

.validate-message {
  color: $b-nc-med;
}

.editPatient-error-message {
  top: 0 !important;
}

.aald--step {
  margin-bottom: $spc-x-small;
  &:last-child {
    margin-bottom: 0;
  }
}

// Media Queries

@media (min-width: $screen-xs) {
  .aide-prescription__header__button-container input {
    width: unset;
    padding: 1.25rem 2.5rem;
  }
}

@media (min-width: $screen-sm) {
  .aide-prescription__header {
    .aide-prescription__header__infos {
      border-right: 1px solid $b-nc-light-bg;
      border-bottom: unset;
    }
  }
  .aide-prescriptionData--opened .aide-prescriptionData__body {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    .form-control {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  .aide--labelMachine {
    width: 300px;
    min-width: unset;
  }
}
