.message-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  color: $b-nc-dark;

  @media only screen and (min-width: $screen-m) {
    max-width: calc(2 * 100% / 3);
  }

  &--outgoing {
    margin-left: auto;
    .message-item {
      &__content {
        order: 1;
        background: rgba($b-mc-med, 0.15);
        margin-left: 0;
        margin-right: 1rem;
      }

      &__avatar {
        order: 2;
      }
    }
  }

  &__avatar {
    flex: 0 0 2rem;
  }

  &__content {
    flex: 1 1 auto;
    margin-left: 1rem;
    background: $b-nc-15;
    padding: 1rem;
    border-radius: $border-rds--xl;

    &__header {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: solid $b-nc-60 1px;

      @media only screen and (min-width: $screen-s) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__body {
      p {
        margin: 0;
      }

      ul {
        list-style: disc;
        padding-left: 1rem;
      }

      ol {
        list-style: decimal;
        padding-left: 1rem;
      }
    }
  }
}
