// DISCUSSIONS

.discussions__table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  .discussions__table__header {
    color: white;
    background-color: $b-mc-light;
  }
  .discussions__table__row {
    border-bottom: 1px solid $b-nc-light;
    line-height: 4;
    &:nth-child(even) {
      background-color: $b-nc-light-bg;
    }
  }
  .discussions__table__row__details {
    line-height: 1;
    list-style-type: none;
  }
}

.discussions__free-message-link {
  float: right;
}

.message-item__reading-infos {
  img {
    width: 1.5rem;
    vertical-align: text-bottom;
  }
}

.messages-single {
  max-width: 48.75em;
  margin-left: auto;
  margin-right: auto;
}

.messages-single__header {
  background-color: $b-nc-white;
  ul {
    margin-bottom: 0;
    .patient__col {
      width: 100%;
      margin-bottom: 1rem;
      h3 {
        @include txt--med($secondary-font);
        font-weight: $fw--bold;
        margin-top: 0;
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--free-message {
    border: none;
    background-color: transparent;
  }
}

.messages-single__form {
  margin-bottom: 1.875rem;
}

.scroll-to-new {
  @include txt--s($main-font);
  color: $b-nc-white;
  padding: 0.5rem;
  display: inline-block;
  border: 1px solid $b-nc-white;
  border-radius: $border-rds--l;
  transition: $tr--s;
  text-decoration: none;
  float: right;
  margin-top: 20px;
  &:hover {
    color: $b-mc-light;
    background-color: $b-nc-white;
    transition: $tr--s;
  }

  @include mf_breakpoint(min-sm) {
    margin-top: 0;
  }
}

.discussions__message {
  display: none;
  opacity: 0;
  &--active {
    display: table-row;
    opacity: 1;
  }
}

.discussions__message__details {
  margin-bottom: 1rem;
  color: $b-nc-white;
  display: block;
  clear: both;
  width: 90%;
  text-align: left;
  border-radius: $border-rds--l;
  padding: 1rem;
  overflow: auto;
  &.doctor {
    margin-right: auto;
    background-color: $b-mc-med;
  }
  &.Bastide {
    margin-left: auto;
    background-color: $b-nc-med-dark;
  }
}

.thread-item__header {
  padding-bottom: $spc-tiny;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.thread-item__sender-name {
  display: block;
  @include txt--med($main-font);
  margin-bottom: 0.25rem;
}

.thread-item__date {
  display: block;
  @include txt--s($main-font);
}

.thread__item--new {
  animation: highlight 4s ease-out;
}

@keyframes highlight {
  0% {
    box-shadow: 0 0 6px $b-uc-warning;
  }
  100% {
    box-shadow: 0 0 0 transparent;
  }
}

.discussions__message__form {
  margin-bottom: 2rem;
  display: block;
  .button--default {
    width: 100%;
    display: block;
    margin-left: auto;
  }
}

/* Quill Editor */

.quillWrapper {
  margin-bottom: 1rem;
}

.ql-editor {
  min-height: 15.625rem;
}

// Media Queries

@media (min-width: $screen-xs) {
  .discussions__message__form .button--default {
    width: auto;
  }
  .messages-single__header ul {
    margin-top: 0;
  }
}

@media (min-width: $screen-sm) {
  .messages-single__header ul {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .patient__col {
      width: 30%;
      margin-bottom: 0;
    }
  }
  .messages-single__thread {
    padding-left: 0;
    padding-right: 0;
  }
  .discussions__message__details {
    width: 80%;
    padding: 2rem;
  }
  .thread-item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .thread-item__sender-name {
    margin-bottom: 0;
  }
  .thread-item__date {
    float: right;
  }
}
