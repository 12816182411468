.connection-cgu{
  &__button{
    display: flex; border-top: 1px solid $b-nc-25;
    flex-flow: row nowrap; justify-content: flex-end;
  }
}

.connection {
  .row {
    position: relative; margin-top: 5rem;

    &::before {
      content: "";
      position: absolute; left: 0.5rem; right: 0.5rem; top: -2.5rem;
      height: 1px;
      background: $b-nc-med;
      transform: translateY(-50%);
    }
  }
}