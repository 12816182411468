///// NEW STYLES MODAL /////
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: rgba($b-nc-15, 0.8);
  @include z-index("modal");

  &__container {
    width: 40rem;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    position: fixed;
    left: 50%;
    top: 50%;
    border-radius: $border-rds--l;
    background-color: $b-nc-white;
    box-shadow: $elevation-16-dp;
    transform: translateX(-50%) translateY(-50%);
    overflow-y: auto;

    &--s {
      width: 30rem;
    }

    &--m {
      width: 40rem;
    }

    &--l {
      width: 64rem;
    }
  }
  
  &__header {
    padding: 1rem 2rem 1rem 1.5rem;
  }

  &__footer {
    width: 100%;
    border-top: solid 1px $b-nc-50;
  }

  &__title {
    color: $b-mc-med;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__btn-close {
    width: $spc-small;
    height: $spc-small;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;

    svg {
      width: $spc-x-small;
      height: $spc-x-small;
      fill: $b-nc-med-dark;
      transition: $tr--s;
    }

    &:hover {
      svg {
        fill: $b-mc-light;
        transform: scale(1.25) rotate(90deg);
      }
    }
  }
}

.modal--bl {
  &__content,
  .tabs {
    height: 100%;
  }

  &__tabs {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;

    main {
      flex: 1 1 auto;
    }

    &__content {
      height: 100%;

      &__signature {
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
      }
    }
  }

  > .modal__container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    height: 100%;

    @media screen and (min-width: $screen-m) {
      width: 80%;
      max-width: calc(100vw - 2rem);
      max-height: calc(100vh - 2rem);
    }

    .form-base {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;

      &__section {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 auto;

        p {
          flex: 0 0 auto;
        }
      }

      &__buttons {
        flex: 0 0 auto;
      }
    }
  }
}


