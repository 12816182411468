.carousel {
  position: relative;
  overflow: hidden;
  background: $b-nc-white;
  display: flex;
  width: 100%;

  &.filiales {
    height: 100%;
  }
}

.carousel__slide__content {
  padding: 0 2rem;
  color: $b-nc-med-dark;
  text-align: left;
  position: relative;
  top: -2.65em;
}

.carousel__slide__content a {
  color: $b-nc-black;
  text-decoration: none;
}

.slide__title {
  @include txt--big($main-font);
  color: $b-nc-dark;
  font-weight: bold;
}

.carousel h2 {
  color: $b-nc-white;
  text-transform: uppercase;
  font-family: $secondary-font;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  margin: 0;
  display: inline-block;
  padding: 7.5px $spc-x-small;
  @include txt--med($secondary-font);
}

.carousel__slide__content__dateandplace {
  @include txt--btn();
  font-weight: $fw--reg;
}

.place {
  background: url(../assets/common/icon_place.svg) 0.25em 45% no-repeat;
  padding-left: 1em;
}

.carousel__slide {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
}

.slide__info {
  line-height: 2;
  color: $b-nc-med-dark;
  height: 4rem;
  overflow: hidden;
  transition: $tr--l;
}

.slide__link {
  @include txt--btn();
  font-weight: $fw--reg;
  color: $b-ac-3;
  text-decoration: underline;
}

.carousel__linktoall {
  margin: -2em 0 2em 2em;
}

/*##### CAROUSEL Navigation #####*/

.carousel__nav {
  margin-top: -5rem;
  position: absolute;
  top: 30%;
  left: 1rem;
  border: none;
  background: transparent;
  cursor: pointer;
}

.carousel__prev img,
.carousel__next img {
  border-radius: $border-rds--circle;
}

.carousel__next {
  right: 1rem;
  left: auto;
}

/* Carousel Animation */

.slide-right-enter-active {
  animation: slideRightIn 1s;
}

.slide-right-leave-active {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: slideRightOut 1s;
}

@keyframes slideRightIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideRightOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.slide-left-enter-active {
  animation: slideLeftIn 1s;
}

.slide-left-leave-active {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: slideLeftOut 1s;
}

@keyframes slideLeftIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideLeftOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
