.card--table {
  // overflow-x: scroll;
  @media print, (min-width: $screen-m) {
    background: $b-nc-white;
    border-radius: $border-rds--l;
    box-shadow: $elevation-4-dp;
  }
}

.global-table {
  width: 100%;
  line-height: 1rem;

  @media print, (min-width: $screen-m) {
    border-top: 1px solid $b-nc-25;
    border-bottom: 1px solid $b-nc-25;
  }

  &__row {
    display: block;
    margin-bottom: 1rem;
    padding: 0;
    flex: 0 0 calc(50% - 2rem);
    border: none;
    border-radius: $border-rds--l;
    background: $b-nc-white;
    box-shadow: $elevation-4-dp;

    @media print, (min-width: $screen-s) {
      margin: 1rem auto;
    }

    @media print, (min-width: $screen-m) {
      display: table-row;
      margin: 0;
      box-shadow: none;
      border-radius: 0;

      &:nth-child(2n + 1) {
        background: $b-nc-light-bg;
      }
    }
    
    &--reversed-background {
      background: $b-nc-light-bg;

      @media print, (min-width: $screen-m) {
      &:nth-child(2n + 1) {
        background: $b-nc-white;
        }
      }
    }

    &:last-child {
      @media (max-width: $screen-s) {
        margin-bottom: 0;
      }
    }

    &--title {
      display: none;
      background: $b-nc-white;
      color: $b-nc-med;
      position: sticky;
      top: -1px;
      z-index: 1;

      @media print, (min-width: $screen-m) {
        display: table-row;
        border-bottom: 1px solid $b-nc-25;
      }

      th {
        display: block;
        position: relative;
        padding: 0.6rem 1rem 0.45rem 1rem;
        font-size: 0.75rem;
        font-weight: $fw--bold;
        text-transform: uppercase;
        text-align: left;

        &:not(:last-child) {
          border-right: 1px solid $b-nc-25;
        }

        @media print, (min-width: $screen-m) {
          display: table-cell;
        }

        &.global-table__col--title {
          @media print, (min-width: $screen-m) {
            min-width: 10rem;
          }

          @media print, (min-width: $screen-l) {
            min-width: 20rem;
          }
        }
      }
    }

    &__sortable {
      position: absolute;
      right: 0.5rem;
      top: 50%;
      width: 12px;
      height: 14px;
      padding: 0;
      background: none;
      border: none;
      transform: translate(50%, -50%);
      cursor: pointer;

      svg {
        width: 12px;
        height: 14px;
      }
    }

    &--hover:hover {
      background: rgba($b-mc-med, 0.2);
      cursor: pointer;

      &:hover {
        box-shadow: none;
      }
    }

    input[type="radio"] {
      width: 18px;
      height: 18px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__col {
    display: block;
    padding: 0.5rem;
    font-size: 0.75rem;
    text-align: center;

    @media print, (min-width: $screen-m) {
      display: table-cell;
      padding: 0.65rem 0.5rem 0.65rem calc(1rem + 1px);
      text-align: left;
    }

    &:first-child {
      padding: 0.65rem 0.5rem 0.65rem 1rem;

      @media only screen and (max-width: $screen-m) {
        width: 100%;
      }
    }

    &--hover {
      cursor: pointer;
      transition: $tr--m;

      &:hover,
      &:focus {
        background-color: rgba($b-mc-med, 0.2);
        transition: $tr--m;
      }
    }

    &--title {
      font-size: 1rem;
      font-weight: $fw--bold;
      color: $b-nc-med-dark;

      @media print, (min-width: $screen-m) {
        min-width: 10rem;
      }

      @media print, (min-width: $screen-l) {
        min-width: 20rem;
      }

      a {
        color: inherit;
      }

      .flex--row {
        @media (max-width: $screen-m) {
          justify-content: center;
        }

        svg {
          flex: 0 0 1.5rem;
        }
      }
    }

    &--icon {
      padding: 0.25rem 0.65rem 0.25rem 1rem;

      &:first-child {
        padding: 0.25rem 0.65rem 0.25rem 1rem;
      }
    }

    &--date {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;

      @media print, (min-width: $screen-m) {
        display: table-cell;
      }

      p + .v-popover {
        margin-left: 0.5rem;
      }
    }

    &--center {
      padding: 0.5rem 0.65rem;
      text-align: center;

      > * {
        margin: auto;
        text-align: center;
      }

      svg {
        display: block;
      }
    }

    &--s {
      @media print, (min-width: $screen-m) {
        min-width: 7rem;
      }
    }

    &--xs {
      @media print, (min-width: $screen-m) {
        min-width: 7rem;
        max-width: 8rem;
      }
    }

    &--telesuivi {
      img {
        display: block;
        margin: auto;
      }
    }

    &--font--big {
      font-size: 1rem;
    }

    &--quarter {
      width: 25%;
    }

    &--thin {
      &:first-child {
        padding: 0.25rem 0.5rem 0.25rem 1rem;
      }
      padding: 0.25rem 0.5rem 0.25rem 1rem;
    }

    &::before {
      display: block;
      margin-bottom: calc($spc-tiny / 2);
      content: attr(data-label);
      font-size: 0.75rem;
      font-weight: $fw--bold;
      text-transform: uppercase;
      color: $b-nc-med;

      @media print, (min-width: $screen-m) {
        display: none;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    &--input {
      padding-left: 1rem;
      padding-right: 2rem;
      @media print, (max-width: $screen-m) {
        padding-right: 1rem;
      }
    }

    &--button-icon {
      padding-right: 1rem;
      & > button {
        padding-left: 1rem;
        border-left: 1px solid $b-nc-40;
      }
      &--contentfit {
        width: 1%;
      }
      @media print, (max-width: $screen-m) {
        display: flex;
        justify-content: center;
        & > button {
          border: none;
        }
        &--contentfit {
          width: auto;
        }
      }
    }
  }

  &__card {
    width: calc(100% - ($spc-x-small*2));
    max-width: 500px;
    margin: $spc-x-small auto;
    border: none;
    border-radius: $border-rds--l;
    background: $b-nc-white;
    box-shadow: $elevation-4-dp;
  }

  &__content {
    display: block;
    width: 100%;
    padding: 2rem 0.5rem;
    border-bottom: 1px solid $b-nc-25;
  }

  &__no-result,
  &__loader {
    margin: auto;
    max-width: 30rem;
  }

  &__no-result {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .card-title-container {
    display: flex;
    flex-direction: column;
    &__inline {
      display: flex;
      flex-direction: row;
      margin-top: $spc-x-small;
      margin-bottom: $spc-x-small;

      > p, > a, > span {
        margin-top: 0;
        margin-bottom: 0;
      }

      .card-subtitle {
        margin-left: $spc-tiny;
      }
    }
  }

  th.subtitle {
    text-align: center;
    text-transform: lowercase;
    text-wrap: nowrap;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.planning-table {
  width: 100%;
  line-height: 1rem;

  @media only screen and (min-width: $screen-m) {
    border-top: 1px solid $b-nc-light-bg;
    border-bottom: 1px solid $b-nc-light-bg;
  }

  tbody {
    @media only screen and (min-width: $screen-s) {
      display: flex;
      margin: -1rem;
      flex-flow: row wrap;
    }

    @media only screen and (min-width: $screen-m) {
      display: table-row-group;
      margin: 0;
    }
  }

  &__row {
    display: block;
    margin-bottom: 1rem;
    padding: 0;
    flex: 0 0 calc(50% - 2rem);
    border: none;
    border-radius: $border-rds--l;
    border-bottom: 1px solid $b-nc-60;
    background: $b-nc-white;
    box-shadow: $elevation-4-dp;

    @media only screen and (min-width: $screen-s) {
      margin: 1rem;
    }

    @media only screen and (min-width: $screen-m) {
      display: table-row;
      margin: 0;
      box-shadow: none;
      border-radius: 0;
    }

    &--title {
      display: none;
      border-bottom: 1px solid $b-nc-60;

      @media only screen and (min-width: $screen-m) {
        display: table-row;
      }

      th {
        display: block;
        position: relative;
        padding: 0.6rem 1rem 0.45rem 1rem;
        font-weight: $fw--bold;
        color: $b-nc-60;
        text-transform: capitalize;

        &:not(:last-child) {
          border-right: 1px solid $b-nc-light-bg;
        }

        @media only screen and (min-width: $screen-m) {
          display: table-cell;
        }

        span {
          display: inline-block;
          width: 1.75rem;
          height: 1.75rem;
          border-radius: 1.75rem;
          line-height: 1.75;
        }

        &.current-day {
          color: $b-mc-med;

          span {
            color: $b-nc-00;
            background: $b-mc-med;
          }
        }
      }
    }
  }

  &__col {
    display: block;
    padding: 0;
    font-size: 0.75rem;
    text-align: center;

    @media only screen and (min-width: $screen-m) {
      display: table-cell;
      padding: 0.65rem 0.5rem 0.65rem calc(1rem + 1px);
      text-align: left;
    }

    &:first-child {
      padding: 0.65rem 0.5rem 0.65rem 1rem;
    }

    &--title {
      font-size: 1rem;
      font-weight: $fw--bold;
      color: $b-nc-med-dark;
      border-bottom: 1px solid $b-nc-60;

      @media only screen and (min-width: $screen-m) {
        width: 12.5%;
        max-width: 12.5%;
        border-bottom: none;
      }

      @media only screen and (min-width: $screen-l) {
        width: 30%;
        max-width: 30%;
      }
    }

    &--hover {
      cursor: pointer;
      transition: $tr--m;

      &:hover,
      &:focus {
        background-color: rgba($b-mc-med, 0.2);
        transition: $tr--m;
      }
    }

    &--day {
      position: relative;
      background: rgba($b-nc-50, 0.1);

      &:hover,
      &:focus {
        background: rgba($b-nc-50, 0.35);
      }

      &::after {
        background: $b-uc-positive;
      }

      &::after {
        content: "";
        width: 1.25rem;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: $b-nc-50;
      }

      @media only screen and (min-width: $screen-m) {
        width: 12.5%;
        max-width: 12.5%;
        border-left: 1px solid $b-nc-60;
      }

      @media only screen and (min-width: $screen-l) {
        width: 10%;
        max-width: 10%;
        border-left: 1px solid $b-nc-60;
      }

      &--positive {
        background: rgba($b-uc-positive, 0.2);

        &:hover,
        &:focus {
          background: rgba($b-uc-positive, 0.6);
        }

        &::after {
          background: $b-uc-positive;
        }
      }

      &--caution {
        background: rgba($b-uc-caution, 0.2);

        &:hover,
        &:focus {
          background: rgba($b-uc-caution, 0.5);
        }

        &::after {
          background: $b-uc-caution;
        }
      }

      &--warning {
        background: rgba($b-uc-warning, 0.2);

        &:hover,
        &:focus {
          background: rgba($b-uc-warning, 0.4);
        }

        &::after {
          background: $b-uc-warning;
        }
      }

      &--disable {
        background: $b-nc-25;

        &:hover,
        &:focus {
          background: rgba($b-nc-50, 0.5);
        }
      }

      @media only screen and (min-width: $screen-m) {
        padding: 0;
      }

      &__count {
        position: relative;
        padding: 0 0.25rem 0 0.3rem;
        color: $b-nc-00;
        font-weight: bold;
        font-size: 1rem;
        z-index: 1;
      }
    }

    &::before {
      display: block;
      padding: 0.25rem;
      content: attr(data-label);
      font-size: 0.85rem;
      font-weight: $fw--bold;
      text-align: center;
      color: $b-nc-60;
      text-transform: capitalize;
      background: $b-nc-00;

      @media only screen and (min-width: $screen-m) {
        display: none;
      }
    }
  }

  &__content {
    display: block;
    width: 100%;
    padding: 2rem 0.5rem;
    border-bottom: 1px solid $b-nc-light-bg;
  }

  &__no-result {
    margin: auto;
    max-width: 30rem;
  }

  &__no-result {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

/* SPECIFIC TABLE'S STYLE */

.patients-table {
  &__col {
    &--iah {
      @media only screen and (min-width: $screen-m) {
        width: 6.25rem; // 70px
      }
    }

    &--compliance {
      @media only screen and (min-width: $screen-m) {
        width: 7.875rem; // 126px
      }
    }

    &--installation {
      @media only screen and (min-width: $screen-m) {
        width: 8.25rem; // 132px
      }
    }

    &--intervention {
      @media only screen and (min-width: $screen-m) {
        width: 9.0625rem; // 145px
      }
    }
  }
}
