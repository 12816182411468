/* Respi mini chart */

.mini-chart {
  background-color: #fff;
  border: 1px solid $b-nc-25;
  border-radius: $border-rds--l;

  .flex-item {
    &:first-child {
      border-right: 1px solid $b-nc-25;
      & > div:not(:last-child) {
        border-bottom: 1px solid $b-nc-25;
      }
      @media only screen and (max-width: $screen-sm) {
        width: 100%;
      }
    }

    &:last-child {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      border-top: 1px solid $b-nc-25;
      border-right: none;

      @media only screen and (min-width: $screen-sm) {
        border-top: none;
      }

      @media only screen and (min-width: $screen-m) {
        border-top: 1px solid $b-nc-25;
      }

      @media only screen and (min-width: $screen-l) {
        border-top: none;
      }
    }

    @media only screen and (min-width: $screen-sm) {
      border-right: 1px solid $b-nc-25;
    }

    @media only screen and (min-width: $screen-m) {
      border-right: none;
    }

    @media only screen and (min-width: $screen-l) {
      border-right: 1px solid $b-nc-25;
    }
  }

  .label-value--big {
    max-width: 100%;

    @supports (box-sizing: border-box) {
      padding: 1rem 0.5rem;
      box-sizing: border-box;
    }
  }

  &__chart {
    height: 10rem;
    width: 100%;

    @supports (box-sizing: border-box) {
      padding: 0.75rem;
      box-sizing: border-box;
    }

    canvas {
      width: 100%;
    }
  }
}

/* Alerts mini chart */

.alerts-mini-chart {
  background: #fff;
  border: 1px solid $b-nc-25;
  border-radius: $border-rds--l;

  &__header {
    border-bottom: 1px solid $b-nc-25;

    .row {
      margin: 0.25rem 1rem 0.25rem 5rem;
    }
  }

  &__content {
    display: flex;

    .row {
      position: relative;
      margin: 0.25rem 1rem 0.25rem 0;
      flex: 1 1 auto;

      &::before {
        content: "";
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        background: $b-nc-50;
        transform: translateY(-50%);
      }
    }
  }

  &__label {
    flex: 0 0 5rem;
    width: 5rem;
    padding-left: 1rem;
    box-sizing: border-box;
    border-radius: $border-rds--l;
  }

  &__point {
    display: block;
    margin: 0 auto;
    border-radius: $border-rds--circle;
    height: 1rem;
    width: 1rem;
    z-index: 2;

    &--PENDING {
      background-color: $b-uc-warning;
    }
    &--ACTIVE {
      background-color: $b-uc-caution;
    }
    &--ARCHIVED {
      background-color: $b-uc-positive;
    }
    &--SUSPENDED {
      background-color: $b-nc-med-dark;
    }
  }
}

/* CHARTS TELESUIVI STORYBOOK - V3 */
.chart--telesuivi {
  border: 1px solid $b-nc-light;
  border-radius: $border-rds--l;

  &__filters {
    display: flex;
    padding: 0 1rem 1rem 1rem;
    flex-flow: row wrap;
    background: $b-nc-light;

    &__buttons{
      margin-left: 0;
      & > button {
        margin-right: $spc-tiny;
      }
    }

    &__containers {
      margin-top: $spc-x-small;
      margin-right: $spc-x-small;

      .telesuivi-img__container {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    @media only screen and (max-width: $screen-s) {
      &__buttons{
        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;

        & > button {
          margin-top: $spc-tiny;
          margin-right: $spc-x-tiny;
          padding-left: $spc-x-tiny;
          min-width: 65px;
          padding-right: 0;
          flex: 0 0 calc(33% - $spc-x-tiny);

          &.btn--big {
            min-width: 110px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
      &__containers {
        flex: 0 0 100%;
        margin-right: 0;

        .input-container__label {
          margin-bottom: 0;
        }

        .telesuivi-img__container {
          justify-content: flex-start;
        }
      }
    }
  }

  &__chart {
    padding: 0.5rem;
  }
}

.chart--telesuivi__legend__item {
  flex-basis: 25%;

  @media only screen and (max-width: $screen-xs) {
    flex-basis: 50%;
  }

}

.chart--alerts--history {
  border: 1px solid $b-nc-light;
  border-radius: $border-rds--l;
}
