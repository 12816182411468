// 00 - Card Coaching
.card--coaching {
  .btn--primary {
    margin-top: 1rem;
    @media (max-width: 45rem) {
      margin: 1.5rem auto 0 auto;
      float: none;
    }
    @media (max-width: 25rem) {
      width: 100%;
    }
  }
}

// 01 - Card Global Infos
.card--global-infos {
  .card__heading {
    padding-bottom: $spc-tiny;
    border-bottom: 1px solid $b-nc-light;
    margin-bottom: $spc-x-small;
  }
  .card__row--float-left:last-of-type {
    margin-bottom: 0;
  }

  address {
    button.button--discrete {
      margin-top: calc($spc-small / 2);
    }
  }
}

.card__row--float-left {
  margin-bottom: $spc-x-small;
  overflow: auto;
  .label-value {
    float: left;
    max-width: 186px;
    width: 100%;
    margin-bottom: $spc-x-small;
    margin-right: $spc-x-small;
  }
  .label-value--s {
    max-width: 85px;
  }
  .label-value--b {
    max-width: 388px;
  }
}

.card__heading--part {
  @include txt--med($main-font);
  font-weight: $fw--bold;
  color: $b-nc-dark;
  margin-top: 0;
  margin-bottom: $spc-x-small;
  padding-bottom: calc($spc-tiny / 2);
  border-bottom: 1px solid $b-nc-light;
}

// 02 - Card cta
.card--cta__content {
  @include txt--med($main-font);
}
.card__cta--left {
  margin-bottom: $spc-small;
}

@media (min-width: $screen-m) {
  .card--cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .card__cta--left {
    margin-bottom: 0;
    margin-right: $spc-medium;
  }
  .card__cta--right {
    width: 250px;
  }
}

// 03 - Card filiales actu
.card--filiales--actu {
  overflow: hidden;
  margin-bottom: 1rem;
  .card__heading {
    @include title--s();
    color: $b-nc-med-dark;
    margin-bottom: 0;
  }
}

.card--filiales--actu__container-slider {
  margin: -1rem -1rem 0.5rem -1rem;
  max-height: 18.75rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .carousel__nav {
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
  }
  .carousel__prev {
    left: 0;
  }
  .carousel__next {
    right: 0;
  }
}

.card--filiales--actu__container-slider__slider {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card--filiales--actu__text__date {
  @include txt--med($secondary-font);
  color: $b-mc-med;
  margin: 0 auto calc($spc-small / 2) auto;
}

.card--filiales--actu__text__content {
  margin-top: 0;
}

.card--filiales--actu__btn--download {
  .btn--download:first-of-type {
    margin-bottom: calc($spc-tiny / 2);
  }
}

@media (min-width: $screen-sm) {
  .card--filiales--actu__text-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card--filiales--actu__text__content {
    width: calc(100% - 24px);
    margin-bottom: 0;
  }
  .card--filiales--actu__btn--download {
    .btn--download {
      width: 175px;
    }
  }
}

@media (min-width: $screen-m) {
  .card--filiales--actu {
    position: relative;
    padding-left: calc(17.1875rem + 1rem);
  }
  .card--filiales--actu__container-slider {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 17.1875rem;
    max-height: 100%;
    height: 100%;
  }
}
