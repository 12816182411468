.modal-add-products {
    .form-base__buttons {
        padding: $spc-x-small;
        margin-top: $spc-x-small;
        @media (max-width: $screen-s) {    
            padding: $spc-tiny;
            padding-bottom: 0;
            margin-top: 0;
        }
    }
    
    .search-result-item-list {
        height: 33vh;
        max-height: 600px;
        overflow: scroll;

        @media (max-width: $screen-s) {    
            padding: $spc-tiny;
            margin-top: $spc-tiny;
            height: auto;
        }
    }

    .modal-add-products-section {
        &--scan {

            .scan-text--wrapper {
                padding: $spc-medium $spc-medium 0 $spc-medium;
            
                @media (max-width: $screen-s) {    
                    padding: $spc-small $spc-small 0 $spc-small;
                }

                p {
                    margin: 0;
                }
            }

            .scan-info--wrapper {
                padding: $spc-medium $spc-medium $spc-x-small $spc-medium;
                margin-top: $spc-tiny;
            
                @media (max-width: $screen-s) {    
                    padding: $spc-small $spc-small $spc-small $spc-small;
                }

                p {
                    text-align: center;
                    margin: 0;
                }
            }

            .scan-result--wrapper {
                padding: $spc-medium;
                margin-top: $spc-tiny;
            
                @media (max-width: $screen-s) {    
                    padding: $spc-tiny $spc-tiny $spc-small $spc-tiny;
                }

                h2 {
                    margin-top: 0;
                }
            }

            .scan-container {
                margin: $spc-small $spc-tiny;

                .buttons-container > button {
                    min-width: 250px;
                }

                #qr-code-full-region {
                    min-width: 250px;
                }
            }
        }

        &--manualSearch {
            .manual-search--inputs-container {
                padding: $spc-medium $spc-medium 0 $spc-medium;
            
                @media (max-width: $screen-s) {    
                    padding: $spc-medium $spc-tiny 0 $spc-tiny;

                    > p {
                        margin-bottom: $spc-x-small;
                    }
                }

                > p {
                    margin-top: 0;
                    text-align: center;
                }
            }

            .button-container {
                display: flex;
                justify-content: flex-end;
                padding: $spc-tiny;
                padding-bottom: 0;
            }
        }
    }
}