.min-w--960 {
  min-width: 60rem; //960px - Classe créée pour faire scroller horizontalement des tableaux dans la fiche patient.
}

/************************
* FONT CONTENT & TITLES *
*************************/

/*** TITLES ***/
.ft--title {
  // h4
  &--xs {
    @include title--xs($secondary-font);
  }
  // h3
  &--s {
    @include title--s();
  }
  // h2
  &--m {
    @include title--med();
  }
  // h1
  &--l {
    @include title--big();
  }
}

/*** CONTENT ***/
.ft--body {
  &--btn {
    @include txt--btn();
  }

  &--s {
    &--main--font {
      @include txt--s($main-font);
    } //12px
    &--second--font {
      @include txt--s($secondary-font);
    } //12px
  }

  &--m {
    &--main--font {
      @include txt--med($main-font);
    } //16px
    &--second--font {
      @include txt--med($secondary-font);
    } //16px
  }

  &--l {
    &--main--font {
      @include txt--big($main-font);
    } //18px
    &--second--font {
      @include txt--big($secondary-font);
    } //18px
  }

  &--xl {
    &--main--font {
      @include txt--xl($main-font);
    } //20px
    &--second--font {
      @include txt--xl($secondary-font);
    } //20px
  }
}

/*** FONT-WEIGHT ***/
.ft--weight {
  &--regular {
    font-weight: 400;
  }
  &--medium {
    font-weight: 500;
  }
  &--bold {
    font-weight: 700;
  }
}

/*** FONT-FAMILY ***/
.ft--family {
  &--main {
    font-family: $main-font;
  }
  &--secondary {
    font-family: $secondary-font;
  }
}

/*** FONT SIZE ***/
.ft--size {
  &--s {
    font-size: 0.75rem !important; //12px
  }
  &--m {
    font-size: 1rem !important; //16px
  }
  &--l {
    font-size: 1.25rem !important; //20px
  }
  &--xl {
    font-size: 1.5rem !important; // 24px
  }
  &--xxl {
    font-size: 1.75rem !important; // 28px
  }
}

/*** LINE-HEIGHT ***/
.line-height {
  &--null {
    line-height: 1;
  }
  &--s {
    line-height: 1.2;
  }
  &--m {
    line-height: 1.5;
  }
  &--l {
    line-height: 1.8;
  }
}

/***************
*    COLORS    *
***************/

/////////////// COLOR

// main colors
.color--mc {
  &--light {
    color: $b-mc-light !important;
  }
  &--med {
    color: $b-mc-med !important;
  }
  &--dark {
    color: $b-mc-dark !important;
  }
}

// accent colors
.color--ac {
  &--1 {
    color: $b-ac-1 !important;
  }
  &--2 {
    color: $b-ac-2 !important;
  }
  &--3 {
    color: $b-ac-3 !important;
  }
}

// neutral colors
.color--nc {
  &--00 {
    color: $b-nc-00 !important;
  }
  &--15 {
    color: $b-nc-15 !important;
  }
  &--25 {
    color: $b-nc-25 !important;
  }
  &--50 {
    color: $b-nc-50 !important;
  }
  &--60 {
    color: $b-nc-60 !important;
  }
  &--85 {
    color: $b-nc-85 !important;
  }
  &--100 {
    color: $b-nc-100 !important;
  }
  &--med {
    color: $b-nc-med !important;
  }
}

// utility colors
.color--uc {
  &--positive {
    color: $b-uc-positive !important;
  }
  &--caution {
    color: $b-uc-caution !important;
  }
  &--warning {
    color: $b-uc-warning !important;
  }
  &--neutral {
    color: $b-nc-60 !important;
  }
}

/////////////// BACKGROUND

// main colors
.background--mc {
  &--light {
    background-color: $b-mc-light !important;
  }
  &--med {
    background-color: $b-mc-med !important;
  }
  &--med--alpha {
    background-color: rgba($b-mc-med, 0.1) !important;
  }
  &--dark {
    background-color: $b-mc-dark !important;
  }
}

.background--null {
  background-color: unset !important;
}

// accent colors
.background--ac {
  &--1 {
    background-color: $b-ac-1 !important;
  }
  &--2 {
    background-color: $b-ac-2 !important;
  }
  &--3 {
    background-color: $b-ac-3 !important;
  }
}

// neutral colors
.background--nc {
  &--00 {
    background-color: $b-nc-00 !important;
  }
  &--15 {
    background-color: $b-nc-15 !important;
  }
  &--25 {
    background-color: $b-nc-25 !important;
  }
  &--50 {
    background-color: $b-nc-50 !important;
  }
  &--60 {
    background-color: $b-nc-60 !important;
  }
  &--85 {
    background-color: $b-nc-85 !important;
  }
  &--100 {
    background-color: $b-nc-100 !important;
  }
}

// utility colors
.background--uc {
  &--positive {
    background-color: $b-uc-positive !important;
  }
  &--caution {
    background-color: $b-uc-caution !important;
  }
  &--warning {
    background-color: $b-uc-warning !important;
  }
  &--info {
    background-color: $b-ac-3 !important;
  }
  &--positive--light {
    background-color: rgba($b-uc-positive, 0.25) !important;
  }
  &--caution--light {
    background-color: rgba($b-uc-caution, 0.25) !important;
  }
  &--warning--light {
    background-color: rgba($b-uc-warning, 0.25) !important;
  }
  // For renewal purpose only
  &--positive--renewal {
    background-color: $b-uc-positive-renewals !important;
  }
  &--caution--renewal {
    background-color: $b-uc-caution-renewals !important;
  }
  &--warning--renewal {
    background-color: $b-uc-warning-renewals !important;
  }
  &--info--light {
    background-color: rgba($b-ac-3, 0.25) !important;
  }
}

.background--transparent {
  background-color: transparent;
}

/////////////// FILL

// main colors
.fill--mc {
  &--light {
    fill: $b-mc-light;
  }
  &--med {
    fill: $b-mc-med;
  }
  &--dark {
    fill: $b-mc-dark;
  }
}

// accent colors
.fill--ac {
  &--1 {
    fill: $b-ac-1;
  }
  &--2 {
    fill: $b-ac-2;
  }
  &--3 {
    fill: $b-ac-3;
  }
}

// neutral colors
.fill--nc {
  &--15 {
    fill: $b-nc-15;
  }
  &--00 {
    fill: $b-nc-00;
  }
  &--25 {
    fill: $b-nc-25;
  }
  &--50 {
    fill: $b-nc-50;
  }
  &--60 {
    fill: $b-nc-60;
  }
  &--85 {
    fill: $b-nc-85;
  }
  &--100 {
    fill: $b-nc-100;
  }
}

// utility colors
.fill--uc {
  &--positive {
    fill: $b-uc-positive;
  }
  &--caution {
    fill: $b-uc-caution;
  }
  &--warning {
    fill: $b-uc-warning;
  }
}

/////////////// STROKE

// main colors
.stroke--mc {
  &--light {
    stroke: $b-mc-light;
  }
  &--med {
    stroke: $b-mc-med;
  }
  &--dark {
    stroke: $b-mc-dark;
  }
}

// accent colors
.stroke--ac {
  &--1 {
    stroke: $b-ac-1;
  }
  &--2 {
    stroke: $b-ac-2;
  }
  &--3 {
    stroke: $b-ac-3;
  }
}

// neutral colors
.stroke--nc {
  &--15 {
    stroke: $b-nc-15;
  }
  &--00 {
    stroke: $b-nc-00;
  }
  &--25 {
    stroke: $b-nc-25;
  }
  &--50 {
    stroke: $b-nc-50;
  }
  &--60 {
    stroke: $b-nc-60;
  }
  &--85 {
    stroke: $b-nc-85;
  }
  &--100 {
    stroke: $b-nc-100;
  }
}

// utility colors
.stroke--uc {
  &--positive {
    stroke: $b-uc-positive;
  }
  &--caution {
    stroke: $b-uc-caution;
  }
  &--warning {
    stroke: $b-uc-warning;
  }
}

/************************
* CURSOR *
*************************/
.cursor--pointer {
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
}

// TO DELETE AFTER REFACTO
.clickable {
  color: $b-mc-med;
  cursor: pointer;
}

// TO DELETE AFTER REFACTO
.notClickable {
  color: $b-nc-med;
}

/************************
* FLEX GRID *
*************************/

.flex--wrap {
  flex-wrap: wrap;
}

.flex--no-shrink {
  flex-shrink: 0;
}

.flex--grow {
  &--auto {
    flex: 1 1 auto;
  }
}

// columns
.flex--col {
  display: flex;
  flex-direction: column;
  &--flex--start {
    justify-content: flex-start;
  }
  &--start {
    justify-content: start;
  }
  &--center {
    justify-content: center;
  }
  &--flex--end {
    justify-content: flex-end;
  }
  &--end {
    justify-content: flex-end;
  }
  &--spc--between {
    justify-content: space-between;
  }
  &--left {
    justify-content: left;
  }
  &--right {
    justify-content: right;
  }
}

// rows
.flex--row {
  display: flex;
  flex-direction: row;
  &--flex--start {
    justify-content: flex-start;
  }
  &--start {
    justify-content: start;
  }
  &--center {
    justify-content: center;
  }
  &--flex--end {
    justify-content: flex-end;
  }
  &--end {
    justify-content: flex-end;
  }
  &--spc--between {
    justify-content: space-between;
  }
  &--spc--around {
    justify-content: space-around;
  }
  &--left {
    justify-content: left;
  }
  &--right {
    justify-content: right;
  }
}

// alignment
.flex--align {
  &--flex--start {
    align-items: flex-start;
  }
  &--start {
    align-items: start;
  }
  &--flex--end {
    align-items: flex-end;
  }
  &--end {
    align-items: flex-end;
  }
  &--center {
    align-items: center;
  }
  &--center--m {
    @include breakpoint(max-m) {
      align-items: center;
    }
  }
  &--stretch {
    align-items: stretch;
  }
}

.flex-full {
  flex-grow: 1;
}

// Cas spécifique quand on a 5 flex-item sur 1 ligne.
@media (min-width: $screen-m) {
  .m-flex--pc {
    &--20 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.btn--container {
  display: flex;
  flex-direction: column;
  &--stacked {
    .btn--global {
      margin-bottom: 0.5rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &--left {
    justify-content: flex-start;
  }
  &--center {
    justify-content: center;
  }
  &--right {
    justify-content: flex-end;
  }
}

.btn--container--left,
.btn--container--center,
.btn--container--right {
  width: 100%;
  display: flex;
  flex-direction: column;
  .btn--global {
    margin-bottom: 8px;
  }

  @media (min-width: $screen-xs) {
    flex-direction: row;
    .btn--global {
      margin-bottom: 0;
      margin-right: 8px;
      &:last-of-child {
        margin-right: 0;
      }
    }
  }
}

// columns-count
.columns-count {
  &--2 {
    columns: auto 2;
  }
  &--3 {
    columns: auto 3;
  }
  &--4 {
    columns: auto 4;
  }
  &--5 {
    columns: auto 5;
  }
}

/************************
*   ORDER CHILD ITEMS   *
************************/

.flex-order {
  &--s {
    @media (max-width: $screen-s) {
      &--1 {
        order: 1;
      }

      &--2 {
        order: 2;
      }

      &--3 {
        order: 3;
      }
    }
  }
}

/************************
* TEXT TRANSFORMATION   *
*************************/

.text-align {
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}

.text-transform {
  &--uppercase {
    text-transform: uppercase;
  }
  &--capitalize {
    text-transform: capitalize;
  }
  &--lowercase {
    text-transform: lowercase;
  }
  &--italic {
    font-style: italic;
  }
}

.text-decoration {
  &--underline {
    text-decoration: underline;
  }
}

.text-space {
  &--no-wrap {
    white-space: nowrap;
  }
  &--pre-line {
    white-space: pre-line;
  }
}

/************************
*   MARGINS / PADDINGS  *
*************************/
.margin {
  &--auto {
    margin: auto !important;
  }
  &--top {
    &--null {
      margin-top: 0 !important;
    }
    &--auto {
      margin-top: auto;
    }
    &--xxs {
      margin-top: calc($spc-tiny / 2);
    }
    &--xs {
      margin-top: $spc-tiny;
    }
    &--s {
      margin-top: $spc-x-small;
    }
    &--m {
      margin-top: $spc-small;
    }
    &--l {
      margin-top: $spc-medium;
    }
    &--xl {
      margin-top: $spc-x-large;
    }
    &--xxl {
      margin-top: $spc-xx-large;
    }
    &--xxxl {
      margin-top: $spc-xxx-large;
    }
  }
  &--bot {
    &--null {
      margin-bottom: 0 !important;
    }
    &--auto {
      margin-bottom: auto;
    }
    &--xxs {
      margin-bottom: calc($spc-tiny / 2);
    }
    &--xs {
      margin-bottom: $spc-tiny;
    }
    &--s {
      margin-bottom: $spc-x-small;
    }
    &--m {
      margin-bottom: $spc-small;
    }
    &--l {
      margin-bottom: $spc-medium;
    }
    &--xl {
      margin-bottom: $spc-x-large;
    }
    &--xxl {
      margin-bottom: $spc-xx-large;
    }
    &--xxxl {
      margin-bottom: $spc-xxx-large;
    }
  }
  &--left {
    &--null {
      margin-left: 0 !important;
    }
    &--auto {
      margin-left: auto;
    }
    &--xxs {
      margin-left: calc($spc-tiny / 2);
    }
    &--xs {
      margin-left: $spc-tiny;
    }
    &--s {
      margin-left: $spc-x-small;
    }
    &--m {
      margin-left: $spc-small;
    }
    &--l {
      margin-left: $spc-medium;
    }
    &--xl {
      margin-left: $spc-x-large;
    }
    &--xxl {
      margin-left: $spc-xx-large;
    }
    &--xxxl {
      margin-left: $spc-xxx-large;
    }
  }
  &--right {
    &--null {
      margin-right: 0 !important;
    }
    &--auto {
      margin-right: auto;
    }
    &--xxs {
      margin-right: calc($spc-tiny / 2);
    }
    &--xs {
      margin-right: $spc-tiny;
    }
    &--s {
      margin-right: $spc-x-small;
    }
    &--m {
      margin-right: $spc-small;
    }
    &--l {
      margin-right: $spc-medium;
    }
    &--xl {
      margin-right: $spc-x-large;
    }
    &--xxl {
      margin-right: $spc-xx-large;
    }
    &--xxxl {
      margin-right: $spc-xxx-large;
    }
  }
  &--top--bot {
    &--null {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &--auto {
      margin-top: auto;
      margin-bottom: auto;
    }
    &--xxs {
      margin-top: calc($spc-tiny / 2);
      margin-bottom: calc($spc-tiny / 2);
    }
    &--xs {
      margin-top: $spc-tiny;
      margin-bottom: $spc-tiny;
    }
    &--s {
      margin-top: $spc-x-small;
      margin-bottom: $spc-x-small;
    }
    &--m {
      margin-top: $spc-small;
      margin-bottom: $spc-small;
    }
    &--l {
      margin-top: $spc-medium;
      margin-bottom: $spc-medium;
    }
  }
  &--left--right {
    &--null {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    &--auto {
      margin-left: auto;
      margin-right: auto;
    }
    &--xs {
      margin-left: $spc-tiny;
      margin-right: $spc-tiny;
    }
    &--s {
      margin-left: $spc-x-small;
      margin-right: $spc-x-small;
    }
    &--m {
      margin-left: $spc-small;
      margin-right: $spc-small;
    }
    &--l {
      margin-left: $spc-medium;
      margin-right: $spc-medium;
    }
    &--xl {
      margin-left: $spc-x-large;
      margin-right: $spc-x-large;
    }
    &--xxl {
      margin-left: $spc-xx-large;
      margin-right: $spc-xx-large;
    }
    &--xxxl {
      margin-left: $spc-xxx-large;
      margin-right: $spc-xxx-large;
    }
  }
  &--null {
    margin: 0 !important;
  }
  &--xs {
    margin: $spc-tiny;
  }
  &--s {
    margin: $spc-x-small;
  }
  &--m {
    margin: $spc-small;
  }
  &--l {
    margin: $spc-medium;
  }
  &--xl {
    margin: $spc-x-large;
  }
  &--xxl {
    margin: $spc-xx-large;
  }
  &--xxxl {
    margin: $spc-xxx-large;
  }
}

.m-margin {
  @media (max-width: $screen-m) {
    &--top--bot {
      &--xxs {
        margin-top: $spc-x-tiny;
        margin-bottom: $spc-x-tiny;
      }
      &--xs {
        margin-top: $spc-tiny;
        margin-bottom: $spc-tiny;
      }
      &--s {
        margin-top: $spc-x-small;
        margin-bottom: $spc-x-small;
      }
      &--m {
        margin-top: $spc-small;
        margin-bottom: $spc-small;
      }
      &--l {
        margin-top: $spc-medium;
        margin-bottom: $spc-medium;
      }
    }
  }
}

.padding {
  &--top {
    &--null {
      padding-top: 0 !important;
    }
    &--xxs {
      padding-top: $spc-x-tiny;
    }
    &--xs {
      padding-top: $spc-tiny;
    }
    &--s {
      padding-top: $spc-x-small;
    }
    &--m {
      padding-top: $spc-small;
    }
    &--l {
      padding-top: $spc-medium;
    }
    &--xxl {
      padding-top: $spc-xx-large;
    }
    &--xxxl {
      padding-top: $spc-xxx-large;
    }
  }
  &--bot {
    &--null {
      padding-bottom: 0 !important;
    }
    &--xs {
      padding-bottom: $spc-tiny;
    }
    &--s {
      padding-bottom: $spc-x-small;
    }
    &--m {
      padding-bottom: $spc-small;
    }
    &--l {
      padding-bottom: $spc-medium;
    }
    &--xxl {
      padding-bottom: $spc-xx-large;
    }
    &--xxxl {
      padding-bottom: $spc-xxx-large;
    }
  }
  &--left {
    &--null {
      padding-left: 0 !important;
    }
    &--xxs {
      padding-left: $spc-x-tiny;
    }
    &--xs {
      padding-left: $spc-tiny;
    }
    &--s {
      padding-left: $spc-x-small;
    }
    &--m {
      padding-left: $spc-small;
    }
    &--l {
      padding-left: $spc-medium;
    }
    &--xxl {
      padding-left: $spc-xx-large;
    }
    &--xxxl {
      padding-left: $spc-xxx-large;
    }
  }
  &--right {
    &--null {
      padding-right: 0 !important;
    }
    &--xs {
      padding-right: $spc-tiny;
    }
    &--s {
      padding-right: $spc-x-small;
    }
    &--m {
      padding-right: $spc-small;
    }
    &--l {
      padding-right: $spc-medium;
    }
    &--xxl {
      padding-right: $spc-xx-large;
    }
    &--xxxl {
      padding-right: $spc-xxx-large;
    }
  }
  &--top--bot {
    &--null {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &--xxs {
      padding-top: $spc-x-tiny;
      padding-bottom: $spc-x-tiny;
    }
    &--xs {
      padding-top: $spc-tiny;
      padding-bottom: $spc-tiny;
    }
    &--s {
      padding-top: $spc-x-small;
      padding-bottom: $spc-x-small;
    }
    &--m {
      padding-top: $spc-small;
      padding-bottom: $spc-small;
    }
    &--l {
      padding-top: $spc-medium;
      padding-bottom: $spc-medium;
    }
    &--xxl {
      padding-top: $spc-xx-large;
      padding-bottom: $spc-xx-large;
    }
    &--xxxl {
      padding-top: $spc-xxx-large;
      padding-bottom: $spc-xxx-large;
    }
  }
  &--left--right {
    &--null {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    &--xs {
      padding-left: $spc-tiny;
      padding-right: $spc-tiny;
    }
    &--s {
      padding-left: $spc-x-small;
      padding-right: $spc-x-small;
    }
    &--m {
      padding-left: $spc-small;
      padding-right: $spc-small;
    }
    &--l {
      padding-left: $spc-medium;
      padding-right: $spc-medium;
    }
    &--xxl {
      padding-left: $spc-xx-large;
      padding-right: $spc-xx-large;
    }
    &--xxxl {
      padding-left: $spc-xxx-large;
      padding-right: $spc-xxx-large;
    }
  }
  &--null {
    padding: 0 !important;
  }
  &--xxs {
    padding: calc($spc-tiny / 2);
  }
  &--xs {
    padding: $spc-tiny;
  }
  &--s {
    padding: $spc-x-small !important;
  }
  &--m {
    padding: $spc-small;
  }
  &--l {
    padding: $spc-medium;
  }
  &--xxl {
    padding: $spc-xx-large;
  }
  &--xxxl {
    padding: $spc-xxx-large;
  }
}

.m-padding {
  @media (max-width: $screen-m) {
    &--top--bot {
      &--s {
        padding-top: $spc-x-small;
        padding-bottom: $spc-x-small;
      }
      &--m {
        padding-top: $spc-small;
        padding-bottom: $spc-small;
      }
      &--l {
        padding-top: $spc-medium;
        padding-bottom: $spc-medium;
      }
    }
  }
}

/****************************
*   POSITION / DISPLAYMENT  *
*****************************/
.position {
  &--relative {
    position: relative;
  }
  &--absolute {
    position: absolute;
    &--top {
      &--null {
        top: 0 !important;
      }
      &--xxs {
        top: calc($spc-tiny / 2);
      }
      &--xs {
        top: $spc-tiny;
      }
      &--s {
        top: $spc-x-small;
      }
      &--m {
        top: $spc-small;
      }
    }
    &--bot {
      &--null {
        bottom: 0 !important;
      }
      &--xxs {
        bottom: calc($spc-tiny / 2);
      }
      &--xs {
        bottom: $spc-tiny;
      }
      &--s {
        bottom: $spc-x-small;
      }
      &--m {
        bottom: $spc-small;
      }
    }
    &--left {
      &--null {
        left: 0 !important;
      }
      &--xxs {
        left: calc($spc-tiny / 2);
      }
      &--xs {
        left: $spc-tiny;
      }
      &--s {
        left: $spc-x-small;
      }
      &--m {
        left: $spc-small;
      }
    }
    &--right {
      &--null {
        right: 0 !important;
      }
      &--xxs {
        right: calc($spc-tiny / 2);
      }
      &--xs {
        right: $spc-tiny;
      }
      &--s {
        right: $spc-x-small;
      }
      &--m {
        right: $spc-small;
      }
    }
  }
  &--fixed {
    position: fixed;
  }
}

.display--block {
  display: block !important;
}

.display--inline-block {
  display: inline-block;
}

/*******************************
*   HIDDEN CLASS / STRUCTURE   *
********************************/
.cells--hidden--screen {
  &--xxs {
    display: none;
    @media (min-width: $screen-xs) {
      display: table-cell;
    }

    &--xs {
      display: none;
      @media (min-width: $screen-sm) {
        display: table-cell;
      }
    }
  }
}

.hidden {
  opacity: 0;
}

.overflow {
  &--auto {
    overflow: auto;
  }
  &--hidden {
    overflow: hidden;
  }

  &--y {
    &--auto {
      overflow-y: auto;
    }
    &--hidden {
      overflow-y: hidden;
    }
  }

  &--x {
    &--auto {
      overflow-x: auto;
    }
    &--hidden {
      overflow-x: hidden;
    }
  }
}

.visibility {
  &--hidden {
    visibility: hidden;
  }
}

.float {
  &--left {
    float: left;
  }
  &--right {
    float: right;
  }
}

.hidden--screen {
  &--xxs {
    display: none;
    @media (min-width: $screen-xs) {
      display: block;
    }
  }
  &--xs {
    display: none;
    @media (min-width: $screen-s) {
      display: block;
    }
  }
  &--s {
    display: none;
    @media (min-width: $screen-sm) {
      display: block;
    }
  }
  &--sm {
    display: none;
    @media (min-width: $screen-m) {
      display: block;
    }
  }
  &--m {
    display: none;
    @media (min-width: $screen-ml) {
      display: block;
    }
  }
  &--ml {
    display: none;
    @media (min-width: $screen-l) {
      display: block;
    }
  }
  &--l {
    display: none;
    @media (min-width: $screen-xl) {
      display: block;
    }
  }
  &--xl {
    display: none;
    @media (min-width: $screen-xxl) {
      display: block;
    }
  }
  &--xxl {
    display: none;
    @media (min-width: $screen-lg) {
      display: block;
    }
  }
}

/*******************************
*   UTILITIES                  *
********************************/

.fullW {
  width: 100%;
}

.fullH {
  height: 100%;

  &--50 {
    min-height: 50vh;
  }

  &--100 {
    min-height: 100vh;
  }
}

.width--fit-content {
  width: fit-content;
}

.initial-width {
  &--s {
    @media (min-width: $screen-s) {
      width: initial;
    }
  }

  &--sm {
    @media (min-width: $screen-sm) {
      width: initial;
    }
  }

  &--m {
    @media (min-width: $screen-m) {
      width: initial;
    }
  }

  &--override {
    width: initial !important;
  }
}

.max-width {
  &--xs {
    max-width: 15rem;
  }

  &--s {
    max-width: 20rem;
  }

  &--sm {
    max-width: 25rem;
  }

  &--m {
    max-width: 30rem;
  }

  &--l {
    max-width: 40rem;
  }
}

.width {
  &--xs {
    width: 15rem;
  }

  &--s {
    width: 20rem;
  }

  &--sm {
    width: 25rem;
  }

  &--m {
    width: 30rem;
  }

  &--l {
    width: 40rem;
  }
}

.min-width {
  &--xs {
    min-width: 11.75rem;
  }
}

.border-rds {
  &--l {
    border-radius: $border-rds--l;
  }
  &--xl {
    border-radius: $border-rds--xl;
  }
  &--xxl {
    border-radius: $border-rds--xxl;
  }
  &--full {
    border-radius: 100%;
  }

  &--top--left {
    &--l {
      border-top-left-radius: $border-rds--l;
    }
    &--xl {
      border-top-left-radius: $border-rds--xl;
    }
  }

  &--bottom--left {
    &--l {
      border-bottom-left-radius: $border-rds--l;
    }
    &--xl {
      border-bottom-left-radius: $border-rds--xl;
    }
  }

  &--top--right {
    &--l {
      border-top-right-radius: $border-rds--l;
    }
    &--xl {
      border-top-right-radius: $border-rds--xl;
    }
  }

  &--bottom--right {
    &--l {
      border-bottom-right-radius: $border-rds--l;
    }
    &--xl {
      border-bottom-right-radius: $border-rds--xl;
    }
  }
}

.border {
  &--null {
    border: unset;
  }

  &--00 {
    border: 1px solid $b-nc-00;
  }
  &--25 {
    border: 1px solid $b-nc-25;
  }
  &--40 {
    border: 1px solid $b-nc-40;
  }
  &--50 {
    border: 1px solid $b-nc-50;
  }
  &--60 {
    border: 1px solid $b-nc-60;
  }
  &--med {
    border: 1px solid $b-mc-med;
    &--2 {
      border: 2px solid $b-mc-med;
    }
  }
  &--uc {
    &--warning {
      border: 1px solid $b-uc-warning;
    }
  }

  &--top {
    &--null {
      border-top: 0px;
    }
    &--25 {
      border-top: 1px solid $b-nc-25;
    }
    &--40 {
      border-top: 1px solid $b-nc-40;
    }
    &--50 {
      border-top: 1px solid $b-nc-50;
    }
    &--60 {
      border-top: 1px solid $b-nc-60;
    }
    &--med {
      border-top: 1px solid $b-mc-med;

      &--5 {
        border-top-width: 5px;
      }
    }
  }

  &--left {
    &--25 {
      border-left: 1px solid $b-nc-25;
    }
    &--50 {
      border-left: 1px solid $b-nc-50;
    }
    &--40 {
      border-left: 1px solid $b-nc-40;
    }
    &--60 {
      border-left: 1px solid $b-nc-60;
    }
    &--med {
      border-left: 1px solid $b-mc-med;
      &--5 {
        border-left-width: 5px;
      }
    }
  }

  &--right {
    &--25 {
      border-right: 1px solid $b-nc-25;
    }
    &--40 {
      border-right: 1px solid $b-nc-40;
    }
    &--50 {
      border-right: 1px solid $b-nc-50;
    }
    &--60 {
      border-right: 1px solid $b-nc-60;
    }
    &--med {
      border-right: 1px solid $b-mc-med;
      &--5 {
        border-right-width: 5px;
      }
    }
  }

  &--bot {
    &--null {
      border-bottom: 0px;
    }
    &--25 {
      border-bottom: 1px solid $b-nc-25;
    }
    &--40 {
      border-bottom: 1px solid $b-nc-40;
    }
    &--50 {
      border-bottom: 1px solid $b-nc-50;
    }
    &--60 {
      border-bottom: 1px solid $b-nc-60;
    }
    &--med {
      border-bottom: 1px solid $b-mc-med;

      &--5 {
        border-bottom-width: 5px;
      }
    }
  }
}

.disabled--element {
  opacity: 0.4;
  cursor: not-allowed;

  *,
  *:hover,
  *:active,
  *:focus {
    pointer-events: none;
  }
}

.elevation {
  &--2 {
    box-shadow: $elevation-2-dp;
  }
  &--4 {
    box-shadow: $elevation-4-dp;
  }
  &--8 {
    box-shadow: $elevation-8-dp;
  }
}

.break-all {
  word-break: break-all;
}

.p--normal {
  font-size: 1rem;
  margin: 1rem 0 !important;
  line-height: 1.25;
}
