.global-map__heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spc-tiny;
}

.global-map__filters {
  background-color: $b-nc-light;
  padding: $spc-x-small $spc-x-small 0 $spc-x-small;
  margin-bottom: $spc-small;
  border-radius: $border-rds--l;
}

.global-map__filters__subfilters {
  .legend-checkbox,
  .legend-radioBtn,
  .legend-input {
    margin-bottom: $spc-x-small;
  }
  @media (min-width: $screen-sm) {
    display: flex;
    flex-direction: column;
    .global-map__filters__subfilters__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .global-map__filters__subfilters__row__item {
        flex: 1;
        &:first-child {
          margin-right: $spc-x-small;
        }
        &:last-child {
          margin-left: $spc-x-small;
        }
      }
    }
  }
  @media (min-width: $screen-l) {
    flex-direction: row;
    justify-content: space-between;
    .global-map__filters__subfilters__row {
      flex-direction: row;
      justify-content: space-between;
      width: 49%;
      .global-map__filters__subfilters__row__item {
        flex: 1;
        &:first-child {
          margin-right: $spc-x-small;
        }
        &:last-child {
          margin-left: $spc-x-small;
        }
      }
    }
  }
}

.global-map__filters__subfilters__container-chips {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 0 16px 0;
  border-top: 1px solid $b-nc-med;
}

.global-map__results {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.global-map__results__type {
  min-width: 250px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: $border-rds--l;
  border: 1px solid $b-nc-light;
  padding: 1rem $spc-x-small $spc-tiny $spc-x-small;
  position: relative;
  margin-bottom: 1rem;
  .ic--filled {
    fill: $b-nc-med;
    min-width: $spc-small;
    width: $spc-small;
    min-height: $spc-x-small;
    height: $spc-x-small;
    position: absolute;
    top: -$spc-tiny;
    left: $spc-tiny;
    background-color: white;
    padding: 0 0 0 calc($spc-tiny / 2);
  }
}

.global-map__results__type__link--excel {
  display: flex;
  margin-left: 24px;
  &:hover {
    transform: scale(1.1);
    transition: $tr--s;
  }
}

@media (min-width: $screen-sm) {
  .global-map__results__type {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: $screen-m) {
  .global-map__results__type {
    margin: 0 1.5rem 1rem 1.5rem;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.global-map__results__type__title {
  @include txt--s($main-font);
  color: $b-nc-med;
  position: absolute;
  left: 32px;
  top: -$spc-tiny;
  margin: 0;
  padding: 0 calc($spc-tiny / 2) 0 calc($spc-tiny / 2);
  background-color: $b-nc-white;
}

.interventions-list {
  overflow: auto;
}

.map {
  position: relative;
}

.cartoLoader {
  position: absolute;
  @include z-index("header");
  width: 100%;
  height: 100%;
  background: #fffc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gm-style .gm-style-iw-c {
  padding: 0;
}

.infowindow {
  padding: 12px;
}

.btns--map {
  display: flex;
  position: absolute;
  left: 0;
  top: 10px;
  margin-top: 0 !important;
  width: 100%;

  button.btn--primary {
    margin-top: 0;
    margin-left: 12px;
  }
}
