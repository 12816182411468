/*
--- ALL INPUTS ---
.input--text -- DONE
.input--email -- DONE
.input--number -- DONE
.input--password -- DONE
.input--tel -- DONE
.input--search -- DONE
.input--file
.input--reset
.input--submit
.input--range -- DONE
*/

@mixin input--range__track() {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  border-color: transparent;
  color: transparent;
  height: 1px;
  &:active,
  &:hover {
    border: none;
  }
}

@mixin input--range__thumb() {
  -webkit-appearance: none;
  width: $spc-small;
  height: $spc-small;
  border: none;
  border-radius: $border-rds--circle;
  background-color: $b-mc-med;
  box-shadow: $elevation-2-dp;
  transition: $tr--s;
  cursor: pointer;
  &:hover,
  &:active {
    transform: scale(1.3);
    box-shadow: $elevation-4-dp;
    transition: $tr--s;
    border: none;
  }
}

@mixin input--range--disabled() {
  transform: none;
  cursor: not-allowed;
  &:hover,
  &:active {
    transform: none;
  }
}

/// show password /////
.show-password {
  position: absolute;
  right: 0;
  top: 0;
  padding: $spc-x-small;

  &--s {
    padding: calc($spc-tiny / 2) $spc-tiny;
  }
}

///// Input /////
.input--global {
  background-color: $b-nc-white;
  border-radius: $border-rds--l;
  border: 1px solid $b-nc-med-dark;
  padding: $spc-x-small;
  color: $b-nc-med-dark;
  letter-spacing: 0;
  @include txt--med($main-font);
  line-height: 1.25rem;
  transition: $tr--s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &::-ms-clear {
    display: none; // Remove clear/cross default button on IE
  }
}

.input--global,
.input--textarea,
.select--global {
  width: 100%;
  transition: $tr--s;
  &::placeholder {
    @include txt--med($main-font);
    color: $b-nc-med;
  }
  &:hover {
    border-radius: $border-rds--xl;
    border-color: $b-mc-med;
  }
  &:focus {
    border-color: $b-mc-light;
    border-radius: $border-rds--xl;
  }
  &:disabled {
    opacity: 0.6;
    border-color: $b-nc-med;
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      border-color: $b-nc-med;
      border-radius: $border-rds--l;
    }
  }
}

///// Input State Class /////
.input--state--error {
  border-color: $b-uc-warning;
  color: $b-uc-warning;
  &:hover,
  &:focus,
  &:active {
    border-color: $b-uc-warning;
    color: $b-nc-med-dark;
  }
}

///// Input Utilities Class /////
.input--s {
  padding: calc($spc-tiny / 2) $spc-tiny;
}

///// Input Message /////
.input__message--error {
  display: none;
  color: $b-uc-warning;
  text-align: right;
  @include txt--s($main-font);
  margin-top: 4px;
  margin-bottom: 0;
}

.input__message--success {
  display: none;
  color: $b-uc-positive;
  text-align: right;
  @include txt--s($main-font);
  margin-top: 4px;
  margin-bottom: 0;
}

///// Link inline form /////
.link--inline--form {
  @include txt--s($main-font);
  font-weight: $fw--bold;
  color: $b-mc-med;
  text-align: right;
  text-decoration: none;
  display: block;
  position: relative;
  top: -12px;
}

/// Input with buttons (radio / checkbox)
// Display by column
.input--buttons--group--col-2 {
  column-count: 2;
  @media screen and (max-width: $screen-s) {
    column-count: 1;
  }
}
.input--buttons--group--col-3 {
  column-count: 3;
  @media screen and (max-width: $screen-l) {
    column-count: 2;
  }
  @media screen and (max-width: $screen-m) {
    column-count: 1;
  }
}
.input--buttons--group--col-4 {
  column-count: 4;
  @media screen and (max-width: $screen-xxl) {
    column-count: 3;
  }
  @media screen and (max-width: $screen-l) {
    column-count: 2;
  }
  @media screen and (max-width: $screen-m) {
    column-count: 1;
  }
}

///// Radio-btns /////
// Active & Hover
.input--radio-btn {
  position: absolute;
  visibility: hidden;
}

.label--radio-btn {
  color: $b-nc-med-dark;
  letter-spacing: 0;
  line-height: 1.5rem;
  padding-left: 1.75rem;
  position: relative;
  cursor: pointer;
  font-weight: $fw--reg;
  transition: $tr--s;
  display: inline-block;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
  }
  &:before {
    width: 1.25rem;
    height: 1.25rem;
    border: 0.125rem solid $b-nc-med-dark;
    border-radius: $border-rds--circle;
    box-sizing: border-box;
    top: 0.125rem;
    left: 0;
    transition: $tr--s;
  }
  &:after {
    width: 0.625rem;
    height: 0.625rem;
    top: 0.44rem;
    left: 0.3125rem;
    border-radius: $border-rds--circle;
    background-color: $b-mc-med;
    transform: scale(0);
    transition: $tr--s;
  }
  &:hover {
    color: $b-nc-dark;
    &:before {
      border-color: $b-mc-med;
    }
    &:after {
      transform: scale(0.5);
      transition: $tr--s;
    }
  }
}

.label--radio-btn--subtitle {
  margin-left: 1.75rem;
}

.input--radio-btn:checked + .label--radio-btn {
  transition: $tr--s;
  font-weight: $fw--bold;
  &:before {
    border-color: $b-mc-med;
    transition: $tr--s;
  }
  .label--radio-btn--sublabel {
    color: $b-nc-dark;
    font-weight: $fw--reg;
    transition: $tr--s;
  }
  &:after {
    transform: scale(1);
    transition: $tr--s;
  }
}

// Options custom

.label--radio-custom {
  @extend .label--radio-btn;
  &:before {
    top: 50%;
    left: 1rem;
    transform: translate(0%, -50%);
  }
  &:after {
    top: 50%;
    left: 1.3125rem;
    transform: translate(0%, -50%) scale(0);
  }
  &:hover:after {
    transform: translate(0%, -50%) scale(0.5);
  }

  padding: 1rem 1rem 1rem 3rem;
  border: 1px solid $b-nc-light;
  border-radius: $border-rds--l;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input--radio-btn:checked + .label--radio-custom {
  &:after {
    transform: translate(0%, -50%) scale(1);
  }
}
.input--radio-btn:checked + .label--radio-custom--warning {
  border-color: $b-uc-warning;
  font-weight: initial;
  background-color: rgba(237, 2, 10, 0.15);
}
.input--radio-btn:checked + .label--radio-custom--positive {
  border-color: $b-uc-positive;
  font-weight: initial;
  background-color: $b-mc-med-10;
}

// Disabled

.input--radio-btn:disabled + .label--radio-btn {
  color: $b-nc-med;
  cursor: not-allowed;
  &:before {
    border-color: $b-nc-med;
  }
  &:after {
    display: none;
  }
}

.input--radio-btn:disabled:checked + .label--radio-btn {
  cursor: not-allowed;
  &:before {
    border-color: rgba($b-mc-med, 0.5);
  }
  &:after {
    display: block;
    background-color: rgba($b-mc-med, 0.5);
  }
}

///// Checkbox /////
// Active & Hover

.input--checkbox--global {
  position: absolute;
  visibility: hidden;
}

.label--checkbox {
  color: $b-nc-med-dark;
  line-height: 1.5rem;
  letter-spacing: 0;
  padding-left: 1.75rem;
  position: relative;
  cursor: pointer;
  font-weight: $fw--reg;
  transition: $tr--s;
  display: inline-block;
  min-height: 1.25em;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    top: 0.125rem;
    left: 0;
    border-radius: $border-rds--l;
    transition: $tr--s;
  }
  &:before {
    border: 0.125rem solid $b-nc-med-dark;
    box-sizing: border-box;
    background-color: transparent;
  }
  &:after {
    width: 1.25rem;
    height: 1.25rem;
    top: 0.125rem;
    left: 0;
    background-color: $b-mc-med;
    background-image: url(../assets/common/icon-check--white.svg);
    background-size: 0;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(0);
  }
  &:hover {
    color: $b-nc-dark;
    &:before {
      border: 0.125rem solid $b-mc-med;
    }
    &:after {
      transform: scale(0.4);
      transition: $tr--s;
    }
  }
}

.label--checkbox--sublabel {
  @include txt--s($main-font);
  color: $b-nc-med-dark;
  display: block;
  transition: $tr--s;
}

.input--checkbox--global:checked + .label--checkbox {
  transition: $tr--s;
  font-weight: $fw--bold;
  color: $b-nc-dark;
  &:before {
    border-color: $b-mc-med;
    transition: $tr--s;
  }
  .label--checkbox--sublabel {
    color: $b-nc-dark;
    font-weight: $fw--reg;
    transition: $tr--s;
  }
  &:after {
    transform: scale(1);
    transition: $tr--s;
    background-size: calc($spc-small / 2);
  }
}

///// Checkbox icon /////
.label--checkbox--icon {
  display: flex;
  align-items: center;
  &:after {
    content: none;
  }
  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    left: 2px;
    @include z-index("header");
    position: absolute;
    fill: $b-nc-med;
    transition: $tr--s;
  }
  &--patient:hover {
    &:before {
      border-color: $b-mc-light;
    }
    svg {
      fill: $b-mc-light;
    }
  }
  &--doctor:hover {
    &:before {
      border-color: $b-ac-3;
    }
    svg {
      fill: $b-ac-3;
    }
  }
  &--technician:hover {
    &:before {
      border-color: $b-ac-1;
    }
    svg {
      fill: $b-ac-1;
    }
  }
  &.form-element--inline-block {
    svg {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.input--checkbox--global:checked {
  & + .label--checkbox--icon {
    svg {
      fill: $b-nc-white;
    }
    &:before {
      background-color: $b-mc-med;
    }
    &--patient:before {
      border-color: $b-mc-light;
      background-color: $b-mc-light;
    }
    &--doctor:before {
      border-color: $b-ac-3;
      background-color: $b-ac-3;
    }
    &--technician:before {
      border-color: $b-ac-1;
      background-color: $b-ac-1;
    }
    &:hover {
      &:after,
      &:before {
        opacity: 0.75;
        transition: $tr--s;
      }
    }
  }
}

///// Checkbox chip /////

.label--checkbox--chip__chip {
  font-size: 20px;
}

//Disabled state
.input--checkbox--global:disabled {
  & + .label--checkbox {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

////// Switches ///////

.label--switch,
.label--switch__toggle {
  cursor: pointer;
}

.label--switch {
  display: inline-flex;
  align-items: center;
  margin: 5px 0;
  height: calc(1.4em - 1px);
  &__toggle::before,
  &__toggle::after {
    background: $b-nc-00;
    margin: 0 3px;
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
  }

  &__toggle::before {
    content: "";
    height: 1.4em;
    width: 2.5em;
    border-radius: 50px;
    opacity: 0.6;
    border: 1px solid $b-nc-50;
  }

  &__toggle::after {
    content: "";
    position: absolute;
    transform: translate(0, -100%);
    height: 1.4em;
    width: 1.4em;
    border-radius: 50%;

    border: 1px solid $b-nc-50;
    background: $b-nc-00;
    transition: background-size 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0.05s;
    background-size: 0%;
  }

  [type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  [type="checkbox"]:checked + &__toggle::after {
    transform: translate(calc(2.6em - 100%), -100%);
    background: $b-mc-med;
    background-size: 50%;
    border: 1px solid $b-mc-med;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="check" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M3,14.2062233 L8.61564985,20.2576866 C8.99132449,20.6625162 9.6240482,20.686151 10.0288778,20.3104763 C10.0923664,20.2515601 10.1479317,20.1846451 10.1941785,20.1114115 L21,3 L21,3"></path></svg>');
  }

  [type="checkbox"]:checked + &__toggle::before {
    background: rgba($b-mc-med, 0.1);
    border: 1px solid $b-mc-med;
  }

  [type="checkbox"]:not(:checked) + &__toggle::before {
    background: $b-nc-15;
    border: 1px solid $b-nc-50;
  }

  [type="checkbox"]:disabled + &__toggle {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.6;
    &::after {
      box-shadow: none;
    }
  }

  &--error {
    .label--switch__toggle::after {
      transform: translate(0, -94%);
    }
  }
}

///// Horizontal checkboxes or radio /////
.form-element--inline-block {
  display: inline-block;
  margin-right: 1rem;
  padding-left: 1.5rem;
}

///// Textarea /////
.input--textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 2.5rem;
  display: block;

  &::placeholder {
    line-height: 1.25rem;
  }
}

///// Textarea Counter /////
.input--textarea__counter {
  @include txt--s($main-font);
  color: $b-nc-med;
  text-align: right;
  display: block;
}

.input--textarea:disabled + .input--textarea__counter {
  opacity: 0.6;
}

.input--textarea__counter--caution {
  color: $b-uc-caution;
}

.input--textarea__counter--warning {
  color: $b-uc-warning;
}

///// Input Range /////
.input--range {
  margin: auto;
  outline: none;
  padding: 0;
  width: 100%;
  height: 1px;
  background-color: $b-nc-med-dark;
  cursor: pointer;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
}

.input--range::-webkit-slider-thumb {
  @include input--range__thumb();
  margin-top: calc(0rem - calc($spc-small / 2));
}
.input--range::-moz-range-thumb {
  @include input--range__thumb();
}
.input--range::-ms-thumb {
  @include input--range__thumb();
}

.input--range::-webkit-slider-runnable-track {
  @include input--range__track();
}
.input--range::-moz-range-track {
  @include input--range__track();
}
.input--range::-ms-track {
  @include input--range__track();
}

// Disabled
.input--range:disabled {
  cursor: not-allowed;
}

.input--range:disabled::-webkit-slider-thumb {
  @include input--range--disabled();
}
.input--range:disabled::-moz-range-thumb {
  @include input--range--disabled();
}
.input--range:disabled::-ms-thumb {
  @include input--range--disabled();
}

.input--range:disabled::-webkit-slider-runnable-track {
  @include input--range--disabled();
}
.input--range:disabled::-moz-range-track {
  @include input--range--disabled();
}
.input--range:disabled::-ms-track {
  @include input--range--disabled();
}

.input--text--multiple {
  position: relative;

  .dropdown-see-more__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    position: relative;
    padding: 0.35rem;
    padding-left: 0.5rem;
    border: 2px solid;
  }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-top-right-radius: $border-rds--l;
    border-bottom-right-radius: $border-rds--l;
    cursor: pointer;
    background: $b-mc-med;
    border: none;
    transition: $tr--m;

    &:hover {
      background: $b-mc-light;
      transition: $tr--m;
    }
  }
}

.input--date {
  position: relative;

  &__label {
    position: absolute;
    top: 0;
    left: 0.4rem;
    padding: 0 3px;
    font-size: 0.75rem;
    font-weight: 700;
    background: linear-gradient(to bottom, rgba($b-nc-00, 0), rgba($b-nc-00, 0) 50%, rgba($b-nc-00, 1) 51%);
    transform: translateY(-50%);
  }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-top-right-radius: $border-rds--l;
    border-bottom-right-radius: $border-rds--l;
    cursor: pointer;
    background: $b-mc-med;
    border: none;
    transition: $tr--m;

    &:hover {
      background: $b-mc-light;
      transition: $tr--m;
    }
  }

  .input--time {
    margin-top: 1rem;

    @media screen and (min-width: $screen-s) {
      margin-top: 0;
    }
  }

  &__picker {
    display: none;
  }

  .lightpick--inlined {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0%, 100%);
  }
}

.picker-right .lightpick--inlined {
  left: auto;
  right: 0;
}

///// Select /////
// Old with border-left green -> need an update
.select--default {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: $border-rds--null;
  border-left: $spc-tiny solid $b-mc-light;
  cursor: pointer;
  background: url(../assets/common/drawer-open.svg) no-repeat 95%;
  background-color: rgba(0, 0, 0, 0);
  background-color: white;
}

// New - Global styles
.select--global {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../assets/common/drawer-open--2.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) 50%;
  background-size: $spc-x-small;
  padding-right: $spc-large;
  height: 54px;
  &:hover {
    border-radius: $border-rds--xl;
  }
  &::-ms-expand {
    display: none; // Remove expand default button on IE
  }
}

.select--global.input--s {
  background-position: calc(100% - 8px) 45%;
  background-size: 12px;
  padding-right: $spc-small;
  height: 30px;
}

.form-base {
  &__section {
    margin: 0;
    padding: 0;
  }

  &__buttons {
    display: flex;
    padding: 0.5rem;
    flex-flow: row wrap;
    justify-content: space-between;
    border-top: 1px solid $b-nc-25;

    &__right,
    &__left {
      display: flex;
      flex: 1 1 auto;
      flex-flow: row nowrap;
    }

    &__right {
      justify-content: flex-end;
    }

    @media only screen and (max-width: $screen-s) {
      padding: 0;
      padding-top: $spc-tiny;

      .btn--global {
        width: 100%;
        &:not(:first-child) {
          margin-top: $spc-tiny;
        }
      }
    }
  }

  &--filter {
    background-color: $b-nc-15;

    .form-base {
      &__section {
        border-bottom: 1px solid $b-nc-50;
        margin: 0 $spc-small;
        padding: $spc-small 0 $spc-tiny 0;

        &:last-child {
          margin: 0;
          padding: $spc-small;
        }

        &--main {
          border-top: 1px solid $b-nc-25;
          border-bottom: none;
          background: $b-nc-00;
          margin: 0;
          padding: $spc-small;
        }
      }

      &__buttons {
        border-top: none;
      }
    }
  }
}

// Form buttons in modal are always display with space between them
.modal {
  .form-base__buttons, .form-base__buttons__right, .form-base__buttons__left {
    justify-content: space-between;
  }
}

// V3 Inputs //
.input-container {
  &__label {
    display: block;
    margin-bottom: 0.2rem;
    font-size: 0.75rem;
    font-weight: $fw--bold;
    margin-top: 0;
    text-align: start;
  }

  &__sublabel {
    display: block;
    margin-bottom: 0.2rem;
    margin-top: 0;
    text-align: start;
  }

  .label--checkbox {
    font-size: 1rem;
    font-weight: 400;
  }

  &--s {
    max-width: 200px;
  }
  &--m {
    max-width: 300px;
  }
  &--full {
    max-width: 100%;
  }
}

.input-dropdown {
  &--locations {
    .input-search__input input {
      padding: 0.5rem;
      padding-right: 1.75rem;
      background: none;
      border: none;
      border-radius: 0;
      border-top: 1px solid $b-mc-med;
      border-bottom: 1px solid $b-nc-60;
      border-left: 2px solid $b-mc-med;
      border-right: 2px solid $b-mc-med;
    }
  }

  &__btn,
  input {
    width: 100%;
    margin: none;
  }

  input {
    cursor: pointer;
  }

  &__btn--triggered.btn--secondary {
    background-color: $b-nc-00;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      border-radius: $border-rds--l;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__container {
    position: relative;
  }

  &__dropdown {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $b-nc-00;
    transform: translateY(calc(100% - 1px));
    @include z-index("element--absolute");
  }

  &__input__container {
    position: relative;

    > svg {
      position: absolute;
      top: 0;
      right: 0;
      width: 1rem;
      height: 1rem;
    }

    input::placeholder {
      @include txt--med($main-font);
      color: $b-nc-med;
    }
  }

  &__icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 100%;
  }

  &__input {
    padding: 0.5rem;
    background: none;
    border-top: 1px solid $b-mc-med;
    border-bottom: 1px solid $b-nc-60;
    border-left: 2px solid $b-mc-med;
    border-right: 2px solid $b-mc-med;
  }

  &__options {
    margin: 0;
    max-height: 10rem;
    border-left: 2px solid $b-mc-med;
    border-right: 2px solid $b-mc-med;
    border-top: none;
    overflow: auto;

    & > .input-dropdown__option:not(:last-of-type) > .input-dropdown__option__button--active {
      /* Specificité Firefox sur le rendu des borders */
      @-moz-document url-prefix() {
        margin-bottom: 1px;
      }
      border-bottom: 1px solid $b-nc-00;
    }
  }

  &__option {
    &:nth-child(2n) {
      background: $b-nc-25;
    }

    &__button {
      width: 100%;
      padding: $spc-tiny $spc-tiny $spc-tiny $spc-small;
      color: $b-nc-60;
      font-size: 1rem;
      text-align: left;
      background: none;
      border: none;
      cursor: pointer;

      &--active {
        background: $b-mc-med;
        color: $b-nc-00;

        svg {
          .icon-filled {
            fill: $b-nc-00;
          }

          .icon-stroked {
            stroke: $b-nc-00;
          }
        }
      }
    }

    .division-icons-group {
      padding: 0;
    }
  }

  &__button-reset {
    padding: 0.5rem;
    width: 100%;
    color: $b-mc-med;
    font-weight: 700;
    font-size: 0.75rem;
    background: $b-nc-00;
    border: 2px solid $b-mc-med;
    border-bottom-left-radius: $border-rds--l;
    border-bottom-right-radius: $border-rds--l;
    cursor: pointer;

    &:hover {
      background: rgba($b-mc-med, 0.15);
      transition: $tr--m;
    }
  }

  &__button-close {
    padding: 0.5rem;
    width: 100%;
    color: $b-nc-00;
    font-weight: 700;
    font-size: 0.75rem;
    background: $b-mc-med;
    border: 2px solid $b-mc-med;
    border-top-color: white;
    border-top-width: 1px;
    border-radius: 0;
    cursor: pointer;
    transition: $tr--m;

    &:hover {
      background: $b-mc-light;
      border-color: $b-mc-light;
      transition: $tr--m;
    }
  }

  .dropdown-see-more {
    margin: 0.25rem -0.25rem 0 -0.25rem;

    &__list {
      display: flex;
      flex-flow: row wrap;
    }
  }

  &__item {
    margin: 0.25rem;
  }
}

// V3 Repeater //
.repeater {
  border: 1px solid $b-nc-med-dark;
  border-radius: $border-rds--l;

  &--no-border {
    border: none;
  }

  &--light {
    overflow: hidden;

    .repeater__header {
      padding: 0;
      color: $b-nc-med-dark;
      background: $b-nc-light-bg;

      &--translate-right {
        padding-right: 2rem;
        margin: 0;
      }

      &__label {
        padding: 0.5rem 0 0.5rem 0.5rem;

        &--border {
          border-left: 1px solid $b-nc-med-dark;
        }
      }
    }

    .repeater__item {
      padding: 0;

      &:last-child {
        button {
          border-bottom-right-radius: $border-rds--l;
        }
      }

      &:not(:first-child) {
        border-top: 1px solid $b-nc-med-dark;
        border-radius: 0;
      }

      &:nth-child(2n + 1) {
        background-color: $b-nc-00;
      }

      &__content {
        .flex-item:not(:first-child) {
          border-left: 1px solid $b-nc-med-dark;
        }
      }

      .input--s {
        border: none;
      }
    }

    .repeater__button--item {
      flex-basis: 2rem;
      margin-left: 0;
      background-color: red;
      background: $b-nc-light-bg;

      &--add {
        background: $b-mc-med;
      }

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  &__item {
    display: flex;
    padding: 1rem;
    flex-flow: column nowrap;
    justify-content: space-between;

    @media screen and (min-width: $screen-s) {
      flex-flow: row nowrap;
    }

    &--files {
      padding: 0.5rem;
    }

    &--file {
      background: $b-nc-white;
      border: 1px solid $b-nc-light;
      border-radius: $border-rds--l;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &:nth-child(2n + 1) {
      background: $b-nc-light-bg;
      border-radius: $border-rds--l;
    }

    &__content {
      flex: 1 1 calc(100% - 2rem);
    }

    .input-container {
      margin-bottom: 0.5rem;

      @media screen and (min-width: $screen-s) {
        margin-bottom: initial;
      }
    }
  }

  &__button {
    display: flex;
    padding: 0.5rem;
    flex-flow: row nowrap;
    justify-content: flex-end;
    border-top: 1px solid $b-nc-25;

    &--item {
      flex: 0 0 2rem;
      padding: 0;
      margin-left: 1rem;
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      @media screen and (min-width: $screen-s) {
        margin-top: 0;
      }

      svg {
        fill: $b-nc-med-dark;
        width: 1.125rem;
        height: 1.25rem;
      }

      &--add {
        font-size: 1.5rem;

        &:disabled {
          opacity: 0.6;
        }
      }
    }
  }

  &__header {
    padding-left: 1rem;
    padding-right: 1rem;

    &--translate-right {
      margin-right: 3rem;
    }

    &__label {
      margin: 0;
      padding: 0.5rem 0;
      font-size: 0.75rem;
      font-weight: $fw--bold;
      text-transform: uppercase;
      text-align: left;
      color: $b-nc-med;

      &:not(:first-child) {
        border-left: 1px solid $b-nc-light-bg;
      }
    }
  }
}

.input--date-picker {
  &__start,
  &__end {
    position: relative;
  }

  p {
    margin-top: 0.25rem;
  }

  &__start {
    margin-top: 0.75rem;
  }

  &__end {
    margin-top: 0.75rem;
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0.4rem;
    padding: 0 3px;
    font-size: 0.75rem;
    font-weight: 700;
    background: linear-gradient(to bottom, rgba($b-nc-00, 0), rgba($b-nc-00, 0) 50%, rgba($b-nc-00, 1) 51%);
    transform: translateY(-50%);
  }

  .button--close--light {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2rem;
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transform: translateY(0);
  }

  &__horizontal {
    > .input-container {
      display: flex;
      justify-content: space-between;

      .input--date-picker__start, .input--date-picker__end {
        flex: 1 1 auto;
      }
    }
  }
}

.input-search {
  &__input {
    position: relative;

    &--active {
      .input--global,
      .input--global:focus {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &--selected {
      .input--global {
        color: rgba($b-mc-med, 1);
        opacity: 1;
      }
    }

    &--disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    input {
      width: 100%;
      background: $b-nc-00;
    }
  }

  &__icon {
    position: absolute;
    width: 2rem;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateY(0);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    button {
      height: 100%;
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__items {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    max-height: 15rem;
    background: $b-nc-00;
    list-style: none;
    border: 1px solid $b-nc-60;
    border-top: none;
    border-bottom-left-radius: $border-rds--l;
    border-bottom-right-radius: $border-rds--l;
    box-shadow: $elevation-4-dp;
    transform: translateY(100%);
    overflow: auto;
    @include z-index("element--absolute");
  }

  &__item {
    width: 100%;

    &:not(:last-child) {
      .input-search__item__button {
        border-bottom: 1px solid $b-nc-25;
      }
    }

    &__button,
    &__no-result {
      width: 100%;
      box-sizing: border-box;
    }

    &__button {
      border: none;
      cursor: pointer;
      text-align: left;
      padding: 0;

      &:hover,
      &:focus {
        background: rgba($b-mc-med, 0.2);
      }

      & + div,
      p {
        padding: 1rem 0.5rem;
      }

      .dropdown-item {
        p {
          padding: 0;
        }
      }
    }

    &__selected {
      background-color: $b-mc-med;
      color: $b-nc-00;

      .dropdown-item__subtitle {
        opacity: 1;
      }

      svg {
        .icon-filled {
          fill: $b-nc-00;
        }

        .icon-stroked {
          stroke: $b-nc-00;
        }
      }
    }

    &__no-result {
      padding: 1rem 0.5rem;
    }
  }

  &__footer {
    &__button {
      width: 100%;
      background: $b-mc-med;
      border-radius: 0;
    }
  }

  .input-dropdown__options {
    border: none;
  }
}

.input-search-multiple {
  .dropdown-see-more {
    margin: -0.25rem;
    margin-top: 0.25rem;
  }

  .dropdown-see-more__list {
    display: flex;
    flex-flow: row wrap;

    &--col {
      flex-flow: column;
    }
  }

  .dropdown-see-more__list__item {
    margin: 0.25rem;
    flex: 1 1 auto;

    .input-search-multiple__item--default {
      display: flex;
      margin-bottom: 0.5rem;
      flex-flow: row nowrap;
      justify-content: space-between;
      background: $b-nc-00;
      border-radius: $border-rds--l;
      border: 1px solid $b-nc-25;

      button {
        display: flex;
        padding: 1rem;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        background: rgba($b-mc-med, 0.1);
        border: none;
        cursor: pointer;
      }
    }
  }

  .dropdown-see-more__button {
    margin-top: $spc-tiny;
  }
}

.input-files {
  border: 1px solid $b-nc-med-dark;
  border-radius: $border-rds--l;

  &__list-files {
    padding: 0.75rem 0.5rem;
    margin: 0;
    background: $b-nc-light-bg;
    border-radius: $border-rds--l;
  }

  &__no-file {
    width: 100%;
    padding: 0.5rem;
    text-align: center;
  }

  &__file {
    display: flex;
    margin: 0.25rem 0.5rem;
    flex-flow: column nowrap;
    justify-content: space-between;
    background: $b-nc-white;
    border: 1px solid $b-nc-light;
    border-radius: $border-rds--l;
    font-size: 1rem;

    @media screen and (min-width: $screen-sm) {
      flex-flow: row wrap;
    }

    &__loader {
      display: flex;
      padding: 0.5rem;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    &__infos {
      display: flex;
      flex-flow: row wrap;
      padding: 0.5rem;
      margin: 0 -0.5rem -0.5rem -0.5rem;

      @media screen and (min-width: $screen-sm) {
        flex: 1 1 calc(100% - 3.5rem);
        max-width: calc(100% - 3.5rem);
      }

      &__item,
      &__item__date {
        margin: 0 0.5rem 0.5rem 0.5rem;
        max-width: 300px;

        > button,
        > p:not(.label-value__label) {
          max-width: 100%;
          text-align: left;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          cursor: pointer;

          @media screen and (min-width: $screen-sm) {
            white-space: nowrap;
          }
        }

        > button {
          padding: 0;
          color: $b-mc-med;
          border: none;
          background: none;
          transition: $tr--m;

          &:hover {
            color: $b-mc-light;
            transition: $tr--m;
          }
        }
      }

      &__item {
        overflow: hidden;
      }
    }

    &__btn-delete {
      display: flex;
      flex: 0 0 3.5rem;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      background: $b-uc-warning;
      border: none;
      border-top-right-radius: $border-rds--l;
      border-bottom-right-radius: $border-rds--l;
      transition: $tr--m;

      &:hover,
      &:focus {
        background-color: $b-uc-warning--dark;
        cursor: pointer;
        transition: $tr--m;
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &__input-files {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; //Hide
    &:disabled + label {
      opacity: 0.6;
      &:hover {
        border-radius: $border-rds--l;
        cursor: not-allowed;
      }
    }
  }

  &__repeater-button {
    display: flex;
    padding: 0.5rem;
    flex-flow: row nowrap;
    justify-content: flex-end;
    background: $b-nc-00;
  }
}

.input-single-file {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  &--error {
    border: 1px solid $b-uc-warning;
  }

  &__label {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  &__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; //Hide

    &:disabled {
      + label {
        opacity: 0.6;

        &:hover {
          color: $b-mc-med;
          border-radius: $border-rds--l;
          box-shadow: inset 0px 0px 0px 2px #219b95;
          cursor: not-allowed;
        }
      }
    }
  }

  &__input-label {
    margin-top: 0.5rem;
  }

  &__buttons {
    display: flex;
    margin-top: 0.5rem;
    flex-flow: row nowrap;
  }

  &__btn-preview,
  &__delete {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.5rem;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    cursor: pointer;
  }

  &__btn-preview {
    background: none;
    border: 2px solid $b-mc-med;
  }

  &__delete {
    background: $b-uc-warning;
    border: 2px solid $b-uc-warning;
  }
}

.input-crop-file {
  &__cropper {
    margin: 1rem;

    img {
      display: block;
      max-width: 100%;
      max-height: calc(100vh - 3.75rem);
    }
  }

  &__preview-crop {
    max-height: calc(100vh - 3.75rem);
  }
}

.preview-file {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: rgba($b-nc-100, 0.5);
  @include z-index("upper");

  &::before {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(5px);
    z-index: -1;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 3rem;
    height: 3rem;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background: $b-mc-med;
    border: none;
    z-index: 5;
    cursor: pointer;
  }

  object {
    width: calc(100vw - 8rem);
    height: calc(100vh - 4rem);
    max-width: calc(100vw - 8rem);
    max-height: calc(100vh - 4rem);
    border: none;

    &[type="application/pdf"] {
      width: 100%;
      height: 100%;
    }
  }

  img {
    max-width: calc(100vw - 8rem);
    max-height: calc(100vh - 4rem);
  }
}

.items-per-page {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: $b-nc-50;
  white-space: nowrap;

  select {
    max-width: 4.5rem;
    margin: 0 0.5rem;
  }
}

.input-richText {
  border-radius: $border-rds--l;
  border: 1px solid $b-nc-60;

  &__menubar {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 0 $spc-tiny;
    border-bottom: 1px solid $b-nc-60;

    &__buttons {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      &:not(:first-child) {
        margin-left: $spc-medium;
      }
    }

    &__button {
      padding: 0;
      margin: $spc-tiny;
      background: none;
      border: none;
      cursor: pointer;

      svg {
        pointer-events: none;
      }
    }
  }

  &__content {
    &--s {
      position: relative;
      padding: 1rem;
    }
    &--m {
      .ProseMirror {
        padding: 1rem;
        min-height: 200px;
      }
    }
  }

  .ProseMirror {
    font-family: $main-font;
    font-size: 1em;

    &:focus {
      outline: none;
    }

    p {
      margin: 0;
    }

    ul {
      list-style: disc;
      padding-left: 1rem;
    }

    ol {
      list-style: decimal;
      padding-left: 1rem;
    }
  }

  @media only screen and (max-width: $screen-s) {
    &__menubar__buttons:not(:first-child, :last-child) {
      margin-left: $spc-tiny;
    }
    &__menubar__buttons:last-child {
      margin-left: auto;
    }
  }
}

.highlighting {
  border: 1px solid $b-mc-med;
}

.highlighting--radio::before {
  border: 0.125rem solid $b-mc-med;
}

.input-number-unit {
  display: flex;
  border: 1px solid $b-nc-med-dark;
  border-radius: $border-rds--l;
  & > .input--s {
    border: none;
    border-radius: 0 $border-rds--l $border-rds--l 0;
    border-left: 1px solid $b-nc-med-dark;
    color: $b-nc-med-dark;
  }
  & > .input--global {
    border: none;
    border-radius: $border-rds--l;
    flex-grow: 1;
  }

  select {
    flex: 1 1 auto;
    max-width: 40%;
  }
}


@media only screen and (max-width: $screen-sm) {
  .input-date-time-range {
    .input-main-date .input--date {
      margin-bottom: $spc-x-small;
    }
  }
}

