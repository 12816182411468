.prestation__header {
  border: 1px solid $b-nc-25;
  border-radius: $border-rds--l;

  > .dropdown-heading__header {
    background-color: $b-nc-25;
    border-top-left-radius: $border-rds--l;
    border-top-right-radius: $border-rds--l;
    padding: 0.5rem 1rem;
    margin-bottom: 0;

    & + main {
      margin-top: 0;
      border-top: none;
    }

    h4 {
      color: $b-nc-85;
    }
  }
}

.prestation__content {
  background-color: #ffffff;
  border-bottom-left-radius: $border-rds--l;
  border-bottom-right-radius: $border-rds--l;

  & > .flex--row {
    flex-wrap: wrap;
  }

  .accordion__content {
    .label-value:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.prestation__action {
  width: 100%;
  flex-wrap: wrap;

  &__comment {
    margin: $spc-x-small 0;

    @media only screen and (min-width: $screen-s) {
      margin: $spc-tiny 0;
    }
  }

  &__downloads {
    flex-wrap: wrap;
    .btn--download:not(:last-child) {
      margin-bottom: $spc-x-small;

      @media only screen and (min-width: $screen-s) {
        margin-bottom: 0;
      }
    }
  }

  .label-value,
  .btn--download {
    flex-basis: 100%;

    @media only screen and (min-width: $screen-s) {
      flex-basis: 49%;
    }
  }
}

.prestation-unappairage {
  .dropdown-heading__header {
    h5 {
      color: $b-nc-med-dark;
      text-transform: uppercase;
    }
  }
}

