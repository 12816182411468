.modal-scan {
    .modal__container {
        
        .scan-hint {
            margin: 0 $spc-small 0 $spc-small;
    
            p {
                color: $b-nc-60;
                margin: 0;
            }
        }
    
        .scan-container {
                
            margin: $spc-x-small 0;

            @media (max-width: $screen-s) {
                margin: $spc-x-small 0 0 0;
            }
    
            .buttons-container > button , #qr-code-full-region {
                min-width: calc(640px - $spc-small * 2);
            }

            .btn--primary , .btn--secondary {
                @media (max-width: $screen-s) {

                    margin: $spc-tiny $spc-small 0 $spc-small;
                }

                margin: $spc-tiny auto 0 auto;
            }
            @media (max-width: $screen-s) {
                .buttons-container > button , #qr-code-full-region {
                    min-width: unset;
                    width: calc(100% - $spc-small * 2);
                    margin: auto;
                }
            }
        }
    }
}