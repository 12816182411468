// DEFAULT

.v-enter-active, .v-leave-active{
  transition: opacity .2s ease-out;////Looks unused, Need to check this with the team
}

.v-enter{
  opacity: 0;
}

.v-leave-to{
  opacity: 0;
}

// FADE

.fade-enter-active, .fade-leave-active{
  transition: all .4s;//Looks unused, Need to check this with the team
}
.fade-enter{
  opacity: 0;
  transform: translateX(-200px);
}

.fade-leave-active{
  opacity: 0;
  transform: translateX(200px);
}

// FADE-BOTTOM

.fade-bottom-enter-active, .fade-bottom-leave-active{
  transition: all .6s;//Looks unused, Need to check this with the team
}
.fade-bottom-enter{
  opacity: 0;
  transform: translateY(200px);
}

.fade-bottom-leave-active{
  opacity: 0;
  transform: translateY(200px);
}

// LIST

.list-enter-active, .list-leave-active{
  transition: all .5s ease-out;//Looks unused, Need to check this with the team
}
.list-enter, .list-leave-active{
  opacity: 0;
  transform: scale(0);
}

// DROPDOWN

.dropdown-enter-active, .dropdown-leave-active{
  transition: all .5s ease-out;//Looks unused, Need to check this with the team
}
.dropdown-enter{
  height: auto;
  opacity: 1;
}

.dropdown-leave-active{
  height: 0;
  opacity: 0;
}
