.publications-view {
  &__content {
    max-width: 80%;
    display: block;
    margin: 0 auto;
  }

  &__header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
    gap: 60px;
    h2 {
      margin: 0;
      flex-grow: 1;
      width: 100%;
      border-bottom: 1px solid $b-nc-25;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
      width: 140px;
    }
    @media screen and (max-width: $screen-m) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      p {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }

  p {
    line-height: 1.4;
  }

  img {
    width: 100%;
  }
}
