// // Extra Extra Small Devices, Phones
$screen-xxs: 320px;

// // Extra Small Devices, Phones
$screen-xs: 480px;

// // Small Devices, Tablets
$screen-sm: 768px;

// // Medium Devices, Desktops
$screen-m: 992px;

// // Intermediate Devices
$screen-l: 1200px;

// // small big
$screen-xl: 1400px;

// // Medium big
$screen-xxl: 1600px;

// // Large Devices, Wide Screens
$screen-lg: 1800px;

$breakpoints: (
  "s": $screen-sm,
  "m": $screen-m,
  "l": $screen-l,
  "xl": $screen-xl
);

$columns: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

$prefix: "fg__";
$gridPrefix: "grd__";

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: $spc-x-small;
  padding-left: $spc-x-small;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.gutter {
  &--xs {
    margin-right: $spc-tiny * -1;
    margin-left: $spc-tiny * -1;
    > .flex-item {
      padding-right: $spc-tiny;
      padding-left: $spc-tiny;
    }
  }

  &--s {
    margin-right: $spc-x-small * -1;
    margin-left: $spc-x-small * -1;
    > .flex-item {
      padding-right: $spc-x-small;
      padding-left: $spc-x-small;
    }
  }

  &--m {
    margin-right: $spc-small * -1;
    margin-left: $spc-small * -1;
    > .flex-item {
      padding-right: $spc-small;
      padding-left: $spc-small;
    }
  }

  &--l {
    margin-right: $spc-medium * -1;
    margin-left: $spc-medium * -1;
    > .flex-item {
      padding-right: $spc-medium;
      padding-left: $spc-medium;
    }
  }
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.#{$prefix}col-no-gutter {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0 !important;
  margin-left: 0 !important;
  > [class^="#{$prefix}col"] {
    padding-right: 0;
    padding-left: 0;
  }
}

/*** Mobile first, so default is xs ***/

.container {
  width: 100%;
}
.#{$prefix}col-xs,
.#{$prefix}col-xs1,
.#{$prefix}col-xs2,
.#{$prefix}col-xs3,
.#{$prefix}col-xs4,
.#{$prefix}col-xs5,
.#{$prefix}col-xs6,
.#{$prefix}col-xs7,
.#{$prefix}col-xs8,
.#{$prefix}col-xs9,
.#{$prefix}col-xs10,
.#{$prefix}col-xs11,
.#{$prefix}col-xs12,
.#{$prefix}col-xs-offset0,
.#{$prefix}col-xs-offset1,
.#{$prefix}col-xs-offset2,
.#{$prefix}col-xs-offset3,
.#{$prefix}col-xs-offset4,
.#{$prefix}col-xs-offset5,
.#{$prefix}col-xs-offset6,
.#{$prefix}col-xs-offset7,
.#{$prefix}col-xs-offset8,
.#{$prefix}col-xs-offset9,
.#{$prefix}col-xs-offset10,
.#{$prefix}col-xs-offset11,
.#{$prefix}col-xs-offset12 {
  flex: 0 0 auto;
}

.#{$prefix}col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  &1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }

  &2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }

  &3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  &4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }

  &5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }

  &6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  &7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }

  &8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }

  &9 {
    flex-basis: 75%;
    max-width: 75%;
  }
  &10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  &11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }

  &12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  &-offset0 {
    margin-left: 0;
  }

  &-offset1 {
    margin-left: 8.33333333%;
  }

  &-offset2 {
    margin-left: 16.66666667%;
  }

  &-offset3 {
    margin-left: 25%;
  }

  &-offset4 {
    margin-left: 33.33333333%;
  }

  &-offset5 {
    margin-left: 41.66666667%;
  }

  &-offset6 {
    margin-left: 50%;
  }

  &-offset7 {
    margin-left: 58.33333333%;
  }

  &-offset8 {
    margin-left: 66.66666667%;
  }

  &-offset9 {
    margin-left: 75%;
  }

  &-offset10 {
    margin-left: 83.33333333%;
  }

  &-offset11 {
    margin-left: 91.66666667%;
  }
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

@each $breakpoint, $value in $breakpoints {
  @media only screen and (min-width: #{$value}) {
    .container {
      width: #{$value + 1};
    }
    .#{$prefix}col-#{$breakpoint},
    .#{$prefix}col-#{$breakpoint}1,
    .#{$prefix}col-#{$breakpoint}2,
    .#{$prefix}col-#{$breakpoint}3,
    .#{$prefix}col-#{$breakpoint}4,
    .#{$prefix}col-#{$breakpoint}5,
    .#{$prefix}col-#{$breakpoint}6,
    .#{$prefix}col-#{$breakpoint}7,
    .#{$prefix}col-#{$breakpoint}8,
    .#{$prefix}col-#{$breakpoint}9,
    .#{$prefix}col-#{$breakpoint}10,
    .#{$prefix}col-#{$breakpoint}11,
    .#{$prefix}col-#{$breakpoint}12,
    .#{$prefix}col-#{$breakpoint}-offset0,
    .#{$prefix}col-#{$breakpoint}-offset1,
    .#{$prefix}col-#{$breakpoint}-offset2,
    .#{$prefix}col-#{$breakpoint}-offset3,
    .#{$prefix}col-#{$breakpoint}-offset4,
    .#{$prefix}col-#{$breakpoint}-offset5,
    .#{$prefix}col-#{$breakpoint}-offset6,
    .#{$prefix}col-#{$breakpoint}-offset7,
    .#{$prefix}col-#{$breakpoint}-offset8,
    .#{$prefix}col-#{$breakpoint}-offset9,
    .#{$prefix}col-#{$breakpoint}-offset10,
    .#{$prefix}col-#{$breakpoint}-offset11,
    .#{$prefix}col-#{$breakpoint}-offset12 {
      flex: 0 0 auto;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .#{$prefix}col-#{$breakpoint} {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
      &1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
      }

      &2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
      }

      &3 {
        flex-basis: 25%;
        max-width: 25%;
      }

      &4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
      }

      &5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
      }

      &6 {
        flex-basis: 50%;
        max-width: 50%;
      }

      &7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
      }

      &8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
      }

      &9 {
        flex-basis: 75%;
        max-width: 75%;
      }
      &10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
      }
      &11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
      }

      &12 {
        flex-basis: 100%;
        max-width: 100%;
      }

      &-offset0 {
        margin-left: 0;
      }

      &-offset1 {
        margin-left: 8.33333333%;
      }

      &-offset2 {
        margin-left: 16.66666667%;
      }

      &-offset3 {
        margin-left: 25%;
      }

      &-offset4 {
        margin-left: 33.33333333%;
      }

      &-offset5 {
        margin-left: 41.66666667%;
      }

      &-offset6 {
        margin-left: 50%;
      }

      &-offset7 {
        margin-left: 58.33333333%;
      }

      &-offset8 {
        margin-left: 66.66666667%;
      }

      &-offset9 {
        margin-left: 75%;
      }

      &-offset10 {
        margin-left: 83.33333333%;
      }

      &-offset11 {
        margin-left: 91.66666667%;
      }
    }

    .start-#{$breakpoint} {
      justify-content: flex-start;
      text-align: start;
    }

    .center-#{$breakpoint} {
      justify-content: center;
      text-align: center;
    }

    .end-#{$breakpoint} {
      justify-content: flex-end;
      text-align: end;
    }

    .top-#{$breakpoint} {
      align-items: flex-start;
    }

    .middle-#{$breakpoint} {
      align-items: center;
    }

    .bottom-#{$breakpoint} {
      align-items: flex-end;
    }

    .around-#{$breakpoint} {
      justify-content: space-around;
    }

    .between-#{$breakpoint} {
      justify-content: space-between;
    }
  }
}

.grid {
  display: grid;
}

@each $breakpoint, $value in $breakpoints {
  @media only screen and (min-width: #{$value}) {
    .#{$gridPrefix}#{$breakpoint} {    
      @each $column in $columns {
        &#{$column} {
          grid-template-columns: repeat(#{$column}, 1fr);
        }
      }
    }
  }
}

.x-gap {
  &--xs {
    row-gap: .5rem
  }
  &--s {
    row-gap: .75rem;
  }
  &--m {
    row-gap: 1rem;
  }
  &--l {
    row-gap: 1.25rem;
  }
}

.y-gap {
  &--xs {
    column-gap: .5rem
  }
  &--s {
    column-gap: .75rem;
  }
  &--m {
    column-gap: 1rem;
  }
  &--l {
    column-gap: 1.25rem;
  }
}

.gap {
  &--xs {
    gap: .5rem .5rem
  }
  &--s {
    gap: .75rem .75rem;
  }
  &--m {
    gap: 1rem 1rem;
  }
  &--l {
    gap: 1.25rem 1.25rem;
  }
}

