.dropdown-see-more__button {
  display: block;
  margin: 0 auto;

  &--light,
  &--light:hover,
  &--light:focus {
    background: transparent;
    color: $b-mc-med;
  }
}

.heading--infos--dropdown {
  cursor: pointer;
  svg {
    margin-left: auto;
  }
}

.dropdown-animation {
  &-enter-active,
  &-leave-active {
    transition: all 0.7s ease-in;
    opacity: 1;
    max-height: 100rem;
  }
  &-leave-to,
  &-enter {
    opacity: 0;
    transition: all 0.5s ease-out;
    max-height: 0;
  }
}

.dropdown-heading {
  &__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .heading--infos {
      flex: 1 1 auto;
      align-items: center;

      @media only screen and (max-width: $screen-s) {
        .chips--status {
          margin-top: $spc-x-tiny;
        }
      }
    }

    & + main {
      margin-top: 0.5rem;
      border-top: 1px solid $b-nc-25;
    }
  }

  &__button {
    flex: 0 0 2rem;
    margin-left: 0.5rem;
    border: none;
    background: none;
    cursor: pointer;
  }

  &__chips {
    flex: 0 0 2rem;
    margin-left: 0.5rem;
    border: none;
    background: none;
  }

  &--intervention {
    .dropdown-heading__header {
      flex-direction: column;

      @media only screen and (min-width: $screen-s) {
        flex-direction: row;
      }
    }
  }

  &--questionnaire {
    border: 1px solid $b-nc-25;
    border-radius: $border-rds--l;

    .dropdown-heading__header {
      padding: $spc-tiny $spc-tiny $spc-tiny $spc-x-small;
      background-color: $b-nc-25;
      h4 {
        color: $b-nc-60;
      }
    }
    .dropdown-heading__header + main {
      border: unset;
      margin: 0;
    }

    .questionnaire-status {
      padding: calc($spc-tiny / 2) $spc-x-small;
      color: $b-nc-white;
      border-radius: $border-rds--xxl;

      &--positive {
        background-color: $b-uc-positive;
      }
      &--warning {
        background-color: $b-uc-warning;
      }
      &--caution {
        background-color: $b-uc-caution;
      }
      &--neutral {
        background-color: $b-nc-white;
        color: $b-nc-60;
      }
    }

    .questionnaire-infos {
      margin: auto 0;
      color: $b-nc-60;
    }

    .questionnaire-content {
      padding: 0 $spc-tiny 0 $spc-x-small;

      &__row {
        display: flex;
        border-bottom: 1px solid $b-nc-25;
        padding-top: $spc-x-small;
        padding-bottom: $spc-x-small;
        &:last-of-type {
          border: none;
        }

        .questionnaire-evolution-icon {
          display: flex;
          width: $spc-large;
          height: $spc-large;
          border-radius: 100%;
          color: $b-nc-60;
          margin-right: auto;

          > svg {
            margin: auto;
            stroke: $b-nc-85;
          }

          &--positive {
            background-color: rgba($b-uc-positive, 0.30);
          }
          &--warning {
            background-color: rgba($b-uc-warning, 0.30);
          }
          &--neutral {
            background-color: $b-nc-25;
          }
        }
      }
    }
  }
}

//Dropdown profil user
.dropdown-profil {
  position: relative;
  display: inline-block;
  @include z-index("overlay");

  &__heading {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }

  &__identity {
    display: none;
    text-align: left;

    @media only screen and (min-width: $screen-sm) {
      display: block;
    }
  }

  &__name {
    margin: 0;
    margin-bottom: 0.3rem;
    font-size: 1em;
    font-family: $secondary-font;
  }

  &__expand {
    display: none;

    @media only screen and (min-width: $screen-sm) {
      display: block;
    }
  }

  &__content {
    position: absolute;
    bottom: -1.5rem;
    right: 0;
    min-width: 16rem;
    transform: translateY(100%);
  }

  &__button {
    border-top: 1px solid $b-nc-light;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease-out;
    opacity: 1;
    transform: translateY(100%);
  }
  &-leave-to,
  &-enter {
    opacity: 0;
    transition: all 0.5s ease-out;
    transform: translateY(calc(100% - 1rem));
  }
}

.dropdown-prescriber-card {
  border-radius: $border-rds--l;
  border: 1px solid $b-nc-25;
  transition: $tr--m;

  &--no-border {
    border: none;
  }

  &--active {
    border-color: $b-nc-60;
    transition: $tr--m;

    .dropdown-prescriber-card {
      &__header {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transition: $tr--m;
      }
    }
  }

  &__header {
    display: flex;
    margin-bottom: -1px;
    flex-flow: row nowrap;
    border-radius: $border-rds--l;
    border-bottom: 1px solid $b-nc-25;
    transition: $tr--m;
    overflow: hidden;

    &__content {
      flex: 1 1 auto;

      @media only screen and (min-width: $screen-sm) {
        display: flex;
        flex-flow: row nowrap;
      }
    }
  }

  &__button {
    padding: 1rem;
    background: none;
    border: none;
    border-top-right-radius: $border-rds--l;
    border-bottom-right-radius: $border-rds--l;
    border-left: 1px solid $b-nc-25;
    cursor: pointer;
  }

  &__title {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    align-items: center;

    @media only screen and (min-width: $screen-sm) {
      border-right: 1px solid $b-nc-25;
    }
  }

  &__avatar {
    margin: 0.75rem 1rem;
  }

  &__numbers {
    @media only screen and (min-width: $screen-s) {
      display: flex;
      flex-flow: row nowrap;
    }

    p {
      padding: 0.75rem;
      margin: 0;
      border-top: 1px solid $b-nc-25;

      @media only screen and (min-width: $screen-s) {
        flex: 1 1 auto;
        border-right: 1px solid $b-nc-25;
      }

      @media only screen and (min-width: $screen-sm) {
        border-top: none;
      }

      &:last-child {
        @media only screen and (min-width: $screen-s) {
          border-right: none;
        }
      }
    }
  }
}

.dropdown-item {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: $screen-s) {
      flex-direction: row;
    }

    &__status {
      @media only screen and (min-width: $screen-s) {
        margin-left: 1rem;
      }
    }
  }

  &__signature {
    order: 2;

    @media only screen and (min-width: $screen-l) {
      border-left: 1px solid $b-nc-light;
      order: 0;
    }

    &__title {
      margin-top: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $b-nc-light;
      font-family: $secondary-font;
      font-size: 1.125rem;
      font-weight: $fw--bold;
      color: $b-mc-med;
      text-align: left;

      @media only screen and (min-width: $screen-l) {
        font-family: $main-font;
        font-weight: $fw--reg;
        font-size: 1rem;
        color: $b-nc-60;
        text-align: center;
        border-bottom: none;
      }
    }

    &__empty-txt {
      @media only screen and (min-width: $screen-l) {
        display: none;
      }
    }

    &__figure {
      margin: 0;

      img {
        width: 100%;
        height: 100%;
        max-height: 10rem;
        object-fit: contain;
      }
    }
  }
}
