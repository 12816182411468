.information-banner {
  border-left: solid 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-flow: nowrap;

  &--positive {
    border-left-color: $b-uc-positive;
    background-color: rgba($b-uc-positive, 0.25);
  }

  &--caution {
    border-left-color: $b-uc-caution;
    background-color: rgba($b-uc-caution, 0.25);
  }

  &--warning {
    border-left-color: $b-uc-warning;
    background-color: rgba($b-uc-warning, 0.25);
  }

  &--info {
    border-left-color: $b-ac-3;
    background-color: rgba($b-ac-3, 0.25);
  }

  > svg {
    flex: 0 0 auto;
  }
}
