/* dosis-regular - latin */
@font-face {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/dosis/dosis-v18-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/dosis/dosis-v18-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/dosis/dosis-v18-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/dosis/dosis-v18-latin-regular.svg#Dosis") format("svg"); /* Legacy iOS */
}

/* dosis-700 - latin */
@font-face {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/dosis/dosis-v18-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/dosis/dosis-v18-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/dosis/dosis-v18-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/dosis/dosis-v18-latin-700.svg#Dosis") format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/montserrat/montserrat-v6-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/montserrat/montserrat-v6-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/montserrat/montserrat-v6-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/montserrat/montserrat-v6-latin-regular.svg#Dosis") format("svg"); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/montserrat/montserrat-v6-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/montserrat/montserrat-v6-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/montserrat/montserrat-v6-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/montserrat/montserrat-v6-latin-700.svg#Dosis") format("svg"); /* Legacy iOS */
}

/* heebo-regular - latin */
@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/fonts/heebo/heebo-v9-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/heebo/heebo-v9-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/heebo/heebo-v9-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/heebo/heebo-v9-latin-regular.svg#Heebo") format("svg"); /* Legacy iOS */
}

/* heebo-500 - latin */
@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/fonts/heebo/heebo-v9-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/heebo/heebo-v9-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/heebo/heebo-v9-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/heebo/heebo-v9-latin-500.svg#Heebo") format("svg"); /* Legacy iOS */
}

/* heebo-700 - latin */
@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../assets/fonts/heebo/heebo-v9-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/heebo/heebo-v9-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/heebo/heebo-v9-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/heebo/heebo-v9-latin-700.svg#Heebo") format("svg"); /* Legacy iOS */
}
