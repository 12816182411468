.tile__container {
  position: relative;
}

.tiles-container--home-connected {
  .tile-doctor {
    a {
      flex-direction: row;
      min-height: auto;
      .tile__title {
        order: 2;
        min-height: auto;
        &:before {
          display: none;
        }
      }
      .tile__icon {
        order: 1;
        min-height: auto;
        margin-right: 1rem;
        img,
        svg {
          max-width: 2rem;
          height: auto;
        }
        svg {
          fill: $b-nc-white;
        }
        .notification-spot {
          font-size: calc($spc-small / 2); // Don't add include txt on this, It needs to be a fix value for the moment
          font-weight: bold;
        }
      }
    }
  }
}

.tile-doctor {
  margin-bottom: 0.625rem;
  color: white;
  @include title--xs($secondary-font);
  text-align: center;
  box-shadow: 0 13px calc($spc-large / 2) 0 rgba(0, 0, 0, 0.15);
  transition: $tr--s;
  a {
    color: $b-nc-white;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 250px;
  }
}

.tile__title {
  min-height: 1.5em;
  position: relative;
  order: 1;
}

.tile__title::before {
  content: " ";
  background: transparent url(../assets/common/hover-arrow.svg) no-repeat 0 50%;
  width: $spc-large;
  height: 1em;
  display: block;
  position: absolute;
  transform: translate(-120%, -50%);
  top: 50%;
  opacity: 0;
  transition: $tr--s;
}

.tile-doctor:hover {
  box-shadow: 0 9px calc($spc-large / 2) calc($spc-tiny / 2) rgba(0, 0, 0, 0.35);
  .tile__title::before {
    opacity: 1;
  }
}

.tile__title + span {
  display: none;
  font-size: 75%; // Don't add include txt on this, It needs to be a fix value for the moment
  text-transform: none;
  font-family: $secondary-font;
  margin-bottom: 0.625rem;
  order: 2;
  @include mf_breakpoint(min-sm) {
    display: block;
  }
}

.w-desc {
  @include mf_breakpoint(min-sm) {
    margin-bottom: 0;
  }
}

.tile-bastide-access {
  background-color: $b-ac-3;
  &__patients-count,
  &__user-doctor-name {
    text-align: right;
    width: 90%;
    display: none;
    @include txt--med($secondary-font);
  }
  &__patients-count {
    order: 3;
  }
  &__user-doctor-name {
    order: 4;
  }
}

.tile-new-patient,
.tile-doctors {
  background-color: $b-mc-med;
}

.tile-messages {
  background-color: $b-nc-med;
}

.tile-actu {
  background-color: $b-mc-dark;
}

@media (min-width: $screen-sm) {
  .tiles-container--home-connected {
    .tile-doctor {
      a {
        flex-direction: column;
        min-height: 15.625rem;
        .tile__title {
          order: 1;
          min-height: 1.5em;
          &:before {
            display: block;
          }
        }
        .tile__icon {
          order: 2;
          min-height: 5.5625rem;
          margin-right: 0;
          img,
          svg {
            max-width: 100%;
          }
          .notification-spot {
            font-size: 100%; // Don't add include txt on this, It needs to be a fix value for the moment
            font-weight: normal;
          }
        }
      }
    }
    .tile-bastide-access__patients-count,
    .tile-bastide-access__user-doctor-name {
      display: block;
    }
  }
}

.notification-spot {
  display: block;
  background: $b-uc-warning;
  width: 2rem;
  height: 2rem;
  line-height: 2;
  border-radius: 100%;
  position: absolute;
  right: -2.25rem;
  top: 1rem;
  font-size: 1rem;
}
