///// Btn /////
.btn--global {
  display: inline-block;
  border-radius: $border-rds--l;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: none;
  @include txt--btn();
  transition: $tr--s;
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: none;
    transition: $tr--s;
    border-radius: $border-rds--xl;
  }

  &.btn--global--no-radius {
    border-radius: 0;
  }
}

.btn--global:disabled {
  opacity: 0.5;

  &:hover {
    border-radius: $border-rds--l;
    cursor: not-allowed;
  }
}

///// 00 Btn primary /////
.btn--primary {
  border: none;
  padding: $spc-x-small $spc-medium;
  background-color: $b-mc-med;
  color: $b-nc-white;
  &:hover {
    background-color: $b-mc-light;
    color: $b-nc-white;
  }

  &--round {
    position: relative;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;

    > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover,
    &:disabled:hover {
      border-radius: 2.5rem;
    }
  }

  &--no-radius {
    border-radius: 0;

    &:hover {
      border-radius: 0;
    }
  }
}

.btn--primary:disabled:hover {
  background-color: $b-mc-med;
  color: $b-nc-white;
}

///// 01 Btn secondary /////
.btn--secondary {
  border: none;
  padding: $spc-x-small $spc-medium;
  background-color: transparent;
  color: $b-mc-med;
  @include border--box-shadow($b-mc-med);
  &:hover {
    @include border--box-shadow($b-mc-light);
    color: $b-mc-light;
  }

  &--round {
    position: relative;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;

    > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      border-radius: 2.5rem;

      .icon-stroke {
        stroke: $b-mc-light;
      }

      .icon-filled {
        fill: $b-mc-light;
      }
    }
  }
}

.btn--secondary--selected {
  background-color: $b-mc-med;
  color: $b-nc-white;
  &:hover {
    color: $b-nc-white;
  }
}

.btn--secondary:disabled:hover {
  color: $b-mc-med;
  @include border--box-shadow($b-mc-med);
}

///// 02 Btn tertiary /////
.btn--tertiary {
  border: none;
  padding: $spc-x-small $spc-medium;
  background-color: transparent;
  color: $b-mc-med;
  transition: background-color 0.75s ease, color 0.3s ease; //Custom transition for a specific behavior

  &:hover {
    background-color: $b-nc-light;
    color: $b-mc-light;
    transition: background-color 0.75s ease, color 0.3s ease; //Custom transition for a specific behavior
  }
}

.btn--tertiary:disabled:hover {
  background-color: transparent;
  color: $b-mc-med;
}

///// 03 Btn Close /////
.btn--carousel {
  margin-left: 1.875rem;

  &--ac1 {
    background-color: $b-ac-1;
  }

  &--ac2 {
    background-color: $b-ac-2;
  }
}

///// 03 Btn Close /////
.button--close {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  background-color: $b-mc-med;
  border: none;
  border-radius: $border-rds--l;
  padding: 0.1rem 0.4em;
  font-size: 1.5rem; // Don't add include txt on this, It needs to be a fix value for the moment
  font-weight: $fw--reg;
  color: $b-nc-white;
  width: 2rem;
  height: 2rem;
  position: relative;
  transition: $tr--s;
  box-shadow: $elevation-2-dp;
  span {
    text-transform: uppercase;
    margin-left: 1rem;
    font-weight: $fw--bold;
    @include txt--med($secondary-font);
    opacity: 0;
    color: $b-nc-white;
  }

  &:hover,
  &:focus {
    width: 125px;
    box-shadow: $elevation-8-dp;
    background-color: $b-mc-light;

    span {
      opacity: 1;
      transition: $tr--s;
      transition-delay: 0.3s;
    }
  }
}

.content .button--close {
  margin-left: 0;
  @media (min-width: 1280px) {
    margin-left: -1.25rem;
  }
}

.button--close--light {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: $b-mc-med;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  cursor: pointer;
}

.button--close--light--transparent {
  background-color: transparent;
}

///// 04 Btn Download /////
.btn--download {
  border: 1px solid $b-mc-med;
  border-radius: $border-rds--l;
  padding: $spc-tiny;
  background-color: $b-nc-white;
  transition: $tr--s;

  &--no-border {
    border: unset;

    &:hover,
    &:focus {
      border: unset;
    }
  }

  &--s {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    svg {
      flex: 0 0 1.5rem;
      width: 1.5rem;
    }

    .btn--download__content p:first-of-type {
      @include txt--s($main-font);
    }
  }

  svg {
    flex: 0 0 $spc-medium;
    margin-right: $spc-tiny;
    width: $spc-medium;
    height: auto;
  }

  &__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  &__content {
    flex: 1 1 calc(100% - #{$spc-medium});
    max-width: calc(100% - #{$spc-medium});
    font-weight: $fw--bold;
    text-align: left;

    @supports (box-sizing: border-box) {
      padding-right: 0.25rem;
      box-sizing: border-box;
    }

    p {
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }
  }

  &__subcontent {
    color: $b-nc-med;
    @include txt--s($main-font);
    font-weight: $fw--reg;
    transition: $tr--s;
  }

  &:hover,
  &:focus {
    transition: $tr--s;
    border-color: $b-mc-light;
  }
}

// 06 Btn Export

.btn--export {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  line-height: 0;
  border: none;
  background-color: transparent;
  border: 1px solid $b-mc-med;
  cursor: pointer;
  transition: $tr--s;
  &:hover,
  &:focus {
    border-radius: 50%;
    background-color: rgba($b-mc-med, 0.15);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  svg {
    /* Visual correction */
    margin-left: 0.15rem;
  }
}

.download-button {
  display: flex;
  max-width: 100%;
  flex-flow: row nowrap;

  &__date {
    display: flex;
    flex: 0 0 calc(2.75rem);
    margin: 0;
    margin-right: 0.25rem;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    text-align: center;
    background: $b-mc-med;
    color: $b-nc-00;
    border-radius: $border-rds--l;

    span {
      display: block;

      &:first-child {
        font-weight: 700;
      }
    }
  }

  &__button {
    flex: 1 1 calc(100% - 2.75rem);
    width: calc(100% - 2.75rem);
    max-width: 100%;
  }
}

// 07 Checkbox button
.button--checkbox {
  position: relative;
  padding: 0;
  width: 1.25rem;
  height: 1.25rem;
  border: 0.125rem solid $b-nc-med-dark;
  border-radius: $border-rds--l;
  transition: $tr--s;
  cursor: pointer;
  transition: $tr--s;

  &::after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 1.25rem;
    height: 1.25rem;
    background-color: $b-mc-med;
    background-image: url(../assets/common/icon-check--white.svg);
    background-size: 0;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(0);
    border-radius: $border-rds--l;
    transition: $tr--s;
  }

  &:hover {
    border: 0.125rem solid $b-mc-med;

    &::after {
      transform: scale(0.4);
      transition: $tr--s;
    }
  }

  &--active {
    &::after {
      transform: scale(1);
      background-size: calc($spc-small / 2);
      transition: $tr--s;
    }

    &:hover {
      &::after {
        transform: scale(1);
      }
    }
  }
}

///// Btn utilities class /////
.btn-s-small {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.btn--height--small {
  height: 2.5rem;
}

.btn-xs-small {
  padding: 0.5rem;
}

.btn--right {
  float: right;
}

.btn--fluid {
  width: 100%;
}

.btn--round--small {
  width: 2rem;
  height: 2rem;

  svg {
    width: 20px;
    height: 20px;
  }
}

.btn--bx-shadow {
  box-shadow: $elevation-2-dp;
  &:hover,
  &:focus {
    box-shadow: $elevation-8-dp;
  }
}

.btn--warning {
  border: none;
  color: $b-nc-white;
  background-color: $b-uc-warning;

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: $b-uc-warning--dark;
  }

  &--big {
    padding: $spc-x-small;
  }

  &--light {
    background-color: transparent;
    color: $b-uc-warning;
    border: solid 2px $b-uc-warning;

    &:hover,
    &:focus {
      background-color: $b-uc-warning;
      color: $b-nc-white;
      border-color: $b-uc-warning;
    }
  }
}

.btn--caution {
  border: none;
  color: $b-nc-white;
  background-color: $b-uc-caution;

  &:hover,
  &:focus {
    background-color: $b-uc-caution--dark;
  }
}

// Add Ripple Material Effect
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

// Add an icon on a button
.btn--w-icon {
  padding-right: 3rem;
  position: relative;
  svg {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    transition: $tr--s;
  }
}

// Little fix for btn small with an icon
.btn-s-small.btn--w-icon {
  padding-right: 2.5rem;
  svg {
    right: 1rem;
  }
}

// White color on the icon in the button
.btn--w-icon--white .ic--filled {
  fill: $b-nc-white;
}

.btn--w-icon--white .ic--outlined {
  stroke: $b-nc-white;
}

// Main color on the icon in the button
.btn--w-icon--main-color {
  .ic--filled {
    fill: $b-mc-med;
  }
  &:hover .ic--filled,
  &:focus .ic--filled {
    fill: $b-mc-light;
  }

  .ic--outlined {
    stroke: $b-mc-med;
  }
  &:hover .ic--outlined,
  &:focus .ic--outlined {
    stroke: $b-mc-light;
  }
}

.btn--selectable {
  transform: none;
  &:after {
    display: none;
  }
}

// Utility Class for icon rotation
.btn--w-icon--rotation svg {
  transform: rotate(180deg) translateY(50%);
}

///// 05 Btn discrete /////
.button--discrete {
  @include txt--btn();
  cursor: pointer;
  background: transparent;
  border: none;
  color: $b-mc-med;
  display: flex;
  align-items: center;
  padding: 0;
  svg {
    transition: $tr--s;
    .icon-filled {
      fill: $b-mc-med;
    }
    .icon-stroked {
      stroke: $b-mc-med;
    }
  }
  &:hover,
  &:focus {
    color: $b-mc-light;
    svg {
      .icon-filled {
        fill: $b-mc-light;
      }
      .icon-stroked {
        stroke: $b-mc-light;
      }
    }
  }
  .icon-copy-container {
    position: relative;
    svg {
      position: absolute;
      &:first-of-type {
        opacity: 1;
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
    }
  }
  &.copied {
    .icon-copy-container {
      svg {
        &:first-of-type {
          opacity: 0;
        }
        &:nth-of-type(2) {
          opacity: 1;
        }
      }
    }
  }
  &--loader {
    min-width: 3rem;
    svg {
      margin: 0;
      width: inherit;
      height: inherit;
    }
  }
  &.rounded {
    border-radius: 50px;
    border: 2px solid $b-mc-med;
    &:hover {
      border: 2px solid $b-mc-light;
    }
    &.rounded--sm {
      width: 30px;
      height: 30px;
    }
  }
}

.button--discrete--fluid {
  width: 100%;
  align-items: center;
  span {
    text-align: left;
    width: calc(100% - 24px);
  }
  &:hover,
  &:focus {
    svg {
      animation-name: bounce--1;
      animation-timing-function: ease-in;
      animation-duration: 0.5s;
      transform: scale(1.2);
    }
  }
}

///// 06 Btn dropdown /////
.btn--dropdown {
  @include txt--med($main-font);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: $b-nc-dark;
  background: none;
  font-weight: $fw--bold;
  text-align: left;
  margin-top: 0;
  margin-bottom: $spc-x-small;
  padding-bottom: calc($spc-tiny / 2);
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid $b-nc-light;
  cursor: auto;
  svg {
    display: none;
  }
  @include breakpoint(max-m) {
    cursor: pointer;
    svg {
      display: block;
      width: $spc-x-small;
      height: $spc-x-small;
      transition: $tr--s;
    }
  }
}

.dropdown--section {
  margin-bottom: 1rem;
  @include breakpoint(max-m) {
    .dropdown--section__target {
      display: none;
      animation-name: fade-content;
      animation-duration: 0.3s;
      animation-timing-function: ease;
    }
    &.activate {
      .btn--dropdown {
        svg {
          transform: rotate(180deg);
        }
      }
      .dropdown--section__target {
        display: block;
      }
    }
  }
}

///// 07 Btn link-icon /////
.btn--link-icon {
  border-radius: 50%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  transition: $tr--m;

  svg {
    width: 1rem;
    height: 1rem;
    stroke: $b-nc-white;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    background: $b-mc-light;
    transform: scale(1.1);
    transition: $tr--m;
  }

  &:focus {
    outline: none;
    border: 2px solid $b-mc-med;
  }
}

.btn--copy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;

  &:hover,
  &:focus {
    color: $b-mc-med;
  }

  .icon-copy-container {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 0;
    }
  }

  .icon-copy-container,
  .ic--copy,
  .ic--check {
    width: 100%;
    height: 1.5rem;
  }

  .ic--copy,
  .ic--check {
    margin: 0;
    left: 0;
  }

  &__label {
    font-size: 0.5rem;
    line-height: 1;
    padding-top: 2px;
  }
}

///// 08 Btn pagination /////
.btn--pagination {
  color: $b-nc-med;
  padding: 0.6rem 0.8rem;
  border: none;
  line-height: 1;
  background: none;

  &:hover,
  &:focus {
    color: $b-mc-med;
    border-radius: $border-rds--l;
  }

  &--selected {
    color: $b-nc-white;
    font-weight: bold;
    background-color: $b-mc-med;

    &:hover,
    &:focus {
      color: $b-nc-white;
    }
  }
}

.btn--delete-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: $b-mc-med;
  padding: 0.2rem 0.5rem;
  border-top-right-radius: $border-rds--l;
  border-bottom-right-radius: $border-rds--l;
  svg {
    width: 0.8rem;
  }
}

.btn--icon {
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.buttons-container {
  button,
  a.ripple {
    @media (max-width: $screen-s) {
      margin: 0 0 0.5rem 0 !important;
    }
  }
}


///// Btn delete /////
.btn--delete {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  cursor: pointer;

  background: $b-uc-warning;
  border: 2px solid $b-uc-warning;
}