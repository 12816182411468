.patients-list-filters{
  background: $b-nc-light;

  @media only screen and (min-width: $screen-m) {
    display: flex;
    flex-flow: row wrap; 
    justify-content: space-between; 
    align-items: flex-start;
  }

  &__filters {
    display: flex; flex: 0 1 auto; margin: -0.5rem -0.5rem 0 0;
    flex-flow: row wrap; 

    button {
      margin: 0.5rem 0.5rem 0 0;
    }
  }

  &__input-search{
    flex: 1 1 20rem; margin-top: 1rem;

    @media only screen and (min-width: $screen-m){
      margin-top: 0; 
      margin-left: 0.5rem;
    }

    input {
      display: block; 
      margin: auto; 
      height: 100%; 
      width: 100%; 

      @media only screen and (min-width: $screen-m){
        margin: 0;
      }
    }
  }

  &__reset{
    display: block; 
    flex: 0 0 auto;
    margin-left: auto; 
    margin-top: 0.5rem;

    @media only screen and (min-width: $screen-m){
      margin-top: 0;
    }
  }
}