.summary-item {
  position: relative;
  padding: 1rem 1.5rem 0.5rem 0.5rem;
  border-radius: $border-rds--l;
  background-color: rgba($b-nc-25, 0.1);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    background-color: $b-nc-85;
    border: 0.5rem solid $b-nc-00;
    border-radius: 100%;
    transform: translate(-50%, -50%);
  }

  &--uc-positive {
    background-color: rgba($b-uc-positive, 0.1);
    color: $b-uc-positive;

    &::before {
      background-color: $b-uc-positive;
    }
  }

  &--uc-warning {
    background-color: rgba($b-uc-warning, 0.1);
    color: $b-uc-warning;

    &::before {
      background-color: $b-uc-warning;
    }
  }

  &--uc-caution {
    background-color: rgba($b-uc-caution, 0.1);
    color: $b-uc-caution;

    &::before {
      background-color: $b-uc-caution;
    }
  }

  &--uc-no-data {
    background-color: rgba($b-nc-med, 0.1);
    color: $b-nc-med;

    &::before {
      background-color: $b-nc-med;
    }
  }

  &--ac-3 {
    background-color: rgba($b-ac-3, 0.1);
    color: $b-ac-3;

    &::before {
      background-color: $b-ac-3;
    }
  }

  &--nc-50 {
    background-color: rgba($b-nc-50, 0.1);

    &::before {
      background-color: $b-nc-50;
    }

    .summary-item {
      &__percentage {
        color: $b-nc-50;
      }

      &__evolution {
        color: $b-nc-50;
      }
    }
  }

  &--nc-85 {
    background-color: rgba($b-nc-85, 0.1);

    &::before {
      background-color: $b-nc-85;
    }

    .summary-item {
      &__percentage {
        color: $b-nc-85;
      }

      &__evolution {
        color: $b-nc-85;
      }
    }
  }

  &__count {
    font-size: 3rem;
  }

  &__percentage {
    opacity: 0.5;
  }

  &__button {
    position: absolute;
    bottom: 0;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    transition: $tr--s;

    &:hover,
    &:focus {
      transform: scale(1.2);
      transition: $tr--s;
    }
  }
}
