.card {
  background: $b-nc-white;
  border-radius: $border-rds--l;
  box-shadow: $elevation-4-dp;

  &--no-shadow {
    box-shadow: none;
  }

  &--radius {
    border-radius: 20px;
  }
}

.payment-card {
  background: $b-nc-white;
  border-radius: $border-rds--xxl;
  box-shadow: $elevation-4-dp;
  padding: 50px 100px !important;
  
  @media (max-width: 600px) {
    padding: 50px 30px !important;
  }

}
