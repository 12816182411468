.accordion {
  border: 1px solid $b-nc-light;
  border-radius: $border-rds--l;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid $b-nc-light;
    }
  }

  &__header {
    display: flex;
    flex-flow: row nowrap; justify-content: space-between; align-items: center;
    transition: all 0.9s;
  }
}