/* Sticky footer */
html,
body,
.app-shell {
  height: 100%;
}

.main {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -6rem;
  &:after {
    content: "";
    display: block;
    height: 6rem;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0;
  background: $b-nc-white;
  padding: $spc-x-small $spc-small;
  @include txt--s($main-font);

  &__links {
    display: inline-block;

    li {
      white-space: nowrap;
    }

    @media screen and (min-width: $screen-sm) {
      ul {
        margin: 0;
      }
    }
  }

  &__logo {
    width: 100%;
    max-width: 5rem;
    svg {
      display: block;
      max-width: 5rem;
      max-height: 2.375rem;
      height: auto;
    }
  }

  @media screen and (min-width: $screen-sm) {
    // position: fixed;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    &__logo,
    &__links {
      margin: 0 $spc-medium 0 0;
    }
  }
}
