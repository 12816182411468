//GLOBAL

* {
  box-sizing: border-box;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

body::-moz-selection {
  background: $b-mc-light;
}

body::selection {
  background: $b-mc-light;
}

body {
  font-family: "Heebo", arial, sans-serif;
  color: $b-nc-med-dark;
  line-height: $base-line-height;
  background: $b-nc-light-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.hide-scrollbar {
    overflow: hidden;

    @include mf_breakpoint(min-xs) {
      overflow: auto;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $b-mc-med;
  font-family: $secondary-font;
  margin: 1.414em 0 0.5em;
  font-weight: $fw--bold;
  line-height: 1.2;
}

h1 {
  font-size: $main-heading;
}

h2 {
  font-size: $secondary-heading;
}

h3 {
  font-size: $third-heading;
}

h4 {
  font-size: $fourth-heading;
}

h5 {
  font-size: $fifth-heading;
}

.block-heading {
  font-size: $large-text;
  font-weight: 800;
  color: $b-mc-med;
  text-transform: uppercase;
  font-family: $secondary-font;
  &--center {
    margin-bottom: 2rem;
    text-align: center;
  }
}

.home-baseline {
  display: none; //Responsive Enhancement
  color: $b-mc-med;
  font-family: $secondary-font;
  line-height: 1.2;
  font-size: $main-heading;
  font-weight: $fw--reg;
  margin-bottom: 0;
}

// Media home-baseline
@media (min-width: $screen-sm) {
  .home-baseline {
    display: block; //Responsive Enhancement
  }
}

ul {
  padding-left: 0;
  list-style-type: none;
}

a {
  color: $b-mc-med;
  transition: $tr--s;
  text-decoration: none;

  .intus--link {
    color: $b-mc-light;
  }

  &:hover {
    color: $b-mc-light;
  }
}

strong {
  font-weight: $fw--bold;
}

hr {
  border: 0;
  height: 2px;
  margin: 2rem auto;
  background-color: $b-mc-light;
}

img {
  max-width: 100%;
  max-height: 100%;
}

table {
  border-collapse: collapse;
}

.input {
  line-height: 3;
  min-height: 3em;
  font-size: 1rem;
  padding-left: 1rem;
  margin-bottom: 1.777rem;
  &--default {
    width: 100%;
    background-color: $b-nc-white;
    border: 1px solid $b-nc-light;
    transition: $tr--s;
    &:focus {
      border-bottom: 1px solid $b-mc-light;
    }
  }
  &--files {
    width: 100%;
    background-color: $b-nc-white;
    border: 1px solid $b-nc-light;
    font-size: 1rem;
    line-height: 1;
    padding: 1rem;
    // margin-bottom: 0;
    transition: $tr--s;
  }
  &--checkbox {
    display: inline-block;
    float: left;
    width: 1rem;
    height: 1.3rem;
  }
}

.button {
  font-weight: $fw--bold;
  border: none;
  cursor: pointer;
  border-radius: $border-rds--l;
  font-family: Heebo, arial, sans-serif;
  color: $b-nc-white;
  &:disabled,
  [disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button--default {
  padding: 1.25rem 2.5rem;
  background-color: $b-mc-med;
  text-transform: uppercase;
  transition: $tr--s;
  &:hover {
    background-color: $b-mc-light;
    color: $b-nc-white;
  }
  &--center {
    display: block;
    margin: 0 auto;
  }
}

.button--small {
  display: inline-block;
  text-decoration: none;
  padding: 1.125rem;
  background-color: $b-mc-light;
  transition: $tr--s;
  opacity: 1;
  &:hover {
    background-color: $b-mc-med;
    color: $b-nc-white;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.button--xxl {
  display: block;
  padding: 2.25rem 4.75rem;
  box-shadow: 0 2px 4px 0px $b-nc-med;
  margin: 0 auto;
}

.button--secondary {
  display: inline-block;
  text-decoration: none;
  padding: 1.125rem;
  background-color: $b-nc-white;
  border: 2px solid $b-mc-med;
  color: $b-mc-med;
  transition: $tr--s;
  opacity: 1;
  &:hover {
    background-color: $b-mc-med;
    color: $b-nc-white;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.button--default--right {
  float: right;
}

.button--default--left {
  clear: left;
}

.button--single-page {
  position: relative;
  left: -1rem;
  top: -0.7rem;
}

.label {
  color: $b-nc-med-dark;
  line-height: 1.5rem;
}

.required-field {
  color: $b-uc-warning;
}

.hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
