
.section-extension-ordoclic-loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  
    .loader-info-text-container {
      display: block;
      text-align: center;
      flex: 0 0 100%;
  
      > p {
        margin: $spc-tiny 0;
      }
    }
  
    .spinner-container {
      position: relative;
      flex: 0 0 200px;
      height: 200px;
  
      .spinner-step-wrapper {
        position: absolute;
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        justify-content: center;
        vertical-align: center;
  
        top: 66px;
  
        width: 100%;
  
        .spinner-icon-container {
          margin-top: -$spc-x-small;
          margin-bottom: $spc-tiny;
        }

        .loader--basic {
            margin-top: $spc-tiny;
        }
  
        > p {
          flex: 0 0 100%;
          margin: 0;
          margin-bottom: $spc-x-tiny;
  
          &.spinner-step {
            font-size: 40px;
            margin-top: 0;
            line-height: 45px;
            font-weight: bold;
          }
        }
      }
    }
  
    &.loader {
      &--inProgress .spinner-step {
        color: $b-mc-med;
      }
  
      &--stopped .spinner-step {
        color: $b-uc-caution;
      }
  
      &--failed .spinner-step {
        color: $b-uc-warning;
      }

      &--finished {
        .spinner-step-wrapper {
          .spinner-icon-container {
            margin-top: -1.5rem;
            > svg {
              width: 38px;
              height: 38px;
            }
          }

          .spinner-step {
            color: $b-mc-med;
          }
        } 
        
      } 
    }
  
    #spinner {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }