@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

////////// Z-INDEX MIXIN //////////
// Usage: @include z-index(modal)
@mixin z-index($key) {
  z-index: map-get($z-index, $key);
}

@mixin dropdown-inactive() {
  opacity: 0;
  transform: translateY(-2rem);
  transition: all 0.1s ease-out 0.02s, transform 0.1s ease-in 0.02s, z-index 0s linear 0s;
}

@mixin dropdown-active() {
  opacity: 1;
  transform: translateY(0);
}

@mixin dropdown-overlay-inactive() {
  opacity: 0;
  transform: translateY(-2rem);
  transition: all 0.1s ease-out 0.02s, transform 0.1s ease-in 0.02s, z-index 0s linear 0s;
  position: absolute;
  z-index: -1;
}

@mixin dropdown-overlay-active() {
  @include z-index("upper");
  opacity: 1;
  transform: translateY(0);
}

////////// BOX-SHADOW HACK FOR BORDER INNER //////////
@mixin border--box-shadow($border-color) {
  box-shadow: inset 0px 0px 0px 2px $border-color;
}

////////// CUSTOM SCROLLBAR //////////
@mixin scrollbar--down--custom() {
  /* width */
  ::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: $b-nc-light-bg;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: $b-mc-med;
    border-radius: $border-rds--xl;
    //background-image: -webkit-linear-gradient(360deg, transparent, $b-mc-light);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: $b-mc-light;
  }
}

////////// MIXINS TEXT //////////
//h1
@mixin title--big() {
  font-family: $secondary-font;
  font-size: 2rem; //32px
  line-height: 1.25em;
  @media (min-width: $screen-sm) {
    font-size: 2.5rem; //40px
    line-height: 1.125em;
  }
  @media (min-width: $screen-m) {
    font-size: 3rem; //48px
    line-height: 1.05em;
  }
}

//h2
@mixin title--med() {
  font-family: $secondary-font;
  font-size: 1.625rem; //26px
  line-height: 1.154em;
  @media (min-width: $screen-sm) {
    font-size: 2rem; //32px
    line-height: 1.25em;
  }
  @media (min-width: $screen-m) {
    font-size: 2.25rem; //36px
    line-height: 1.25em;
  }
}

//h3
@mixin title--s() {
  font-family: $secondary-font;
  font-size: 1.375rem; //22px
  line-height: 1.14em;
  @media (min-width: $screen-sm) {
    font-size: 1.5rem; //24px
    line-height: 1.25em;
  }
  @media (min-width: $screen-m) {
    font-size: 1.75rem; //28px
    line-height: 1.25em;
  }
}

//h4
@mixin title--xs($font-family) {
  font-family: $font-family;
  font-size: 1.25rem; //20px
  line-height: 1.4em;
}

//Body big
@mixin txt--big($font-family) {
  font-family: $font-family;
  font-size: 1.125rem; //18px
  line-height: 1.11em;
  @media (min-width: $screen-sm) {
    line-height: 1.22em;
  }
}

//XL
@mixin txt--xl($font-family) {
  font-family: $font-family;
  font-size: 1.25rem; //20px
  line-height: 1.25em;
  @media (min-width: $screen-sm) {
    line-height: 1.25em;
  }
}

//Body normal
@mixin txt--med($font-family) {
  font-family: $font-family;
  font-size: 1rem; //16px
  line-height: 1.6em;
}

//Body small
@mixin txt--s($font-family) {
  font-family: $font-family;
  font-size: 0.75rem; //12px
  line-height: 1.25em;
  @media (min-width: $screen-sm) {
    line-height: 1.375em;
  }
}

//Body Btn
@mixin txt--btn() {
  font-family: $main-font;
  font-weight: $fw--med;
  font-size: 0.875rem; //14px
  line-height: 1.25em;
  @media (min-width: $screen-sm) {
    line-height: 1.375em;
  }
}

/* Media queries mixins */

@mixin breakpoint($class) {
  @if $class == max-xs {
    @media (max-width: $screen-xs - 1) {
      @content;
    }
  }

  @if $class == max-sm {
    @media (max-width: $screen-sm - 1) {
      @content;
    }
  }

  @if $class == max-m {
    @media (max-width: $screen-m - 1) {
      @content;
    }
  }

  @if $class == max-lg {
    @media (max-width: $screen-lg - 1) {
      @content;
    }
  }
}

@mixin mf_breakpoint($class) {
  @if $class == min-xs {
    @media (min-width: $screen-xs) {
      @content;
    }
  }

  @if $class == min-sm {
    @media (min-width: $screen-sm) {
      @content;
    }
  }

  @if $class == min-m {
    @media (min-width: $screen-m) {
      @content;
    }
  }

  @if $class == min-l {
    @media (min-width: $screen-l) {
      @content;
    }
  }

  @if $class == min-xl {
    @media (min-width: $screen-xl) {
      @content;
    }
  }

  @if $class == min-xxl {
    @media (min-width: $screen-xxl) {
      @content;
    }
  }

  @if $class == min-lg {
    @media (min-width: $screen-lg) {
      @content;
    }
  }
}

@mixin mf_height_breakpoint($class) {
  @if $class == min-xs {
    @media (min-height: $screen-xs) {
      @content;
    }
  }

  @if $class == min-sm {
    @media (min-height: $screen-sm) {
      @content;
    }
  }

  @if $class == min-m {
    @media (min-height: $screen-m) {
      @content;
    }
  }

  @if $class == min-lg {
    @media (min-height: $screen-lg) {
      @content;
    }
  }
}

/* Usage :

@include breakpoint(max-sm){
  {
    ...
  }
}

@include mf_breakpoint(min-sm){
  {
    ...
  }
}

*/
