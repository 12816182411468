.main-menu {
  position: fixed;
  top: 3.125rem;
  bottom: 0;
  left: -18rem;
  transition: left 0.7s ease;
  @include z-index("header");

  @media only screen and (min-width: $screen-sm) {
    display: block;
    top: 0;
    left: 0;
  }

  &__mask {
    width: 0;
    height: 0;
    position: fixed;
    top: 3.125rem;
    bottom: 0;
    left: 0;
    background: rgba($b-nc-85, 0.5);
    z-index: -1;
    opacity: 0;

    @media only screen and (min-width: $screen-sm) {
      display: none;
    }
  }

  &--triggered {
    left: 0;
    transition: left 0.7s ease;

    .main-menu__mask {
      width: 100vw;
      height: 100vh;
      opacity: 1;
      right: 0;
      transition: opacity 0.5s ease;
      @include z-index("element--absolute");
    }
  }

  [class*="icon"] {
    .icon-stroked {
      stroke-width: 1.5px;
    }
  }

  button,
  a {
    &:focus {
      outline: 2px solid $b-mc-med;
      outline-offset: -2px;
    }
  }

  &__first-level {
    height: calc(100vh - 3.125rem);
    width: 17.5rem;
    margin: 0;
    background: $b-nc-00;
    border-top: 1px solid $b-nc-25;
    overflow: auto;
    position: relative;
    @include z-index("header");

    @media only screen and (min-width: $screen-sm) {
      border-top: none;
      width: 4.5rem;
      height: 100vh;
      box-shadow: $elevation-4-dp;
      position: initial;
    }

    &__button,
    &__link {
      display: flex;
      height: 4.5rem;
      width: 100%;
      padding: 1rem;
      flex-flow: row nowrap;
      align-items: center;
      color: $b-nc-60;
      background: $b-nc-00;
      border: none;
      border-bottom: 1px solid $b-nc-25;
      cursor: pointer;
      transition: background 0.5s ease;

      @media only screen and (min-width: $screen-sm) {
        width: 4.5rem;
        padding: 0;
        justify-content: center;
        border-bottom: none;
      }

      &:hover {
        background: rgba($b-mc-med, 0.15);
      }

      &--triggered {
        background: $b-mc-med;
        color: $b-nc-00;

        &:hover {
          background: $b-mc-med;
        }
      }
    }

    &__icon {
      display: flex;
      align-items: center;

      svg {
        width: 2rem;
        height: 2rem;

        @media only screen and (min-width: $screen-sm) {
          width: 2.5rem;
          height: 2.5rem;
        }
      }

      img {
        padding: 0 .5rem;
      }
    }

    &__title {
      margin-left: 0.5rem;
      color: inherit;
      text-decoration: none;
      text-align: left;

      @media only screen and (min-width: $screen-sm) {
        display: none;
      }
    }

    &__icon-expand {
      display: block;
      margin-left: auto;
      width: 1.5rem;
      height: 1.5rem;

      @media only screen and (min-width: $screen-sm) {
        display: none;
      }
    }

    &__item {
      &--home {
        position: fixed;
        top: 0.5rem;
        right: 50%;
        padding: 0;
        transform: translateX(50%);

        @media only screen and (min-width: $screen-sm) {
          position: relative;
          top: auto;
          right: auto;
          transform: none;
        }

        .main-menu__first-level__title {
          display: none;
        }

        .main-menu__first-level__link {
          height: auto;
          padding: 0;
          background: none;
          border-bottom: none;

          @media only screen and (min-width: $screen-sm) {
            width: 4.5rem;
            height: 4.5rem;
            border-bottom: 1px solid $b-nc-25;
          }
        }

        .main-menu__first-level__icon svg {
          height: 1.875rem;
          width: auto;

          @media only screen and (min-width: $screen-sm) {
            width: 3rem;
            height: auto;
          }
        }
      }

      &--help {
        margin: 1rem;

        @media only screen and (min-width: $screen-sm) {
          display: none;
        }
      }
    }
  }

  &__second-level {
    background: $b-nc-00;

    @media only screen and (min-width: $screen-sm) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 18.75rem;
      height: 100vh;
      border-left: 1px solid $b-nc-25;
      box-shadow: $elevation-4-dp;
      transform: translateX(100%);
      z-index: -1;
      overflow: auto;
    }

    &__title {
      padding: 0.5rem 1rem;
      margin: 0;
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      background: $b-nc-25;

      @media only screen and (min-width: $screen-sm) {
        font-size: 1rem;
      }
    }
  }

  &__third-level {
    &__link {
      display: flex;
      padding: 1rem;
      width: 100%;
      flex-flow: row nowrap;
      align-items: center;
      color: $b-nc-60;
      text-decoration: none;
      font-size: 0.75rem;
      cursor: pointer;
      transition: background 0.5s, color 0.5s;

      @media only screen and (min-width: $screen-sm) {
        font-size: 1rem;
      }

      span {
        margin-left: 0.5rem;
      }

      svg {
        flex: 0 0 2rem;
        width: 2rem;
        height: 2rem;

        &.ic--expand--more {
          margin-left: auto;
          transform: rotate(-90deg);
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &:hover {
        background: rgba($b-mc-med, 0.15);
        color: $b-mc-med;
      }

      &--active {
        background: $b-mc-med;
        color: $b-nc-00;

        &:hover {
          background: $b-mc-med;
          color: $b-nc-00;
        }
      }

      &.disabled--element:hover {
        color: $b-nc-60;
      }
    }
  }

  &-enter-active,
  &-leave-active {
    transition: transform 0.6s ease, opacity 0.6s ease;
    @media only screen and (min-width: $screen-sm) {
      transform: translateX(100%);
    }
  }
  &-enter,
  &-leave-to {
    opacity: 0;
    @media only screen and (min-width: $screen-sm) {
      transform: translateX(80%);
    }
  }
}

.prescriber-menu {
  position: fixed;
  top: 3.125rem;
  bottom: 0;
  left: 0;
  width: 17.5rem;
  background: $b-nc-00;
  box-shadow: $elevation-4-dp;
  transition: $tr--s;
  transform: translateX(-100%);
  @include z-index("header");

  @media only screen and (min-width: $screen-sm) {
    top: 4.5rem;
  }

  @media only screen and (min-width: $screen-m) {
    position: initial;
    top: auto;
    left: auto;
    background: none;
    box-shadow: none;
    width: 100%;
    max-width: #{11.5 * 4}rem;
    transform: translateX(0);
  }

  &--triggered {
    transform: translateX(0);
    transition: $tr--s;
  }

  &__first-level {
    margin: 0;

    @media only screen and (min-width: $screen-m) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    &__link {
      display: flex;
      height: 4.5rem;
      width: 100%;
      flex: 0 0 calc(100% / 5);
      padding: 1rem;
      flex-flow: row nowrap;
      align-items: center;
      color: $b-nc-85;
      background: $b-nc-00;
      border: none;
      border-bottom: 1px solid $b-nc-25;
      cursor: pointer;
      transition: background 0.5s ease;

      @media only screen and (min-width: $screen-m) {
        padding: 0;
        max-width: 11.5rem;
        justify-content: center;
        flex-flow: column nowrap;
        border-bottom: none;
      }

      &:hover {
        background: rgba($b-mc-med, 0.15);
        color: $b-nc-60;
      }

      &--triggered {
        background: $b-mc-med;
        color: $b-nc-00;

        &:hover {
          background: $b-mc-med;
          color: $b-nc-00;
        }
      }
    }

    &__icon svg {
      width: 2rem;
      height: 2rem;
    }

    &__title {
      margin-left: 0.5rem;
      color: inherit;
      text-decoration: none;
      text-align: left;
      font-family: $secondary-font;
      font-weight: 700;
      font-size: 0.75rem;

      @media only screen and (min-width: $screen-m) {
        margin-left: 0;
        text-align: center;
      }
    }
  }
}
