// Single
.page-single {
  width: 100%;
  padding: 1rem;
  background: $b-nc-white;
  margin-bottom: 5.625rem;
  overflow-y: auto;
  overflow-x: hidden;
  &__header {
    position: relative;
    .content-type {
      position: absolute;
    }
  }
  &__content {
    margin: 0;
    padding: 0;
    line-height: 2;
    float: none;
    h1 {
      margin-top: 0;
      color: $b-ac-1;
      font-weight: bold;
    }
    .content-preamble {
      color: $b-ac-1;
      @include title--xs($secondary-font);
    }
  }
  &__contact-form {
    margin-top: 1rem;
    padding: 1rem;
    background-color: $b-nc-light-bg;
  }
  &__sidebar {
    margin: 0;
    position: relative;
    padding: 0;
    float: none;
    .carousel__nav {
      top: 75%;
    }
    .carousel__nav img {
      background: $b-ac-1;
    }
  }
  &__sidebar-practical {
    background: $b-ac-1;
    padding: 1.875rem;
    color: $b-nc-white;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    margin: 0 0 1.25rem 0;
    h3 {
      color: $b-nc-white;
      @include title--med();
    }
  }
}

@media (min-width: $screen-m) {
  .page-single {
    &__sidebar {
      margin-top: -15%;
      padding: 1rem 3.25rem;
    }
    &__content {
      margin-top: 1rem;
      padding: 1rem;
    }
  }
}

/* Specific DMs & Events overrides */

.news-single {
  .page-single {
    &__header .content-type span {
      background-color: $b-ac-2;
    }
    &__content {
      h1 {
        color: $b-mc-light;
      }
      .content-preamble {
        color: $b-mc-light;
      }
    }
    &__sidebar-practical {
      background-color: $b-mc-light;
    }
  }
  &__link {
    @include title--xs($main-font);
    color: $b-nc-white;
  }
  .list-item__new {
    top: calc($spc-small / 2);
    right: -65px;
  }
}

.bastide-extended-date {
  color: $b-nc-white;
  @include title--med();
  span {
    font-weight: bold;
  }
}

.baside-url-reference a {
  display: block;
  color: $b-nc-white;
  margin-bottom: 1.414em;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.bastide-call-me-back {
  background: $b-mc-light;
  padding: 1.75rem;
  margin: 0 0 1.25rem 0;
  text-align: center;
  display: none;
  a {
    color: $b-nc-white;
    @include title--xs($main-font);
    text-decoration: none;
  }
  a::before {
    content: url(../assets/common/hover-arrow.svg);
    margin-right: 1rem;
  }
  label {
    color: $b-nc-white;
    text-align: left;
  }
  .input {
    width: 280px;
  }
  .button--default {
    padding: 1rem 2.5rem;
    border: 1px solid $b-nc-white;
  }
  @media (min-width: $screen-xs) {
    display: block;
  }
}

.bastide-call-me-back-not {
  background: $b-mc-light;
  padding: 1.75rem;
  margin: 1.25rem 0;
  text-align: center;
  color: $b-nc-white;
  @include title--xs($main-font);
}

/* To be removed When transition is in place */

/* @clem 09/06 - Commented hover toggle because of Vue implementation */

.bastide-call-me-back {
  cursor: pointer;
}

/* Contact page */

.contact,
.legal-terms {
  max-width: 48.75em;
  margin-left: auto;
  margin-right: auto;
}

.contact__form,
.contact__infos {
  width: 100%;
}

.contact__form {
  padding: 0 0 1rem 0;
  border-right: none;
  border-bottom: 1px solid $b-nc-med;
  margin-bottom: 2rem;
}
