.doughnut-legend, .chart-legend {
  display: flex;
  &-color {
    display: block;
    margin-right: .3rem;
    line-height: 1;
  }
}

.doughnut-legend {
  margin-top: .8rem;
  &-color {
    width: .7rem;
    height: .7rem;
    margin-top: .2rem;
    border-radius: 50%;
  }
  &-info {
    span {
      display: block;
    }
  }
}

.chart-legend {
  justify-content: center;
  &-color {
    width: 3rem;
    height: 1rem;
    margin-top: .2rem;
    border-radius: 1rem;
  }
}