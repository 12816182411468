.card-mrg {
  margin-bottom: $spc-medium;
  @media (max-width: 480px) {
    margin-bottom: calc($spc-medium/2);
  }
}

.margin--bottom--small {
  margin-bottom: 1.5rem;
}

.margin--mobile--top--small {
  margin-top: 1.5rem;
}

@media screen and (min-width: $screen-m) {
  .margin--mobile--top--small {
    margin-top: 0;
  }
}
