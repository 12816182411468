.list__downloads__btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &.centered {
    justify-content: center;
  }

  & > li {
    width: 100%;
    margin-bottom: $spc-tiny;
  }

  .btn--download {
    max-width: 90%;
    margin: 0 auto;
  }

  @include mf_breakpoint(min-xs) {
    flex-direction: row;

    & > li {
      width: 50%;
      margin-bottom: 0;
    }
  }
}

.list-file__close {
  float: right;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(10px, -50%);
  line-height: 0;
  padding: 10px;
}

.label-value--list {
  @include txt--s($main-font);
  color: $b-nc-med-dark;
}

.label-value--list__label {
  margin: 0;
}

.label-value--list__list {
  margin: 0;
  font-weight: $fw--bold;
  color: $b-nc-dark;
}
