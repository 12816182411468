// APP
.app-shell {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-wrapper {
  margin: 2rem auto 2rem auto;
  padding: 0 16px;
  @media screen and (min-width: 940px) {
    padding: 0;
  }
}

.pattern-test {
  background-color: green;
}
