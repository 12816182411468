hr {
  border: 0;
  height: 0.0625rem;
  margin: 2rem auto;
  background-color: $b-mc-light;
}

.hr-s {
  height: 0.0625rem;
}

.hr-med {
  height: 0.125rem;
}

.hr--mc-med {
  background-color: $b-mc-med;
}
