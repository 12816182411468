///// LABEL-VALUE /////
.label-value {
  p {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--input p {
    margin-bottom: $spc-tiny !important;
  }
}

.label-value--wrap {
  word-break: break-all;
}

.label-value__label {
  color: $b-nc-med-dark;
  @include txt--s($main-font);

  &--input {
    color: $b-nc-med-dark;
    font-weight: bold;
  }
}

.label-value__value {
  color: $b-nc-dark;
}

.label-value__value--positive {
  color: $b-uc-positive;
}

.label-value__value--warning {
  color: $b-uc-warning;
}

.label-value__value--link {
  color: $b-mc-med;
  cursor: pointer;
  text-decoration: none;
}

.label-value__value--address {
  address {
    display: flex;
    flex-wrap: wrap;
  }
}

.label-value__details {
  color: $b-nc-med-dark;
  @include txt--s($main-font);

  &--input {
    color: $b-nc-med-dark;
    font-weight: bold;
  }
}

.label-value--s {
  max-width: 7.5rem;
}

//label-value--big
.label-value--big {
  padding: 1rem;
  .label-value__label {
    @include txt--s($main-font);
    margin: 0;
  }

  .label-value__value {
    @include title--med();
    font-weight: $fw--bold;
    margin: 0;
    color: $b-nc-60;
  }

  .label-value__value--big {
    @include title--med();
    font-weight: bold;
  }

  .label-value__value--big--m {
    @include title--xs($secondary-font);
  }

  &--warning,
  &--caution,
  &--positive {
    .label-value__label,
    .label-value__value {
      color: $b-nc-00;
    }
  }

  &--warning {
    background-color: $b-uc-warning;
  }

  &--caution {
    background-color: $b-uc-caution;
  }

  &--positive {
    background-color: $b-uc-positive;
  }
}

// label-value--check
.label-value--check {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  &--positive {
    background: rgba($b-uc-positive, 0.25);
  }

  &--warning {
    background: rgba($b-uc-warning, 0.25);
  }
}

// link--promotional
.link--promotional {
  padding-left: 2.5rem;
  display: inline-block;
  text-decoration: none;
  font-weight: $fw--bold;
  color: $b-nc-dark;
  transition: $tr--s;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    display: block;
    width: $spc-medium;
    height: $spc-medium;
    background-size: calc($spc-large / 2);
    background-repeat: no-repeat;
    background-position: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: $tr--s;
    background-image: url(../assets/common/icon_exitWebsite--mc-light.svg);
  }
  &:hover {
    color: $b-mc-light;
    &:before {
      background-size: $spc-small;
    }
  }
}

.link--promotional__subtext {
  @include txt--s($main-font);
  font-weight: $fw--reg;
  color: $b-nc-med-dark;
  margin: 0;
}

.circle-numbers {
  display: flex;
  position: relative;
  width: $spc-large;
  height: $spc-large;
  border: 2px solid $b-mc-med;
  border-radius: 100%;

  &--with-icon {
    background: $b-mc-med;

    > svg {
      margin: auto;
      width: $spc-small;
      height: $spc-small;
    }

    &:before {
      content: unset !important;
    }
  }

  &--filled {
    &--positive {
      &:before {
        color: $b-nc-white !important;
      }
      border: unset;
      background-color: $b-uc-positive;
    }
    &--warning {
      &:before {
        color: $b-nc-white !important;
      }
      border: unset;
      background-color: $b-uc-warning;
    }
    &--caution {
      &:before {
        color: $b-nc-white !important;
      }
      border: unset;
      background-color: $b-uc-caution;
    }
    &--neutral {
      &:before {
        color: $b-nc-85 !important;
      }
      border: unset;
      background-color: $b-nc-15;
    }
  }

  &:before {
    content: attr(attr-nbr);
    color: $b-mc-med;
    font-weight: bold;
    margin: auto;
  }
}
