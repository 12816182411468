// HOME

.nav-doctor {
  margin-bottom: 2rem;
  ul {
    margin: 0;
  }
}

.tile-news,
.tile-events {
  margin-bottom: 2.5rem;
  padding-bottom: 1rem;
  background-color: $b-nc-white;
  box-shadow: 0 10px calc($spc-large / 2) 0 rgba(0, 0, 0, 0.1);
  .carousel {
    min-height: 100%;
    @media (min-width: $screen-sm) {
      min-height: 30rem;
    }
    @media (min-width: $screen-m) {
      min-height: 35rem;
    }
  }
  .button {
    margin-left: 1.875rem;
  }
}

// @media (min-width: $screen-sm) {
//   .tile-news, .tile-events, .carousel{
//     min-height: 34rem;
//   }
// }

/* Tile specific colors and things */

.tile-news .button {
  background-color: $b-ac-2;
  &:hover {
    opacity: 0.75;
  }
}

.tile-news .carousel__prev,
.tile-news .carousel__next {
  img {
    background: $b-ac-2;
  }
}

.tile-news .carousel h2 {
  background-color: $b-ac-2;
}

.tile-events .button {
  background-color: $b-ac-1;
  &:hover {
    opacity: 0.75;
  }
}

.tile-events .carousel__prev,
.tile-events .carousel__next {
  img {
    background: $b-ac-1;
  }
}

.tile-events .carousel h2 {
  background-color: $b-ac-1;
  //width: 17.25rem;
}

.tile-brand {
  margin-bottom: 2.5rem;
  @include clearfix();
  img {
    width: 100%;
  }
  h1 {
    @include breakpoint(max-sm) {
      display: none;
    }
  }
}

.tile-public .carousel {
  display: block;
}
