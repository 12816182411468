// Helper Functions

/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function lightpick-str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
      @return str-slice($string, 1, $index - 1) + $replace + lightpick-str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

/// Split `$string` between the `$separator` characters
/// @author https://stackoverflow.com/a/42295154/108816
/// @param {String} $string - Initial string
/// @param {String} $separator - Substring to split on
/// @return {List} - Split string
@function lightpick-str-split($string, $separator) {
  $split-arr: ();
  $index: str-index($string, $separator);

  @while $index != null {
      $item: str-slice($string, 1, $index - 1);

      $split-arr: append($split-arr, $item);

      $string: str-slice($string, $index + 1);

      $index: str-index($string, $separator);
  }

  $split-arr: append($split-arr, $string);

  @return $split-arr;
}

// SCSS Variables

$lightpick-font: $main-font;
$lightpick-line-height: $base-line-height;
$lightpick-border-radius: $border-rds--l;
$lightpick-padding: $spc-tiny;
$lightpick-color:$b-nc-60;
$lightpick-background-color: $b-nc-00;
$lightpick-months-background-color: $b-nc-15;
$lightpick-title-font-size: $base-font-size;
$lightpick-title-font-weight: $fw--reg;
$lightpick-day-of-week-font-size: $base-font-size;
$lightpick-day-of-week-font-weight: $fw--bold;
$lightpick-day-hover-color: $b-nc-15;
$lightpick-day-hover-background-image-color: lightpick-str-replace(quote(#{$lightpick-day-hover-color}), '#', '%23');
$lightpick-day-hover-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='#{$lightpick-day-hover-background-image-color}' cx='16' cy='16' r='16'/%3E%3C/svg%3E") !default;
$lightpick-day-disabled-opacity: 0.38 !default;
$lightpick-day-previous-next-opacity: $lightpick-day-disabled-opacity !default;
$lightpick-day-size: $spc-large;
$lightpick-day-font-size: $base-font-size;
$lightpick-selected-date-color:$b-nc-00;
$lightpick-selected-date-background-color:$b-mc-med;
$lightpick-selected-date-background-image-color: lightpick-str-replace(quote(#{$lightpick-selected-date-background-color}), '#', '%23');
$lightpick-selected-date-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='#{$lightpick-selected-date-background-image-color}' cx='16' cy='16' r='16'/%3E%3C/svg%3E") !default;
$lightpick-selected-date-font-weight: $fw--bold;
$lightpick-selected-range-background-color: rgba($lightpick-selected-date-background-color, 0.1) !default;
$lightpick-selected-range-hover-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='#{rgba($lightpick-selected-date-background-color, 0.5)}' cx='16' cy='16' r='16'/%3E%3C/svg%3E") !default;
$lightpick-today-color: $b-mc-light;
$lightpick-today-background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='#{rgba($lightpick-today-color, .5)}' cx='16' cy='16' r='16'/%3E%3C/svg%3E") !default;
$lightpick-tooltip-background-color: $b-nc-00;
$lightpick-tooltip-padding: 4px 8px !default;
$lightpick-tooltip-margin: -(unquote(nth(lightpick-str-split(quote(#{$lightpick-tooltip-padding}), ' '), 1))) !default;
$lightpick-tooltip-triangle-size: 4px !default;
$lightpick-tooltip-border-color: rgba(0, 0, 0, 0.12) !default;
$lightpick-tooltip-font-size: 11px !default;
$lightpick-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) !default;
$lightpick-dropdown-font-size: 1em !default;
$lightpick-dropdown-font-weight: bold !default;
$lightpick-dropdown-disabled-color: $b-nc-50;
$lightpick-action-width: 32px !default;
$lightpick-action-height: $lightpick-action-width !default;
$lightpick-action-background-color:$b-nc-15;
$lightpick-action-reset-background-color: $b-nc-15;
$lightpick-action-reset-color: $b-nc-00;
$lightpick-action-apply-background-color: $b-mc-med;
$lightpick-action-apply-color: $b-nc-white;
$lightpick-action-border-radius: $border-rds--l;
$lightpick-action-font-size: $base-font-size;
$lightpick-action-close-font-size: $base-font-size;

// Styles

.lightpick {
  position: absolute;
  z-index: 99999;
  padding: $lightpick-padding;
  border-radius: $lightpick-border-radius;
  background-color: $lightpick-background-color;
  box-shadow: $lightpick-box-shadow;
  color: $lightpick-color;
  font-family: $lightpick-font;
  line-height: $lightpick-line-height;

  &--inlined {
      position: relative;
      display: inline-block;
  }

  &,
  & *,
  &::after,
  &::before {
      box-sizing: border-box;
  }

  &.is-hidden {
      display: none;
  }

  &__months {
      display: grid;
      background-color: $lightpick-months-background-color;
      grid-template-columns: auto;
      grid-gap: 1px;

      @at-root .lightpick--2-columns #{&} {
          grid-template-columns: auto auto;
      }

      @at-root .lightpick--3-columns #{&} {
          grid-template-columns: auto auto auto;
      }

      @at-root .lightpick--4-columns #{&} {
          grid-template-columns: auto auto auto auto;
      }

      @at-root .lightpick--5-columns #{&} {
          grid-template-columns: auto auto auto auto auto;
      }
  }

  &__month {
      padding: $lightpick-padding;
      width: ($lightpick-day-size * 7) + ($lightpick-padding * 2);
      background-color: $lightpick-background-color;

      &-title-bar {
          display: flex;
          margin-bottom: $lightpick-padding;
          justify-content: space-between;
          align-items: center;
      }

      &-title {
          margin-top: $lightpick-padding;
          margin-bottom: $lightpick-padding;
          margin-left: $lightpick-padding;
          font-size: $lightpick-title-font-size;
          font-weight: $lightpick-title-font-weight;
          line-height: $lightpick-title-font-size * 1.5;
          cursor: default;
          padding: 0 $lightpick-padding;
          border-radius: $lightpick-border-radius;

          & > .lightpick__select {
            font-family: $lightpick-font;
            color: $lightpick-color;
            border: none;
            background-color: transparent;
            outline: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;

            &:disabled {
                color: $lightpick-dropdown-disabled-color;
            }
          }

          & > .lightpick__select-months {
            color: $lightpick-color;
            font-family: $lightpick-font;
            font-weight: $lightpick-dropdown-font-weight;
            font-size: $lightpick-dropdown-font-size;
            margin-right: 0.5em;
          }
      }
  }

  &__toolbar {
      display: flex;
      text-align: right;
      justify-content: flex-end;
  }

  &__previous-action,
  &__next-action,
  &__close-action {
      display: flex;
      margin-left: 6px;
      width: $lightpick-action-width;
      height: $lightpick-action-height;
      outline: none;
      border: none;
      border-radius: 50%;
      background-color: $lightpick-action-background-color;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:active {
          color: inherit;
      }
  }

  &__previous-action,
  &__next-action {
      font-size: $lightpick-action-font-size;
  }

  &__close-action {
      font-size: $lightpick-action-close-font-size;
  }

  &__days-of-the-week {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
  }

  &__day-of-the-week {
      display: flex;
      font-size: $lightpick-day-of-week-font-size;
      font-weight: $lightpick-day-of-week-font-weight;
      justify-content: center;
      align-items: center;
  }

  &__days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
  }

  &__day {
      display: flex;
      height: $lightpick-day-size;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      font-size: $lightpick-day-font-size;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.is-today {
          background-image: $lightpick-today-background-image;
          background-size: 18.8% auto;
          background-position: center bottom;
          color: $lightpick-today-color;
      }

      &:not(.is-disabled):hover {
          background-size: contain;
          background-image: $lightpick-day-hover-background-image;
      }

      &.is-disabled {
          opacity: $lightpick-day-disabled-opacity;
          pointer-events: none;

          &.is-forward-selected {
              opacity: 1;

              &:not(.is-start-date) {
                  background-color: $lightpick-selected-range-background-color;
                  background-image: none;
              }
          }
      }

      &.disabled-tooltip {
          pointer-events: auto;
      }

      &.is-previous-month,
      &.is-next-month {
          opacity: $lightpick-day-previous-next-opacity;
      }

      &#{&}.is-in-range:not(.is-disabled) {
          opacity: 1;
      }

      &.is-in-range {
          border-radius: 0;
          background-color: $lightpick-selected-range-background-color;
          background-image: none;

          &:hover {
              background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(38, 139, 210, 0.5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
          }
      }

      &.is-start-date.is-in-range,
      &.is-end-date.is-in-range.is-flipped {
          border-top-left-radius: 50%;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 50%;
          background-color: $lightpick-selected-date-background-color;
          background-image: none;
      }

      &.is-end-date.is-in-range,
      &.is-start-date.is-in-range.is-flipped {
          border-top-left-radius: 0;
          border-top-right-radius: 50%;
          border-bottom-right-radius: 50%;
          border-bottom-left-radius: 0;
          background-color: $lightpick-selected-date-background-color;
          background-image: none;
      }

      &.is-start-date.is-end-date {
          background-color: transparent;
          background-image: $lightpick-selected-date-background-image;
      }

      &.is-start-date,
      &.is-end-date,
      &.is-start-date:hover,
      &.is-end-date:hover {
          background-image: $lightpick-selected-date-background-image;
          background-size: auto;
          background-position: center;
          color: $lightpick-selected-date-color;
          font-weight: $lightpick-selected-date-font-weight;
      }
  }

  &__tooltip {
      position: absolute;
      margin-top: $lightpick-tooltip-margin;
      padding: $lightpick-tooltip-padding;
      border-radius: $lightpick-border-radius;
      background-color: $lightpick-tooltip-background-color;
      box-shadow: $lightpick-box-shadow;
      white-space: nowrap;
      font-size: $lightpick-tooltip-font-size;
      pointer-events: none;

      &::before {
          position: absolute;
          bottom: -($lightpick-tooltip-triangle-size + 1);
          left: calc(50% - #{$lightpick-tooltip-triangle-size + 1});
          border-top: ($lightpick-tooltip-triangle-size + 1) solid $lightpick-tooltip-border-color;
          border-right: ($lightpick-tooltip-triangle-size + 1) solid transparent;
          border-left: ($lightpick-tooltip-triangle-size + 1) solid transparent;
          content: '';
      }

      &::after {
          position: absolute;
          bottom: -$lightpick-tooltip-triangle-size;
          left: calc(50% - #{$lightpick-tooltip-triangle-size});
          border-top: $lightpick-tooltip-triangle-size solid $lightpick-tooltip-background-color;
          border-right: $lightpick-tooltip-triangle-size solid transparent;
          border-left: $lightpick-tooltip-triangle-size solid transparent;
          content: '';
      }
  }

  &__footer {
      display: flex;
      justify-content: space-between;
  }

  &__reset-action,
  &__apply-action {
      border-radius: $lightpick-action-border-radius;
      font-size: $lightpick-action-font-size;
      border: none;
  }

  &__reset-action {
      color: $lightpick-action-reset-color;
      background-color: $lightpick-action-reset-background-color;
  }

  &__apply-action {
      color: $lightpick-action-apply-color;
      background-color: $lightpick-action-apply-background-color;
  }
}