.status-icon, .info-block, .stats-block {
  .positive, .default {
    color: $b-uc-positive;
    stroke: $b-uc-positive;
  }
  .equal {
    color: $b-uc-caution;
    stroke: $b-uc-caution;
  }
  .negative {
    color: $b-uc-warning;
    stroke: $b-uc-warning;
  }
}

.status-icon, .info-block {
  .positive, .default {
    background: rgba($b-uc-positive, .1);
  }
  .equal {
    background: rgba($b-uc-caution, .1);
  }
  .negative {
    background: rgba($b-uc-warning, .1);
  }
}

.status-icon > div {
  position: absolute;
  bottom: 1rem; 
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem; 
  height: 2rem;
  border-radius: $border-rds--circle;
}

.info-block {
  position: relative;
  padding: 2rem 3rem;
  border: 1px solid $b-nc-light;
  border-radius: 20px;
  text-align: center;
  height: 100%;
  &-value, &-label {
    display: block;
  }
  &-value {
    width: 6rem;
    font-size: 2rem;
    font-weight: bold;
    padding: 1rem 0;
    margin: auto;
    background: red;
    border-radius: $border-rds--xl;
  }
  &-label {
    font-size: 1.25rem;
    margin-top: 1rem;
  }
  &-icon {
    position: absolute;
    bottom: 1rem; 
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem; 
    height: 2rem;
    border-radius: $border-rds--circle;
  }
}

.stats-block {
  position: relative;
  &, & > * {
    height: 100%;
  }
  .status-icon > div {
    position: static;
  }
  h4 {
    color: $b-nc-50;
    margin: 0 0 0 .5rem;
  }
  &-header, &-values {
    display: flex;
  }
  &-header {
    align-items: center;
  }
  &-values {
    align-items: end;
    margin: 1.2rem 0;
  }
  span {
    display: block;
    font-weight: bold;
    line-height: 1;
  }
}

.stats-line {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  padding: 1rem 1.2rem 1rem 0;
  margin-bottom: 1rem;
  &-value {
    color: $b-mc-med;
  }
}