// SEARCH BAR

.search-bar {
  margin-bottom: 2rem;
}

.search-bar__header {
  padding: 2rem 0 0;
  &__input {
    input {
      width: 100%;
      padding: 1rem 0 1rem 1rem;
      @include txt--med($main-font);
    }
  }
}

.input-wrapper::after {
  content: url(../assets/common/icon_magnifiying-glass.svg);
  float: right;
  width: 1.25rem;
  transform: translateX(-1em) translateY(-2.25em);
}

.search-bar__main {
  @include dropdown-overlay-inactive();
  width: 100%;
  &__input__results {
    margin-top: 0;
    border: 1px solid $b-nc-light-bg;
    background-color: $b-nc-white;
    list-style-type: none;
    &__item {
      padding: 1rem;
      border-bottom: 2px solid $b-nc-light-bg;
      font-size: 30px; //Need to check if this class is called
    }
  }
  &__filter {
    padding: 1rem;
    border: 1px solid $b-nc-light;
    border-left: $spc-tiny solid $b-mc-light;
    border-top: 0;
    background-color: white;
    box-shadow: 0px $spc-x-small $spc-small calc($spc-tiny / 2) rgba(0, 0, 0, 0.15);
    width: 100vw;
    max-width: 100%;
    max-height: 60vh;
    overflow: auto;
  }
}

.main-wrapper--back {
  .search-bar__main {
    max-width: unset;
    width: auto;
  }
}

.search-bar--active {
  .search-bar__main {
    @include dropdown-overlay-active();
  }
}

.sub-filter__header {
  border-bottom: 1px solid $b-mc-med;
}

.sub-filter__header,
.fabricant--filter__header {
  width: 90%;
  margin: 0 auto 1rem auto;
  padding: 0.2rem;
  border-bottom: 1px solid $b-nc-light;
  font-size: 1rem; // Don't add include txt on this, It needs to be a fix value for the moment
  font-weight: $fw--bold;
  line-height: 0;
  p {
    text-align: center;
  }
}

.search-bar__sub__filter {
  margin: 0 auto;
  padding-left: 5%;
  transition: $tr--s;
}

.search-bar__buttons {
  padding: 0;
  float: right;
  clear: left;
}

.search-bar__sub-filter {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: $screen-xs) {
  .search-bar__main__filters {
    padding: 1rem 2rem;
  }
  .search-bar__buttons {
    padding: $spc-tiny 1rem;
  }
}

@media screen and (min-width: $screen-sm) {
  .search-bar__sub-filter {
    width: 50%;
    float: left;
  }
}

@media screen and (min-width: $screen-m) {
  .search-bar__sub-filter {
    width: 25%;
    float: left;
  }
}

.filterforfait-container {
  position: relative;
}

.sub-filter__header__filterforfait--trigger {
  margin-bottom: 0;
  color: $b-nc-white;
  background-color: $b-mc-light;
  border: 1px solid $b-mc-light;
  cursor: pointer;
  transition: $tr--s;
  &:hover {
    background-color: $b-mc-med;
  }
}

.sub-filter__header.sub-filter__header--reverse {
  background-color: white;
  color: $b-mc-light;
  border: 1px solid $b-mc-light;

  &:hover {
    color: $b-mc-med;
    border: 1px solid $b-mc-med;
  }
}

.compliance-label {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: $border-rds--circle;
  margin-left: $spc-tiny;
  &--green {
    background-color: $b-uc-positive;
  }
  &--orange {
    background-color: $b-uc-caution;
  }
  &--red {
    background-color: $b-uc-warning;
  }
}

.sub-filter__indication {
  display: inline-block;
  width: 100%;
  @include txt--s($main-font);
}

.filterforfait__ul--niveau1,
.filterforfait__ul--niveau2,
.filterforfait__ul--niveau3 {
  position: absolute;
  display: none;
  width: 95%;
  margin-top: 0;
  border: 1px solid $b-nc-light;
  background-color: white;
  @include z-index("header");

  li {
    padding: 0.8rem 1rem;
    @include txt--s($main-font);
    font-weight: $fw--bold;
    cursor: pointer;
    border-bottom: 1px solid $b-nc-light-bg;
    border-left: calc($spc-tiny / 2) solid transparent;
    transition: $tr--s;
  }
}

.filterforfait__ul--niveau1 {
  li:hover,
  li:active {
    border-left: calc($spc-tiny / 2) solid $b-mc-light;
  }
}

.filterforfait__ul--niveau2 {
  top: -1px;
  left: 312px;
}

.filterforfait__ul--niveau3 {
  top: -1px;
  left: 270px;
}

/* typeAhead */

.bastide-typeahead__list {
  margin-top: 0;
  border: 1px solid $b-nc-light;
  border-top: none;
  * {
    transition: unset;
  }
  &__item {
    background-color: $b-nc-white;
    padding: 1rem;
    border-bottom: 1px solid $b-nc-light;
    a {
      color: $b-nc-med-dark;
    }
    &:hover {
      cursor: pointer;
    }
    &.active {
      background-color: $b-nc-med;
      a {
        color: $b-nc-white;
      }
    }
  }
}
