.container-s {
  margin: 0 auto 5rem auto;
  max-width: 56.25rem;
}

.btns-container--double {
  padding-bottom: 0.5rem;
  overflow: auto;
  .btn--global {
    float: right;
    &:first-of-type {
      margin-left: 0.5rem;
    }
  }
}

.line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .size1of3,
  .size2of3 {
    width: 100%;
  }
  @media (min-width: $screen-sm) {
    .size1of3,
    .size2of3 {
      width: 50%;
    }
  }
  @media (min-width: $screen-m) {
    .size1of3 {
      width: 33.33333333%;
    }
    .size2of3 {
      width: 66.66666666%;
    }
  }
}

.container--320px {
  max-width: 20rem;
  margin: $spc-x-large $spc-small $spc-medium $spc-small;
  @media (min-width: $screen-sm) {
    margin: $spc-xxx-large $spc-small $spc-x-large $spc-small;
  }
}

.container--mrg-top-128px {
  margin-top: 8rem;
}

.center--wrapper {
  display: block;
  width: 95%; max-width: 60rem;
  margin: auto;
}