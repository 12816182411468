.link--inline {
  font-weight: $fw--bold;
  color: $b-mc-med;
  text-decoration: none;
  transition: $tr--s;
  position: relative;
  box-sizing: border-box;
  line-height: 1.5;
  &:after {
    content: "";
    transform-origin: left center;
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 0.125rem;
    bottom: -0.125rem;
    left: 0;
    background-color: transparent;
    transition: $tr--s;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: $b-mc-med;
    transition: $tr--s;
    opacity: 0.8;
    &:after {
      transform: scaleX(2);
      background-color: $b-mc-med;
      transition: $tr--s;
    }
  }
}

.indications--link--inline {
  color: $b-nc-med;
  margin-top: $spc-tiny;
}

// Légende avec couleur
.legend--color {
  @include txt--s($main-font);
  margin: 0;
  color: $b-nc-med;
  padding-left: $spc-x-small;
  position: relative;
  &:after {
    display: block;
    content: "";
    width: $spc-tiny;
    height: $spc-tiny;
    border-radius: $border-rds--circle;
    background-color: $b-nc-med;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.legend--color--mc-med:after {
  background-color: $b-mc-med;
}

// Text utilities class
.txt-center {
  text-align: center;
}

.txt-capitalize {
  text-transform: capitalize;
}

.txt-uppercase {
  text-transform: uppercase;
}

// Address tag
address {
  font-style: normal;
}

// Txt Separator in form
.txt--form-separator {
  color: $b-nc-med;
  overflow: hidden;
  text-align: center;
  span {
    position: relative;
    display: inline-block;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      border-bottom: 1px solid $b-nc-med;
      width: 591px; /* half of limiter*/
      margin: 0 12px;
    }
    &::before {
      right: 100%;
    }
    &::after {
      left: 100%;
    }
  }
}

// Need Help V3
.help-informations {
  @media only screen and (min-width: $screen-sm) {
    display: inline-flex;
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: inset 0px 0px 0px 1px $b-mc-med;
    border-radius: $border-rds--l;
  }

  &__title {
    margin: 0;

    @media only screen and (min-width: $screen-sm) {
      margin-right: 1rem;
    }
  }

  &__informations {
    position: relative;

    @media only screen and (min-width: $screen-sm) {
      margin: 5px 0 5px 1rem;
    }

    &::before {
      display: none;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1rem;
      width: 1px;
      background-color: $b-mc-med;

      @media only screen and (min-width: $screen-sm) {
        display: block;
      }
    }

    &__link {
      color: $b-mc-med;
      transition: $tr--s;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $b-mc-light;
      }
    }
  }

  &__hours {
    font-size: 0.65em;
  }
}

// Platform environment
.platform-environment {
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: inset 0px 0px 0px 1px $b-mc-med;
  border-radius: $border-rds--l;

  &--local {
    background-color: $b-ac-3;
    color: white;
    box-shadow: inset 0px 0px 0px 1px $b-ac-3;
  }

  &--dev {
    background-color: $b-uc-caution;
    color: white;
    box-shadow: inset 0px 0px 0px 1px $b-uc-caution;
  }

  &--staging {
    background-color: $b-uc-warning;
    color: white;
    box-shadow: inset 0px 0px 0px 1px $b-uc-warning;
  }
}

// Pastille V3
.pastille {
  display: flex;
  width: 2.1875rem;
  height: 2.1875rem;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: $small-text;
  color: $b-nc-white;
  background: $b-nc-med;
  border-radius: $border-rds--circle;

  &--caution {
    background: $b-uc-caution;
  }

  &--warning {
    background: $b-uc-warning;
  }

  &--positive {
    background: $b-uc-positive;
  }

  &--no-data {
    background: $b-nc-med;
  }
}

.pastille-state {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 2rem;
  height: 2rem;
  width: 2rem;
}

.pastille-count {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $b-mc-med;
  color: white;
  border-radius: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

.icon-pastille {
  display: inline-block;
  background-color: $b-mc-med;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;

  svg {
    display: block;
    width: 0.875rem;
    margin: auto;
    height: 100%;
  }
}

.text-chip {
  position: relative;
  padding-left: 1rem;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background: $b-nc-60;
    border-radius: 1rem;
    transform: translateY(-50%);
  }

  &--positive {
    &::before {
      background: $b-uc-positive;
    }
  }

  &--caution {
    &::before {
      background: $b-uc-caution;
    }
  }

  &--warning {
    &::before {
      background: $b-uc-warning;
    }
  }

  &--ac-3,
  &--neutral {
    &::before {
      background: $b-ac-3;
    }
  }

  &--dark {
    &::before {
      background: $b-nc-100;
    }
  }

  &--after {
    padding-left: 0;

    &::before {
      display: none;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 0.5rem;
      height: 0.5rem;
      background: $b-nc-60;
      border-radius: 1rem;
      transform: translateY(-50%);
      margin-left: 0.5rem;
    }

    &--positive {
      &::after {
        background: $b-uc-positive;
      }
    }

    &--caution {
      &::after {
        background: $b-uc-caution;
      }
    }

    &--warning {
      &::after {
        background: $b-uc-warning;
      }
    }

    &--neutral {
      &::after {
        background: $b-ac-3;
      }
    }
  }
}

$colors: (
  "uc-positive": $b-uc-positive,
  "uc-caution": $b-uc-caution,
  "mc-light": $b-mc-light,
  "mc-med": $b-mc-med,
  "ac-3": $b-ac-3,
  "ac-6": $b-ac-6,
  "ac-7": $b-ac-7,
  "ac-8": $b-ac-8
);

.link-dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: $secondary-font;
  font-weight: $fw--bold;
  border-radius: $border-rds--xl;
  background: $b-nc-00;
  box-shadow: $elevation-8-dp;
  overflow: hidden;
  transition: all 0.5s ease;

  &:hover,
  &:focus {
    transform: scale(1.025);
    box-shadow: $elevation-16-dp;
    transition: all 0.5s ease-in-out;
  }

  .icon-stroked {
    stroke: $b-nc-60;
  }

  .icon-filled {
    fill: $b-nc-60;
  }

  &__footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    background: $b-nc-00;
    color: $b-nc-60;

    svg {
      margin-left: 0.5rem;
    }
  }

  &__header {
    background: $b-nc-25;
    padding: 1.75rem 1.5rem 1.25rem 1.5rem;
    flex-grow: 1;

    &__title {
      font-size: 1.75em;
      margin: 0;
      text-align: center;
    }

    &__desc {
      font-family: $main-font;
      font-weight: $fw--reg;
      color: $b-nc-60;
      margin: 1.5rem 0 0 0;
      text-align: center;
    }

    svg {
      display: block;
      margin: 0 auto 1rem auto;
      width: 5rem;
      height: 5rem;
    }
  }

  &__notifications {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem 0.75rem 0 0;
    color: $b-nc-00;
    font-family: $secondary-font;
    font-weight: $fw--bold;
    z-index: 3;

    &::before {
      content: "";
      width: 100px;
      height: 100px;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 100%;
      background: $b-uc-warning;
      z-index: -1;
      transform: translate(50%, -50%);
    }
  }

  @each $name, $color in $colors {
    &--#{$name} {
      .link-dashboard__header {
        background: rgba($color, 0.1);
      }
      .link-dashboard__header__title,
      .link-dashboard__footer {
        color: $color;
      }
    }
  }

  @media only screen and (max-width: $screen-sm) {
    &__footer {
      justify-content: center;
    }
  }
}
