.search-result-item {
  display: flex;
  flex-flow: row nowrap;
  border-radius: $border-rds--l;
  border: 1px solid $b-nc-50;
  overflow: hidden;

  &--product {
    background-color: $b-nc-15;
    border: none;
    flex-flow: column nowrap;

    @media screen and (min-width: $screen-s) {
      flex-flow: row nowrap;
    }

    .search-result-item__content {
      padding: 0 0.5rem 0.5rem;
    }

    &__infos {
      display: flex;
      flex-flow: column wrap;

      @media screen and (max-width: $screen-s) {
        flex-flow: row wrap;
      }
    }
  }

  &--pump {
    background-color: $b-nc-15;
    border: none;
  }

  &--selected {
    background-color: rgba($b-mc-med, 0.1);
    border-color: $b-uc-positive;

    .search-result-item__button {
      background: $b-uc-positive;
    }
  }

  &__button {
    flex: 3.5rem 0 0;
    border: none;
    background: $b-mc-med;
    transition: $tr--m;
    cursor: pointer;

    &:hover,
    &:focus {
      background: $b-uc-positive;
      transition: $tr--m;
      cursor: pointer;
    }

    &:disabled {
      background-color: rgba($b-mc-med, 0.1);
      cursor: not-allowed;
    }
  }

  &__content {
    flex: auto 1 1;
    padding: 0.5rem;
  }
}

.search-result-item-list {
  height: 33vh;
  max-height: 600px;
  overflow: scroll;

  @media (max-width: $screen-s) {   
    padding: $spc-tiny;
    margin-top: $spc-tiny;
    height: auto;
  }
}

.product-quantity-item {
  display: flex;
  flex-flow: column nowrap;
  border-radius: $border-rds--l;
  background-color: rgba($b-mc-med, 0.1);
  overflow: hidden;

  @media screen and (min-width: $screen-s) {
    flex-flow: row nowrap;
  }

  &__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }

  &__button {
    width: 25%;
    min-height: 3.5rem;
    border: none;
    background: $b-mc-med;
    transition: $tr--m;
    cursor: pointer;

    @media screen and (min-width: $screen-s) {
      width: 3.5rem;
    }

    &:hover,
    &:focus {
      background: $b-uc-positive;
      transition: $tr--m;
      cursor: pointer;
    }

    &--delete {
      background: $b-uc-warning;

      &:hover,
      &:focus {
        background: $b-uc-warning--dark;
      }

      &--full {
        width: 100%;

        @media screen and (min-width: $screen-s) {
          width: 3.5rem;
        }
      }
    }

    &--disabled {
      &:hover,
      &:focus {
        background: $b-mc-med;
        cursor: not-allowed;
      }
    }
  }

  &__quantity {
    width: 25%;
    background-color: rgba($b-mc-med, 0.1);
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $screen-s) {
      width: 3.5rem;
    }

    &.input-container {
      width: 25%;
      background-color: inherit;
      > input {
        width: 100%;
        border: unset;
        background-color: inherit;
        text-align: center;
        font-weight: bold;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    } 
  }

  &__content {
    flex: auto 1 1;
    margin: 0 1rem;
  }
}

.product-transfert-stock {
  &-item {
    @extend .product-quantity-item;
    position: relative;
    flex-flow: column nowrap;
    border-radius: .1rem;
  
    &__buttons {
      display: flex;
      flex-flow: row nowrap;
    }
  
    &__button {
      @extend .product-quantity-item__button;
      width: 25%;
      &--delete {
        @extend .product-quantity-item__button--delete;
  
        &--single {
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  
    &__quantity {
      @extend .product-quantity-item__quantity;
      width: 25%;
    }
  }

  &__validate {
    width: 100%;
  }

  &__overflow {
    max-height: 20rem;
    overflow: auto;
  }

  &__modal .search-result-item--product__infos {
    flex-flow: row wrap;
  }
}

.product-list-items {
  @extend .product-quantity-item;
    
  position: relative;
  flex-flow: column nowrap;
  border-radius: 4px;

  &__buttons {
    display: flex;
    flex-flow: row nowrap;

    .product-list-items__button {
      display: flex;
      align-self: center;
      width: $spc-medium;
      height: $spc-medium;
      border: unset;
      border-radius: 100%;
      background-color: $b-nc-00;

      > svg {
        margin: auto;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__overflow {
    max-height: 20rem;
    overflow: auto;
  }

  &__disabled {
    background-color: rgba($b-nc-med, 0.1);
  }

  &--selected .search-result-item__button {
    background: $b-uc-positive;
  }
}

.product-heading {
  display: flex;
  flex-flow: column;
  align-items: center;

  .img--circle {
    margin-bottom: $spc-tiny;
  }

  @media screen and (min-width: $screen-s) {
    flex-flow: row;
    align-items: center;
    .img--circle {
      margin-bottom: 0;
    }
  }
}
