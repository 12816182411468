.message--cookie {
  background: $b-mc-med;
  box-shadow: $elevation-4-dp;
  width: 100%;
  color: $b-nc-white;
  top: 0;
  @include z-index("cookie-message");
  p {
    margin-top: 0;
  }
  .btn--primary {
    color: $b-mc-med;
    background-color: $b-nc-white;
    &:hover {
      color: $b-mc-light;
    }
  }
  .btn--tertiary {
    color: $b-nc-white;
    &:hover {
      color: $b-mc-med;
    }
  }
}

.message--cookie__overlay {
  background-color: $b-nc-med-dark;
  opacity: 0.5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include z-index("overlay");
}

.message--cookie__title {
  @include title--xs($secondary-font);
  font-weight: $fw--bold;
}

.message--cookie__text {
  @include txt--med($main-font);
}
