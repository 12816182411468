$icon-colors: (
  "mc-med": $b-mc-med,
  "uc-positive": $b-uc-positive,
  "uc-caution": $b-uc-caution,
  "uc-warning": $b-uc-warning
);

.tooltip-container {
  line-height: 1;

  &__target {
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
    color: $b-nc-60;
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;
  @include txt--med($main-font);
  max-width: 45rem;
  margin: 1rem;

  .tooltip-inner {
    background: $b-nc-60;
    color: $b-nc-00;
    border-radius: $border-rds--l;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: $b-nc-60;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

@each $name, $color in $icon-colors {
  .tooltip--#{$name} {
    .tooltip-inner,
    .popover-inner {
      background: $color;
    }
    .tooltip-arrow,
    .popover-arrow {
      border-color: $color;
    }
  }
}
