/*########### List type specific. ############# */
/* ########### Event specific. ############# */

.list__item__link {
  color: $b-nc-med-dark;
}

.list__item__dates {
  color: $b-ac-1;
}

.list__item__summary {
  height: 3.8rem;
  overflow: hidden;
}

/* ########### DM specific. ############# */

/* DMs color override */

.news__list {
  .list__item__content .content-type span {
    background-color: $b-ac-2;
  }
  .list-item:first-child .list__item__infos {
    background-color: transparentize($b-mc-light, 0.4);
  }
}

.list-item {
  position: relative;
  overflow: hidden;
  margin-bottom: 3.75rem;
  font-family: $secondary-font;
  box-shadow: $elevation-2-dp;
  img {
    display: block;
  }
}

.list__item__link {
  text-decoration: none;
}

.list__item__content {
  height: 100%;
  width: 100%;
  background: $b-nc-white;
}

.list__item__image {
  float: left;
}

.list__item__content .content-type {
  position: absolute;
}

.list__item__content .content-type span {
  background-color: rgba(170, 135, 80, 1);
}

.list__item__infos {
  overflow: hidden;
  padding: 1.25rem;
  height: 100%;
  width: 100%;
  .pdf__download {
    display: none;
    @media (min-width: $screen-m) {
      display: block;
    }
  }
}

.list__item__main h2 {
  margin-top: 0;
  color: $b-nc-med-dark;
  font-weight: $fw--bold;
  @include title--xs($secondary-font);
  transition: $tr--s;
}

.list__item__dates {
  span {
    font-weight: $fw--bold;
  }
}

.list__item__link {
  display: block;
  width: 100%;
  float: left;
}

.list__item__more-link {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.list__item__content .pdf__download {
  float: right;
  width: 10%;
  height: 6rem;
}

.list-item__new {
  width: 200px;
  background: $b-mc-light;
  position: absolute;
  top: 18px;
  right: -60px;
  left: auto;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: $b-nc-light-bg;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  font-weight: $fw--bold;
}

.list-item__bastideAdvise {
  width: 310px;
  background: $b-mc-med;
  position: absolute;
  top: 51px;
  right: -80px;
  left: auto;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: $b-nc-light-bg;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  font-weight: 800;
  font-size: 90%; // Don't add include txt on this, It needs to be a fix value for the moment
}

.list-item {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  &:first-child {
    .list__item__more-link {
      position: relative;
      bottom: 0;
      right: 0;
      float: right;
    }
    .list__item__infos {
      background-color: rgba(170, 135, 80, 0.6);
      overflow: hidden;
      display: table;
      bottom: 0;
      padding: 1rem;
      width: 100%;
    }
    .content-type {
      position: absolute;
      top: 1em;
      left: 1em;
    }
    .list__item__link {
      color: $b-nc-white;
      width: 100%;
      &:hover {
        opacity: 0.75;
        h2 {
          opacity: 0.75;
        }
      }
    }
    .list__item__main {
      h2 {
        margin-top: 0;
        color: $b-nc-white;
        font-weight: $fw--bold;
        @include title--med();
        transition: $tr--s;
      }
      p {
        margin-bottom: 0;
      }
    }
    .list__item__dates {
      width: 100%;
      display: block;
      border-right: none;
      margin: 0 0 1rem 0;
      vertical-align: middle;
      @include title--s();
      padding: 0;
      color: $b-nc-white;
      span {
        font-weight: $fw--bold;
      }
      br {
        display: none;
      }
    }
    .list__item__content .pdf__download {
      width: 5%;
      height: 8rem;
    }
  }
  &:first-child,
  &:nth-child(2),
  &:nth-child(3) {
    flex-direction: column;
    .list__item__content {
      height: auto;
    }
  }
  &:nth-child(2),
  &:nth-child(3) {
    .events__list .content-type {
      position: relative;
      top: -2.5em;
    }
    .list__item__dates {
      margin-bottom: 1em;
    }
    .events__list .list__item__infos {
      margin-top: -2.75em;
    }
    .list__item__infos {
      height: 100%;
    }
  }
  &__link {
    color: currentColor;
    text-decoration: none;
  }
  &__pdf-link,
  &__external-link,
  &__new {
  }
}

//Media Queries

@media (min-width: $screen-sm) {
  .list__item__content {
    height: 165px;
  }
  .list__item__infos {
    height: 12rem;
    width: auto;
  }
  .list-item {
    &:first-child {
      .list__item__infos {
        position: absolute;
        padding: 1.875rem;
      }
      .list__item__main {
        display: table-cell;
        padding: 0.15em 2em;
      }
      .list__item__dates {
        display: table-cell;
        margin: 2em 0;
        width: 25%;
        border-right: 1px solid $b-nc-white;
      }
      .list__item__more-link {
        position: absolute;
        bottom: 1rem;
        right: 2rem;
      }
    }
    &:nth-child(2),
    &:nth-child(3) {
      .list__item__infos {
        height: 12rem;
      }
    }
    .list__item__more-link {
      right: 2rem;
    }
  }
}

@media (min-width: $screen-m) {
  .list-item:first-child {
    .list__item__dates {
      br {
        display: block;
      }
    }
    .list__item__link {
      width: 95%;
    }
  }
  .list__item__link {
    width: 90%;
  }
}

.inters-list__patientName {
  font-size: 1rem;
  font-weight: $fw--bold;

  @media screen and (min-width: $screen-m) {
    font-size: 0.75rem;
    font-weight: $fw--reg;
  }
}
