.maintenance {
  height: 100vh; width: 100vw;
  
  @media only screen and (min-width: $screen-s) {
    display: flex;
    flex-flow: row wrap; justify-content: center; align-items: center;
  }

  &__wheels {
    display: block; margin: auto;
    width:auto; 
    max-height:33vh;

    @media only screen and (min-width: $screen-s) {
      margin: 0;
    }
  }

  &__content {
    max-width: 26rem; margin: 0 1rem;

    @media only screen and (min-width: $screen-s) {
      margin: 0; margin-left: 3rem;
    }
  }

  #wheel--l-up {
    animation : rotate--wheel--l-up 5s infinite linear; 
    transform-origin: 85px 85.5px;
  }
  @keyframes rotate--wheel--l-up {
    from {transform: translate(153px, 36px) rotate(0deg);}
    to {transform: translate(153px, 36px) rotate(-360deg);}
  }
  
  #wheel--r-up {
    animation : rotate--wheel--r-up 8s infinite linear; 
    transform-origin: 70.5px 71px;;
  }
  @keyframes rotate--wheel--r-up {
    from {transform: translate(341px, 20px) rotate(0deg);}
    to {transform: translate(341px, 20px) rotate(360deg);}
  }
  
  #wheel--l-bot {
    animation : rotate--wheel--l-bot 10s infinite linear; 
    transform-origin: 131.5px 132px;;
  }
  @keyframes rotate--wheel--l-bot {
    from {transform: translate(14px, 217px) rotate(0deg);}
    to {transform: translate(14px, 217px) rotate(360deg);}
  }

  #wheel--r-bot {
    animation : rotate--wheel--r-bot 8s infinite linear; 
    transform-origin: 99.5px 99.5px;
  }
  @keyframes rotate--wheel--r-bot {
    from {transform: translate(288px, 185px) rotate(0deg);}
    to {transform: translate(288px, 185px) rotate(-360deg);}
  }
}