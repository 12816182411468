.new-patient{
  margin-bottom: 5rem;
}

.form--controls-container {
  padding: 1rem;
  background-color: $b-nc-white;
}

.blockCheckbox {
  display: block;
  margin-bottom: 0.5rem;
}

.input--else {
    margin-bottom: 0 !important;
    margin-left: 1rem;
    width: calc(100% - 1rem) !important;
}