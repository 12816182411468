.fc {
  overflow-x: scroll;

  &-col-header-cell-cushion {
    text-transform: capitalize;
    width: 100%;

    > * {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-col-header-cell-cushion,
  &-col-header-cell-cushion:hover {
    color: $b-nc-60;
  }

  &-other-month {
    opacity: 1 !important;

    .fc-day-number {
      opacity: 1;
    }

    .fc-day__icon {
      opacity: 0.3;
    }

    .fc-event__content {
      opacity: 0.5;
    }
  }

  &-more-popover-misc {
    a,
    svg {
      display: none;
    }
  }

  &-popover {
    max-height: 350px;
    overflow-y: scroll;
  }

  &-daygrid-day-top {
    position: relative;
    height: 27px;
  }

  &-daygrid-week-number {
    display: none;
    font-size: 12px;
    font-weight: bold;
    font-family: $main-font;
    background: $b-nc-25;

    @media only screen and (min-width: $screen-s) {
      display: block;
    }
  }

  &-daygrid-dot-event:hover {
    background: initial;
  }

  &-timeGridWeek-view {
    min-width: 1000px;
  }

  &-day {
    position: relative;
    padding: 6px;

    &__icon {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 24px;
      height: 24px;
    }

    &-number {
      padding: 0;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 6px;
      right: 6px;
      font-size: 12px;
      font-weight: bold;
      font-family: $main-font;
      text-align: center;
      color: #ffffff;
      z-index: 3;

      &:hover {
        color: #ffffff;
      }
    }
  }

  &-title {
    word-break: break-word;
  }

  &-event {
    margin: 2px !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
    height: auto;
    box-shadow: none !important;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }

    > * {
      display: block;
      padding: 0.25rem 0.25rem 0.25rem calc(0.25rem + 4px) !important;
      height: fit-content !important;
      border-radius: 3px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }
    }

    &__title {
      font-weight: bold;
    }

    span {
      width: 100%;
      overflow: hidden;
    }

    &:hover {
      color: $b-nc-85 !important;
    }

    p {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $b-nc-85 !important;
    }

    &-mc-med {
      background-color: #cde6e4 !important;
      &::before {
        background-color: $b-mc-med !important;
      }
      > * {
        background-color: #cde6e4 !important;
        &::before {
          background-color: $b-mc-med !important;
        }
      }
    }

    &-ac-4 {
      background-color: #dcd6ff !important;
      &::before {
        background-color: $b-ac-4 !important;
      }
      > * {
        background-color: #dcd6ff !important;
        &::before {
          background-color: $b-ac-4 !important;
        }
      }
    }

    &-ac-5 {
      background-color: #fff3cd !important;
      &::before {
        background-color: $b-ac-5 !important;
      }
      > * {
        background-color: #fff3cd !important;
        &::before {
          background-color: $b-ac-5 !important;
        }
      }
    }

    &-ac-6 {
      background-color: #d5e9fd !important;
      &::before {
        background-color: $b-ac-6 !important;
      }
      > * {
        background-color: #d5e9fd !important;
        &::before {
          background-color: $b-ac-6 !important;
        }
      }
    }

    &-ac-7 {
      background-color: #fff3cd !important;
      &::before {
        background-color: $b-ac-7 !important;
      }
      > * {
        background-color: #fff3cd !important;
        &::before {
          background-color: $b-ac-7 !important;
        }
      }
    }

    &-ac-8 {
      background-color: #fff3cd !important;
      &::before {
        background-color: $b-ac-8 !important;
      }
      > * {
        background-color: #fff3cd !important;
        &::before {
          background-color: $b-ac-8 !important;
        }
      }
    }

    &-uc-positive {
      background-color: #cef0e8 !important;
      &::before {
        background-color: $b-uc-positive !important;
      }
      > * {
        background-color: #cef0e8 !important;
        &::before {
          background-color: $b-uc-positive !important;
        }
      }
    }

    &-uc-caution {
      background-color: #ffe1c9 !important;
      &::before {
        background-color: $b-uc-caution !important;
      }
      > * {
        background-color: #ffe1c9 !important;
        &::before {
          background-color: $b-uc-caution !important;
        }
      }
    }

    &-uc-warning {
      background-color: #fec9c6 !important;
      &::before {
        background-color: $b-uc-warning !important;
      }
      > * {
        background-color: #fec9c6 !important;
        &::before {
          background-color: $b-uc-warning !important;
        }
      }
    }

    &-nc-60 {
      background-color: #e5e5e5 !important;
      &::before {
        background-color: $b-nc-60 !important;
      }
      > * {
        background-color: #e5e5e5 !important;
        &::before {
          background-color: $b-nc-60 !important;
        }
      }
    }
  }

  &-timegrid-event-harness:not(:first-child) {
    .fc-event {
      margin-top: 0 !important;
      border-top: 2px solid #ffffff !important;
    }
  }

  &-day-today {
    background-color: rgba($b-mc-med, 0.1) !important;
  }

  .fc-dayGridMonth-view .fc-day-today {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 1.5rem solid $b-mc-med;
      border-left: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-right: 1.5rem solid transparent;
    }
  }
}
