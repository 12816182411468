.action-block {
    &__button-container {
        @media (max-width: $screen-m) {    
            margin-top: $spc-x-small;
            > button {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}