.alert-container {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: $border-rds--l;
}

.alert--success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
  display: block;
}

.alert--danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  display: block;
  clear: both;
}

.alert--noalert {
  display: none;
}
