.header__logo{
  .router-link-active{
    background-color: transparent;
  }
}

.post-count {
  font-weight: 600;
}


.legals-mentions,
.donnees-personnelles {
  @media screen and (min-width: $screen-sm) {
    margin-bottom: 8rem;
  }

  ul {
    list-style-type: initial;
    margin-left: $spc-small;
  }
}