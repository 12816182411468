// HEADER
//Global
header {
  font-family: $secondary-font;
  margin-bottom: $spc-small; //Responsive Enhancement //1.5rem - 24px
  @include clearfix();
  background-color: $b-nc-white;
  box-shadow: 0 $spc-x-small 102px -27px rgba(0, 0, 0, 0.75); // 1rem - 16px
  .content {
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
  @media (min-width: $screen-m) {
    .content {
      min-height: 90px;
    }
  }
}

//Header global Responsive
.header--responsive {
  padding: 0 1rem;
  svg {
    height: $spc-medium; //2rem - 32px
    width: auto;
    &.logo-humanair--svg {
      height: 3rem;
    }
    @media (min-width: $screen-m) {
      height: 3.125rem;
      &.logo-humanair--svg {
        height: 4rem;
      }
    }
  }
}

@media (min-width: $screen-m) {
  .header--responsive {
    .logo-bastide--svg {
      height: 3.125rem;
      &.logo-humanair--svg {
        height: 4rem;
      }
    }
  }
}

/*######  LOGIN-BLOCK  ######*/

.not-home {
  .content {
    flex-direction: column;
    @media (min-width: $screen-sm) {
      flex-direction: row;
    }
  }
  .main-navigation {
    min-width: 18rem;
    flex-grow: 3;
  }
  .tile__icon {
    min-height: $spc-x-small; //1rem - 16px
    height: $spc-x-small; //1rem - 16px
    svg {
      fill: $b-nc-med-dark;
      height: $spc-x-small; //1rem - 16px
      width: 100%;
    }
  }
  .tile__user-doctor-name {
    @include txt--s($secondary-font);
    margin-top: calc(0rem - (calc($spc-tiny / 2))); //.25rem - 4px
    display: block;
  }
  .tile-message__wrapper {
    padding-bottom: 0;
  }
  .notification-spot {
    background: $b-uc-warning;
    font-size: 60%; // Don't add include txt on this, It needs to be a fix value for the moment
    width: 1.25em;
    height: 1.25em;
    line-height: 2.2;
    border-radius: $border-rds--circle;
    position: absolute;
    right: 29%;
    top: calc(0rem - (calc($spc-tiny / 2))); //.25rem - 4px
    color: transparent;
  }
  .header__login__infos {
    margin-bottom: 1.125rem;
  }
  @media (min-width: $screen-xs) {
  }
  @media (min-width: $screen-sm) {
    .header__login__infos {
      margin-bottom: 0;
    }
    .notification-spot {
      right: 38%;
    }
  }
  @media (min-width: $screen-m) {
    .tile__icon {
      min-height: $spc-small; //1.5rem - 24px
      height: $spc-small; //1.5rem - 24px
      svg {
        height: $spc-small; //1.5rem - 24px
      }
    }
    .notification-spot {
      width: 2.2em;
      height: 2.2em;
      top: calc(0rem - (calc($spc-tiny / 2))); //.25rem - 4px
      color: $b-nc-white;
    }
  }
}

.header__login__infos {
  position: relative;
  padding-left: 1em;
  display: table;
  cursor: pointer;
  div {
    display: table-cell;
    vertical-align: middle;
  }
  p {
    margin: 0;
  }
  .open-close-button {
    display: table-cell;
    vertical-align: middle;
    padding: 0 0.25em 0 1em;
    cursor: pointer;
    img {
      width: $spc-x-small; //1rem - 16px
      height: auto;
    }
  }
  .ic--user {
    fill: $b-mc-med;
  }
}

.profile__image {
  border-radius: $border-rds--circle;
  margin-right: 1em;
  display: table-cell;
  vertical-align: middle;
  width: $spc-medium; //2rem - 32px
  @media (min-width: $screen-m) {
    width: $spc-x-large; //3rem - 48px
  }
}

.header__login__name {
  font-weight: bold;
  color: $b-nc-dark;
}

.header__login__menu {
  position: absolute;
  @include z-index("header");
  top: 100%;
}

.header__logout-button {
  @include dropdown-overlay-inactive();
  width: 100%;
  background: white;
  top: 3.4rem;
  left: 0;
  box-shadow: 0 calc($spc-large / 2) calc($spc-large / 2) 0 rgba(0, 0, 0, 0.15); //1.25rem - 20px
  border-bottom: 2px solid $b-mc-light;
  @include mf_breakpoint(min-m) {
    top: 4.2rem;
  }
}

.header__login__infos--active .header__logout-button {
  @include dropdown-overlay-active();
}

.header__login__contact-infos {
  display: inline-block;
  padding: 1rem;
  &__label {
    color: $b-nc-med;
  }
  &__value {
    color: $b-nc-dark;
    word-break: break-all;
  }
}

.header__logout-link {
  width: 100%;
  display: inline-block;
  padding: 1rem;
  border-top: 1px solid $b-nc-light;
  text-transform: uppercase;
}

.main-navigation__list {
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
  list-style-type: none;
}

.main-navigation__list-item {
  //flex-grow: 1;
  flex: 1;
}

.header__logo {
  float: left;
  margin-left: 0.6em;
  width: 5.5rem;
  p {
    margin: 0;
  }
  @include breakpoint(max-sm) {
    img {
      margin-top: $spc-tiny; //.5rem - 8px
    }
  }
}

.header__logo--big {
  width: 7.5rem;
}

.main-navigation__list-item a {
  height: 70px;
  padding: 0.75rem 0;
  display: block;
  background-color: transparent;
  font-weight: $fw--bold;
  text-align: center;
  text-decoration: none;
  .tile__title {
    color: $b-nc-dark;
    text-transform: uppercase;
    @include txt--s($secondary-font);
    margin: 0.5em 0 0 0;
  }
  @media (min-width: $screen-xs) {
    padding: 0.75rem 0.75rem 0.25rem 0.75rem;
  }
  @media (min-width: $screen-m) {
    height: 90px;
    border-bottom: 0.25em solid transparent;
    .tile__title {
      @include txt--med($secondary-font);
    }
    &:hover {
      border-bottom: 0.25em solid $b-mc-light;
      box-shadow: 0 41px 142px 19px rgba(218, 218, 218, 0.99);
    }
  }
}

.main-navigation__list-item {
  &:last-child a {
    margin-right: 0;
  }
  &:first-child a {
    margin-left: 0;
  }
}

.notifications-count {
  width: $spc-xx-large; //3.5rem - 56px
  margin-top: -$spc-tiny; //.5rem - 8px
  padding: $spc-tiny; //.5rem - 8px
  position: relative;
  top: 0;
  left: 4rem;
  display: block;
  float: left;
  border-radius: $border-rds--circle;
  background: rgba(252, 30, 72, 0.4);
}
