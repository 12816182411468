.plaquette__hero__video {
  background: $b-nc-black;
  iframe {
    margin: 0 auto;
    max-width: 100%;
    display: block;
    height: 60vw;
    /*    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; */
    @media (min-width: $screen-m) {
      max-width: 960px;
      height: 540px;
    }
  }
}

.plaquette__videos__list,
.plaquette__images__list,
.plaquette__documents__list {
  margin-bottom: 1rem;
}

.plaquette__videos__list__item,
.plaquette__documents__list__item {
  margin-bottom: 1rem;
  overflow: hidden;
  cursor: pointer;

  img {
    margin: 0 auto;
    display: block;
  }
}

.plaquette__videos__list__item img {
  transition: $tr--s;
  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
}

.thumb-container {
  overflow: hidden;
}

.plaquette__videos__list__item {
  h2 {
    text-align: center;
    margin: 0;
    @include txt--med($secondary-font);
    height: 50px;
    padding-top: calc($spc-tiny / 2);
    font-weight: $fw--reg;
  }
  a {
    text-decoration: none;
  }
}

.plaquette__images__list__item {
  cursor: pointer;
}

/* Masonry */
.grid-item,
.grid-sizer {
  width: 20%;
  img {
    width: 100%;
  }
}

.plaquette__documents__list__item {
  padding: 1rem;
  border: 1px solid $b-nc-light;
  background: url(../assets/common/icon_download.svg) no-repeat 95% 5%;
  min-height: 205px;
  .pdf-icon {
    float: left;
    margin-right: 1em;
  }
  h2 {
    text-align: left;
    @include txt--med($secondary-font);
    width: 90%;
    margin: 0;
    padding-top: calc($spc-tiny / 2);
    font-weight: $fw--reg;
  }
  a {
    color: $b-nc-med-dark;
    text-decoration: none;
  }
  .pdf-size {
    @include txt--s($main-font);
    color: $b-nc-med;
  }
}

.bastide-document-telechargement {
  display: block;
  clear: left;
  margin: 42px auto !important;
}
