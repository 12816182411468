// label
.label--global {
  display: block;
  font-weight: $fw--bold;
  @include txt--s($main-font);
  color: $b-nc-med-dark;
  .input--global,
  .select--global {
    margin-top: calc($spc-tiny / 2);
  }
  .input__message--error,
  .input--textarea__counter {
    font-weight: $fw--reg;
  }
}

label.label--global {
  margin-bottom: $spc-x-small;
}

.container--input {
  position: relative;
  .input--global {
    height: 54px;
    padding-right: $spc-large;
    &:focus ~ .ic--search {
      display: none;
    }
  }
  .input--s.input--global {
    padding-right: $spc-medium;
    height: 30px;
  }
  .input--global:disabled ~ svg {
    opacity: 0.5;
  }
  .container--input__link {
    width: $spc-small;
    height: $spc-small;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 34px;
    svg {
      transition: $tr--s;
    }
    &:hover svg {
      fill: $b-mc-light;
      transform: scale(1.2) rotate(90deg);
    }
  }
  .ic--search,
  .ic--close {
    width: $spc-x-small;
    height: $spc-x-small;
    fill: $b-nc-med-dark;
  }
  .ic--search {
    position: absolute;
    right: $spc-x-small;
    top: 37px;
  }
  .input--s ~ .ic--search {
    top: 25px;
    right: 8px;
  }
  .input--s ~ .container--input__link {
    top: 22px;
    right: 4px;
  }
}

.input__indications--left {
  font-weight: $fw--reg;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: 4px;
  svg {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    margin-right: 2px;
  }
}

//

// radio-btns-vertical-textarea
.radio-btns-textarea--container-textarea,
.checkbox-textarea--container-textarea {
  padding-left: $spc-medium;
}

// Input range indications
.input--range__indications {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include txt--s($main-font);
  width: 100%;
  height: 1rem;
  margin-top: $spc-x-small;
  .input--range__indications__text {
    margin: 0 auto;
    text-align: center;
    &:first-child {
      margin-left: $spc-tiny;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

// Double Input range indications
.input--range--double__input-range {
  position: relative;
  height: $spc-small;
  input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: calc($spc-tiny / 2);
    border-top-left-radius: $border-rds--l;
    border-bottom-left-radius: $border-rds--l;
    background: rgb(229, 73, 89);
    background: -webkit-linear-gradient(left, rgba(229, 73, 89, 1) 0%, rgba(255, 135, 0, 1) 50%, rgba(33, 155, 149, 1) 100%);
    background: -o-linear-gradient(left, rgba(229, 73, 89, 1) 0%, rgba(255, 135, 0, 1) 50%, rgba(33, 155, 149, 1) 100%);
    background: linear-gradient(to right, rgba(229, 73, 89, 1) 0%, rgba(255, 135, 0, 1) 50%, rgba(33, 155, 149, 1) 100%);
    &:after {
      display: block;
      content: "";
      position: absolute;
      right: calc(0rem - calc($spc-small / 2));
      top: 50%;
      transform: translateY(-50%);
      border: 6px solid transparent;
      border-left: 6px solid $b-mc-med;
    }
  }
  .input--range--double__input-range--track-hidden {
    background: transparent;
    left: calc($spc-tiny / 2);
    width: 99%;
    &:after {
      display: none;
    }
  }
}

.input--range--double__input-range--track-hidden::-webkit-slider-thumb {
  width: $spc-x-small;
  height: $spc-x-small;
  margin-top: -$spc-tiny;
  background-color: $b-nc-med;
}
.input--range--double__input-range--track-hidden::-moz-range-thumb {
  width: $spc-x-small;
  height: $spc-x-small;
  margin-top: -$spc-tiny;
  background-color: $b-nc-med;
}
.input--range--double__input-range--track-hidden::-ms-thumb {
  width: $spc-x-small;
  height: $spc-x-small;
  margin-top: -$spc-tiny;
  background-color: $b-nc-med;
}

.input--range--double__indications {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @include txt--s($main-font);
  width: 100%;
  height: 1rem;
  margin-top: $spc-tiny;
  .input--range--double__indications__text {
    margin: 0 auto;
    text-align: center;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

// Legend
.legend-checkbox,
.legend-radioBtn,
.flatpickr__date--range {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  .label--global {
    margin-bottom: calc($spc-tiny / 2);
  }
  .label--checkbox,
  .label--radio-btn {
    margin-bottom: calc($spc-tiny / 4);
  }
}

// Radio Switch Icon
.container--radio--switch {
  display: flex;
  flex-direction: row;
  position: relative;
  .radio--switch__label:first-of-type {
    margin-right: 8px;
  }
}

.radio--switch__label {
  padding: 8px;
  background-color: $b-nc-light-bg;
  transition: all 0.15s ease-in;
  display: inline-flex;
  align-items: center;
  flex-basis: auto;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    fill: $b-nc-dark;
  }
  &:hover {
    width: 110px;
    background-color: $b-nc-light;
    padding: 8px 12px;
    .radio--switch__title {
      transform: scale(1);
      opacity: 1;
      transition: opacity 0.5s ease-in, scale 0.05s ease-in;
    }
    svg {
      margin-right: 8px;
    }
  }
}

.radio--switch__title {
  @include txt--s($main-font);
  color: $b-nc-med-dark;
  transform: scale(0);
  opacity: 0;
}

.radio--switch__input {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked {
    & + .radio--switch__label {
      background-color: $b-mc-med;
      svg {
        stroke: $b-nc-white;
      }
      .radio--switch__title {
        color: $b-nc-white;
      }
      &:hover {
        background-color: $b-mc-light;
      }
    }
  }
}

///// List State Treatment /////
.list--state-treatment {
  .input--radio-btn:checked + .list--state-treatment__item,
  .list--state-treatment__item:hover {
    color: $b-nc-white;
    &.color--uc--positive {
      background-color: $b-uc-positive;
      border-color: $b-uc-positive;
      .ic--smiley--happy__bg {
        fill: $b-nc-white;
      }
      .ic--smiley--happy__mouth,
      .ic--smiley--happy__eye--left,
      .ic--smiley--happy__eye--right {
        stroke: $b-uc-positive;
      }
      .ic--smiley--happy__eye--left,
      .ic--smiley--happy__eye--right {
        fill: $b-uc-positive;
      }
    }
    &.color--uc--caution {
      background-color: $b-uc-caution;
      border-color: $b-uc-caution;
      .ic--smiley--neutral__bg {
        fill: $b-nc-white;
      }
      .ic--smiley--neutral__mouth,
      .ic--smiley--neutral__eye--left,
      .ic--smiley--neutral__eye--right {
        stroke: $b-uc-caution;
      }
      .ic--smiley--neutral__eye--left,
      .ic--smiley--neutral__eye--right {
        fill: $b-uc-caution;
      }
    }
    &.color--uc--warning {
      background-color: $b-uc-warning;
      border-color: $b-uc-warning;
      .ic--smiley--sad__bg {
        fill: $b-nc-white;
      }
      .ic--smiley--sad__mouth,
      .ic--smiley--sad__eye--left,
      .ic--smiley--sad__eye--right {
        stroke: $b-uc-warning;
      }
      .ic--smiley--sad__eye--left,
      .ic--smiley--sad__eye--right {
        fill: $b-uc-warning;
      }
    }
  }
}

.list--state-treatment__item {
  margin-bottom: calc($spc-tiny / 2);
  border: 1px solid $b-nc-light;
  display: block;
  position: relative;
  border-radius: $border-rds--l;
  padding: calc($spc-small / 2) calc($spc-small / 2) calc($spc-small / 2) $spc-large;
  text-align: left;
  text-decoration: none;
  transition: $tr--s;
  cursor: pointer;
  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: $spc-tiny;
    transform: translateY(-50%);
    width: $spc-small;
    height: $spc-small;
    min-width: $spc-small;
    min-height: $spc-small;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

@media (min-width: $screen-sm) {
  .list--state-treatment__item {
    margin-bottom: 0;
    width: 32.5%;
    text-align: center;
    display: inline-block;
    padding: 120px $spc-tiny $spc-tiny $spc-tiny;
    height: 175px;
    svg {
      width: 80px;
      height: 80px;
      top: $spc-x-small;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
