.intervention {
  border: 1px solid $b-nc-light;
  border-radius: $border-rds--l;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;

  & > div:first-child {
    width: 100%;
  }

  @media only screen and (max-width: $screen-sm) {
    flex-wrap: wrap;
    .intervention__settings {
      width: 100%;
    }
  }

  &__top {
    @media only screen and (min-width: $screen-sm) {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  &__top-left {
    flex: 1 1 auto;
    padding: 0.75rem 1rem 0rem 1rem;

    @media only screen and (min-width: $screen-sm) {
      flex: 0 0 40%;
    }
  }

  &__top-right {
    border-top: 1px solid $b-nc-light;

    @media only screen and (min-width: $screen-sm) {
      display: flex;
      flex: 0 0 60%;
      flex-flow: row nowrap;
      border-left: 1px solid $b-nc-light;
      border-top: none;
    }
  }

  &__bottom {
    border-top: 1px solid $b-nc-light;
    padding: 0.5rem;
  }

  &__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-bottom: 1rem;

    @media only screen and (min-width: $screen-s) {
      align-items: center;
    }

    h3 {
      font-weight: 700;
      color: #000;
      font-family: Heebo;
      font-size: 1rem;
    }

    svg {
      flex: 0 0 2rem;
      margin-right: 0.5rem;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    min-width: 100px;
    column-gap: 20px;
    .label-value {
      margin-bottom: 1rem;
    }
  }

  &__comment {
    flex: 1 1 50%;
    margin-top: 1rem;

    @media only screen and (min-width: $screen-s) {
      margin-left: 1rem;
      margin-top: 0;
    }
  }

  &__metrics {
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 auto;

    @media only screen and (min-width: $screen-l) {
      flex-wrap: nowrap;
      justify-content: flex-end;
      border: none;
    }

    div {
      flex: 1 1 50%;

      &:nth-child(2n) {
        border-left: 1px solid $b-nc-light;

        @media only screen and (min-width: $screen-l) {
          border-left: none;
        }
      }

      &:not(:nth-child(1)):not(:nth-child(2)) {
        border-top: 1px solid $b-nc-light;

        @media only screen and (min-width: $screen-l) {
          border-top: none;
        }
      }

      @media only screen and (min-width: $screen-l) {
        flex-basis: 5rem;
        border-right: 1px solid $b-nc-light;
      }
    }
  }

  &__settings {
    background-color: $b-nc-25;
    padding: 0.8rem;
    font-size: 16px;

    @media only screen and (min-width: $screen-s) {
      flex: 0 0 auto;
      padding: 0.25rem 0.5rem;
    }

    &__title {
      color: $b-nc-med;
      margin-top: 0;
      margin-bottom: 1rem;
    }

    &__item {
      margin: 0;
      word-break: break-all;
    }

    &__label {
      display: inline-block;
      width: 9.5rem;
    }

    &__value {
      font-family: $secondary-font;
    }
  }

  /* Border color depending on status */

  &--caution {
    border-color: $b-uc-caution;

    .intervention__top-right,
    .intervention__bottom {
      border-color: $b-uc-caution;
    }

    .intervention__title {
      h3 {
        color: $b-uc-caution;
      }

      svg {
        stroke: $b-uc-caution;
      }
    }
  }

  &--warning {
    border-color: $b-uc-warning;

    .intervention__top-right,
    .intervention__bottom {
      border-color: $b-uc-warning;
    }

    .intervention__title {
      h3 {
        color: $b-uc-warning;
      }

      svg {
        stroke: $b-uc-warning;
      }
    }
  }

  &--positive {
    border-color: $b-uc-positive;

    .intervention__top-right,
    .intervention__bottom {
      border-color: $b-uc-positive;
    }

    .intervention__title {
      h3 {
        color: $b-uc-positive;
      }

      svg {
        stroke: $b-uc-positive;
      }
    }
  }
}
