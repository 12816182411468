.pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  &__pages {
    display: flex;
    margin: 0 $spc-x-small;

    &__page {
      display: none; margin: 0 $spc-tiny;
      align-items: center;
      background: none;

      @media only screen and (min-width:$screen-m) {
        display: flex;
      }

      &--active {
        display: flex;
      }
    }
  }

  &__ellipsis {
    letter-spacing: 0.25rem;
  }

  .button--discrete {
    svg {
      height: 20px;
      width: 20px;
    }

    span {
      display: none;

      @media only screen and (min-width: $screen-xs){
        display: inline;
      }
    }
  }
}