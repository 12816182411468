.ordonnance {
  border-radius: $border-rds--l;
  overflow: hidden;
  margin-bottom: .25rem;
}

.ordonnance__ref {
  padding: 0.5rem;
  border-top-left-radius: $border-rds--l;
  border-bottom-left-radius: $border-rds--l;
  text-align: center;
  p {
    font-size: 1rem;
    font-size: 0.75rem;
    color: #fff;
    margin: 0;
    span {
      display: block;
      font-weight: 700;
      font-size: 1rem;
    }
  }
}

.ordonnance__content {
  padding: 0.5rem;
  width: 100%;

  .flex-item:not(:last-child) {
    margin-bottom: $spc-tiny;

    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 0;
    }
  }
}