.form-section-prescription-addresses {
    border-radius: 4px;
    border: 1px solid $b-nc-85;

    &--search {
        margin: 0;
        border-radius: 4px;
        background: $b-nc-light-bg;
        padding: $spc-x-small $spc-tiny;
    }

    &--form {
        margin: 0;
        padding: $spc-x-small;

        .label-value__label {
            font-weight: bold;
        }

        .form-base__buttons {
            padding-bottom: 0;
            padding-right: 0;
            padding-left: 0;
            padding-top: $spc-x-small;

            .btn--secondary {
                margin-right: auto;
                margin-left: 0;
            }
        }
    }

    &--indications {
        display: flex;
        flex: 0 0 100%;
        padding: $spc-tiny;
    }

    &--button-container {
        display: flex;
        justify-content: flex-end;
        
        margin-bottom: $spc-tiny;
        padding-right: $spc-tiny;
        padding-top: $spc-tiny;
        border-top: 1px solid $b-nc-25;
    }

    &--list {
        margin: $spc-x-small;
        margin-top: $spc-tiny;

        .form-section-prescription-addresses--form {
            border: 1px solid $b-nc-25;
            margin-bottom: $spc-x-small;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    &--list-header {
        margin-top: $spc-x-small;
        margin-left: $spc-x-small;
        margin-bottom: $spc-tiny;
        color: $b-nc-60;
    }
}

.view-prescription-addresses {
    border-radius: 4px;
    background-color: rgba($b-mc-med, 0.1);
    padding-left: $spc-x-small;
    margin-bottom: $spc-x-small;
}