// Title Back Bastide
.back-h1 {
  font-weight: $fw--bold;
  color: $b-mc-med;
  text-transform: uppercase;
  margin-top: 3.5rem;
  margin-bottom: 4.5rem;
  @include title--xs($secondary-font);
}

// Title card
.card__heading {
  font-weight: $fw--bold;
  @include title--xs($secondary-font);
  color: $b-mc-med;
  margin-top: 0;
  margin-bottom: $spc-tiny;
}

.card__heading__inline-explications {
  @include txt--med($main-font);
  color: $b-nc-med;
  font-weight: $fw--reg;
}

.card__sub-heading {
  font-family: $secondary-font;
  font-weight: $fw--bold;
  color: $b-nc-med-dark;
  margin-top: 0;
  margin-bottom: $spc-x-small;
}

// Title Numbered
.title-numbered {
  @include txt--big($main-font);
  color: $b-nc-dark;
  margin-top: 0;
  margin-bottom: $spc-small;
  position: relative;
}

.title-numbered--sub {
  @include txt--med($main-font);
  margin-bottom: 0.75rem;
}

.title-numbered--number {
  color: $b-mc-med;
  text-align: right;
  position: absolute;
  top: 0;
  left: -$spc-small;
}

.title-numbered--number--sub {
  font-weight: $fw--bold;
}

///// Heading Info /////
.heading--infos {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $secondary-font;
    margin-top: 0;
    margin-bottom: 0;

    @media only screen and (min-width: $screen-s) {
      margin-right: 0.5rem;
    }
  }

  p,
  span {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    @include txt--s($main-font);
    color: $b-nc-50;
  }

  &.heading__list {
    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-top: -0.2rem;
    }
  }

  .heading__list__count {
    position: absolute;
    right: 0;
    top: 50%;
    color: $b-mc-med;
    font-size: 0.75rem;
    transform: translateY(-50%);
  }

  .chips {
    margin: 0.5rem 0 0 0;

    @media only screen and (min-width: $screen-s) {
      margin: 0 0 0 auto;
    }
  }

  svg {
    transform: translateY(2px);
  }

  &--w-border {
    border-bottom: 1px solid $b-nc-light;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &--intervention {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5rem;

    @media only screen and (min-width: $screen-s) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
    }
  }
}
