.p--signup-login {
  height: auto;
  @media (min-width: $screen-sm) {
    min-height: calc(100vh - 200px);
  }
  @media (min-width: $screen-m) {
    min-height: calc(100vh - 160px);
  }
  .size2of3 {
    position: relative;
    display: none;
    @media (min-width: $screen-sm) {
      display: block;
    }
  }
}

.p--signup-login__title {
  @include title--med();
  margin-bottom: 0.25rem;
  margin-top: 0;
}

.p--signup-login__subtitle {
  @include txt--big($secondary-font);
  margin-top: 0;
  margin-bottom: $spc-medium;
}

.p--signup-login__signup-step-txt {
  @include txt--big($main-font);
  margin-top: 0;
  margin-bottom: $spc-tiny;
  font-weight: $fw--bold;
}

.p--signup-login__signup-name-verif {
  @include title--xs($main-font);
  font-weight: $fw--bold;
  color: $b-nc-dark;
}

.p--signup-login__form__birth-date {
  .label-select--day {
    width: 20%;
  }
  .label-select--month {
    width: 45%;
  }
  .label-select--year {
    width: 30%;
  }
  .label-select--day,
  .label-select--month,
  .label-select--year {
  }
}

.p--signup-login__change-form {
  @include title--xs($secondary-font);
  margin-top: 0;
  margin-bottom: $spc-small;
}

.p--signup-login__img {
  width: auto;
  height: 100%;
  max-width: none;
  max-height: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  @media (min-width: 1750px) {
    width: 105%;
    height: auto;
  }
}
