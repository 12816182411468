// 00-container--link--promotional
.container--link--promotional {
  float: right;
  .link--promotional:first-of-type {
    padding-right: $spc-x-small;
    border-right: 1px solid $b-nc-med;
    margin-right: $spc-tiny;
  }
  @media (max-width: $screen-m) {
    float: left;
    .link--promotional {
      display: block;
      margin-bottom: $spc-x-small;
      &:first-of-type {
        padding-right: 0;
        border-right: none;
        margin-right: 0;
      }
    }
  }
  @media (max-width: $screen-xs) {
    .link--promotional {
      line-height: 1.2;
      margin-bottom: $spc-small;
    }
    .link--promotional__subtext {
      margin-top: calc($spc-tiny / 2);
      line-height: 1.4;
    }
  }
}

// 01_date-label--multiple-value
.date-label--multiple-value__date {
  font-weight: $fw--bold;
  color: $b-mc-med;
  margin: 0 0 calc($spc-small / 2) 0;
  border-bottom: 1px solid $b-mc-med;
}

.date-label--multiple-value {
  .label-value--list,
  .label-value {
    margin-bottom: calc($spc-small / 2);
  }
  .label-value__label {
    color: $b-nc-med-dark;
    margin-bottom: 0;
  }
  .label-value__value {
    color: $b-nc-dark;
    font-weight: $fw--bold;
    @include txt--s($main-font);
    margin-bottom: 0;
  }
}

.label-value {
  ul {
    margin: 0;
  }
}
