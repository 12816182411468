.toast {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  background: $b-nc-light-bg;
  padding: $spc-tiny $spc-small $spc-tiny $spc-medium ;
  border-radius: $border-rds--l;
  box-shadow: $elevation-2-dp;
  @include z-index("cookie-message");

  .icon-close-container {
    cursor: pointer;
    padding: $spc-tiny;
    position: relative;
    @include z-index("upper");
  }
}