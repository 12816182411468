.container__map--responsive {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: $spc-large;
}

.container__map--responsive.home__maps {
  padding-bottom: 225px;
}

.container__map--responsive iframe,
.container__map--responsive object,
.container__map--responsive embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Button circle */

.img--circle {
  display: flex;
  width: 4.5rem;
  height: 4.5rem;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: $border-rds--circle;
  background-color: $b-nc-light;
  overflow: hidden;
  &--w-text {
    text-transform: capitalize;
    text-align: center;
    line-height: 0.8;
    font-size: 2rem;
    font-weight: $fw--bold;
    font-family: $secondary-font;
    color: $b-nc-med;
  }
  &--medium {
    width: 3.125rem;
    height: 3.125rem;
    min-width: 3.125rem;
    min-height: 3.125rem;
    font-size: 0.75rem;
  }
  &--small {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    font-size: 0.75rem;

    @media only screen and (min-width: $screen-sm) {
      width: 2.5rem;
      height: 2.5rem;
      min-width: 2.5rem;
      min-height: 2.5rem;
      font-size: 1rem;
    }
  }
  &--actionable {
    background-color: $b-mc-med;
    color: $b-nc-00;
  }
  p {
    margin: 0;
  }

  &__figure {
    height: 100%;
    width: 100%;
    border-radius: $border-rds--circle;
    margin: 0;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.img--circle__img {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: cover;
  &--medium {
    width: 3.125rem;
    height: 3.125rem;
  }
  &--small {
    width: 2.5rem;
    height: 2.5rem;
  }
}

/* DMs */

.dm__item {
  display: flex;
  flex-flow: row nowrap;

  .img--circle {
    flex: 0 0 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    border: 1px solid $b-nc-light;

    @media only screen and (min-width: $screen-s) {
      flex: 0 0 3.5rem;
      width: 3.5rem;
      height: 3.5rem;
    }
  }
  &__infos {
    flex: 1 1 auto;

    h5, p {
      margin: 0;
    }

    h5 {
      color: $b-nc-med-dark;
      word-break: break-all;
      line-height: 1.4em;
      font-size: $fifth-heading;
    }

    p {
      @include txt--s($main-font);
    }
  }
}
