.patient-single__block-wrapper {
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  border: 1px solid $b-nc-light;
  background-color: $b-nc-white;
}

.patient-data-label {
  display: block;
  float: left;
  width: 40%;
  color: $b-nc-med;
  line-height: 1.5625rem;
}

.patient-data-value {
  display: block;
  float: right;
  width: 60%;
  color: $b-nc-dark;
  line-height: 1.5625rem;
}

.patient-single__table {
  width: 100%;
  text-align: center;
  .patient-single__table__header {
    background-color: $b-nc-light-bg;
    line-height: 2.5625rem;
    th {
      border: 1px solid $b-nc-light;
      font-weight: $fw--reg;
      color: $b-nc-dark;
    }
  }
  td {
    border: 1px solid $b-nc-light;
  }
}

.patient-single__table__row--telesuivi {
  line-height: 3;
}

.dropdown__icon__wrapper {
  float: right;
  margin-right: 1.5rem;
}

.patient-single__interventions-label {
  color: $b-nc-med;
}

.patient-single__interventions-value {
  color: $b-nc-dark;
  &:not(:last-of-type) {
    &:after {
      content: " - ";
      display: inline-block;
    }
  }
}

.patient-single__table__date {
  width: 8rem;
  vertical-align: top;
  text-align: left;
  p {
    margin-left: 1rem;
  }
}

.patient-single__table__details {
  width: 36rem;
  text-align: left;
  p {
    margin-left: 1rem;
  }
}

.patient-single__interventions-data {
  width: 5rem;
}

.patient-single__interventions-settings-table {
  width: 100%;
  td {
    border: none;
    text-align: left;
  }
  .patient-single__interventions-label {
    padding-left: 1rem;
  }
}

.patient-single__table__comment {
  vertical-align: top;
}

.patient-single__more-interventions {
  line-height: 4;
  float: none;
}

.interventions-table__more {
  display: none;
  opacity: 0;
  &--active {
    display: table-row-group;
    opacity: 1;
  }
}

.interventions__pdfs-container {
  max-width: 40%;
  margin: 0 1rem 1rem 1rem;
  padding: $spc-tiny;
  float: left;
  border: 1px solid $b-nc-light;
}

.interventions__pdfs__open-link,
.interventions__pdfs__download-link,
.interventions__pdfs__details {
  float: left;
}

.interventions__pdfs__details {
  @include txt--s($main-font);
  margin: 0 $spc-tiny;
  width: 75%;
  text-align: left;
  word-break: break-word;
  &__file-type {
    font-weight: $fw--bold;
  }
}

/* Télésuivi */

.sub-filter__header--telesuivi {
  margin: 0 0 1rem 0;
  p {
    text-align: left;
  }
}

.telesuivi__header {
  margin-bottom: 1rem;
}

.patient-single__messages__header {
  .block-heading {
    float: left;
  }
  &__link {
    float: right;
    margin: 1rem;
  }
  &__icon {
    width: 1rem;
    margin-left: $spc-tiny;
  }
}

.button--telesuivi {
  padding: 0.75rem;
  min-width: 2.875rem;
  &--selected {
    background-color: $b-mc-med;
    color: $b-nc-white;
  }
}

// Button interventions more

.interventions__btn--more {
  border: none;
  background: transparent;

  & > td {
    border: none;
    padding: 16px 0;
  }
}
