$margin-login: 8rem;

// CONNEXION
.registration {
  margin: $spc-medium 0 $spc-xxx-large 0;

  @media screen and (min-width: $screen-sm) {
    margin: $spc-xxx-large 0 2 * $spc-xxx-large 0;
    padding-top: 1rem;
  }
}
.login__container {
  margin-top: $spc-xxx-large;
}

.login-extranet-no-account {
  & > div {
    border-top: 2px solid rgb(227, 227, 227);
    padding: $spc-medium 0;
  }
}

.login__forget-password {
  margin: calc($spc-tiny / 2) 0.5rem $spc-medium auto;
  @include txt--s($main-font);
  font-weight: 700;
  text-align: right;
  display: block;
}

.reset__password {
  &__body {
    padding: 0rem 2rem 1rem 1.5rem;
  }

  &__actions {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding: 0.5rem;
    border-top: 1px solid #ece9e9;
  }
}

.credentials {
  &__error {
    &--verify {
      margin-top: 8rem;
    }

    &--connexion {
      display: block;
      margin: $spc-x-large auto;
    }
  }
}

.pages-login__container {
  margin: 4rem 0;
}

.pages-login__background {
  width: 0;
  height: 0;
}

@media screen and (min-width: $screen-sm) {

  .pages-login__container {
    margin: 0;
  }

  .pages-login__background {
    width: 100%;
    height: calc(100vh - $margin-login);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
