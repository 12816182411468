.doughnut {
  position: relative;
  &-info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    &-total, &-label {
      display: block;
      text-align: center;
    }
    &-total {
      font-size: 3.5rem;
      line-height: 1;
    }
  }
}