@keyframes smooth-scale-opa {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes fade-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

@keyframes bounce--1 {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1.2);
  }
}

/* Bounce--horizontal */

@keyframes bounce--horizontal{

  50% {
      transform: translateX(-60px);
  }

  100% {
      transform: translateX(0px);
  }
}

// Keyframes Loader
@keyframes loader {
  0% {
   width:0;
  }
  50% {
   width:40%;
  }
  75% {
   width:60%;
  }
  100% {
   width:100%;
  }
}

@keyframes pin--bounce {
  0%{
   transform:translateY(0);
  }
  100% {
    transform:translateY(-10%);
  }
}

@keyframes pin__ground--opa {
  0%{
   opacity:.5;
  }
  100% {
    opacity:.25;
    transform:scaleX(.9)
  }
}

@keyframes stats-scaleY {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(.75);
  }
}

@keyframes loader--basic-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes loader--basic-2 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

@keyframes loader--basic-2--s {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(10px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

@keyframes loader--basic-3 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-24px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

@keyframes loader--basic-3--s {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(-10px, 0, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

@keyframes loader--basic-4 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, 24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

@keyframes loader--basic-4--s {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, 10px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

@keyframes loader--basic-5 {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, -24px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

@keyframes loader--basic-5--s {
	0%   { transform: translate3d(0, 0, 0) scale(1); }
	50%  { transform: translate3d(0, -10px, 0) scale(.5); }
	100% { transform: translate3d(0, 0, 0) scale(1); }
}

// Icon animations

@keyframes Bubbles {
  0%{
    transform:translateX(-50%) translateY(-50%) scale(1.5);
  }
  10% {
     opacity:1;
  }
 100% {
    opacity:0;
   transform:translateX(-50%) translateY(-50%) scale(2.5);
  }
}

@keyframes mail-anim {
  0% {
    opacity:1;
    transform:translateX(-50%) translateY(-50%) scale(0);
  }
  20% {
    opacity:1;
    transform:translateX(-50%) translateY(-50%) scale(1.1);
  }
  30% {
    opacity:1;
    transform:translateX(-50%) translateY(-50%) scale(1);
  }
  40% {
    opacity:1;
    transform:translateX(-50%) translateY(-50%) scale(1);
  }
  45% {
    opacity:1;
    transform:translateX(-55%) translateY(-50%) scale(1);
  }
  100% {
    opacity:0;
    transform:translateX(200%) translateY(-50%) scale(1);
  }
}

@keyframes check-anim {
   0% {
    stroke-dashoffset: 150;
  }
  66% { 
    stroke-dashoffset: 150;
  }
  100% {  
    stroke-dashoffset: 0;
  }
}

@keyframes warning-anim{
   0% {
    stroke-dashoffset: 200;
  }
   25% {
    stroke-dashoffset: 200;
  }
  100% { 
    stroke-dashoffset: 0;
  }
}

@keyframes appear{
  0% {
    transform:scale(0);
  }
  70% { 
    transform:scale(0);
  }
  85% {
    transform:scale(1.1);
  }
  100% { 
    transform:scale(1);
  }
}