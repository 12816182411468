.tabs__navigation {
  &__items {
    margin-bottom: 0;
    border-bottom: 1px solid $b-nc-med;

    @media only screen and (min-width: $screen-sm) {
      display: flex;
      flex-flow: row wrap;
    }
  }

  &__items.full--mobile {
    display: flex;
    flex-flow: row nowrap;
  }

  &__items.full--column {
    @media only screen and (min-width: $screen-sm) {
      display: flex;
      flex-flow: column wrap;
    }

    .tabs__navigation__button {
      @media only screen and (min-width: $screen-sm) {
        text-align: left;
      }
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;
    width: 100%;

    @media only screen and (min-width: $screen-sm) {
      flex: 0 0 auto;
      width: initial;
    }

    &:first-child {
      button {
        &::before {
          display: none;
        }
      }
    }

    &--active + .tabs__navigation__item button::before {
      display: none;
    }

    svg {
      vertical-align: middle;
      margin-right: 0.25rem;
    }
  }

  &__button {
    position: relative;
    padding: 1rem 1.5rem 1rem 1rem;
    color: $b-nc-60;
    background: none;
    border: none;
    cursor: pointer;
    transition: $tr--m;
    width: 100%;
    text-align: left;

    @media only screen and (min-width: $screen-sm) {
      width: initial;
      text-align: center;
    }

    &::before {
      content: "";
      height: 1.875rem;
      width: 1px;
      position: absolute;
      left: -0.5px;
      top: 50%;
      background: $b-nc-25;
      transform: translateY(-50%);
    }

    &::after {
      content: "";
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      background: $b-nc-light;
      opacity: 1;

      @media only screen and (min-width: $screen-sm) {
        opacity: 0;
      }
    }

    &:hover {
      background-color: rgba($b-mc-med, 0.1);
      transition: $tr--m;

      &::before {
        display: none;
      }
    }

    &:focus {
      background-color: rgba($b-mc-med, 0.1);
      transition: $tr--m;

      &::after {
        opacity: 1;
      }
    }

    &--active {
      background-color: rgba($b-mc-med, 0.1);
      color: $b-mc-med;
      transition: $tr--m;

      &::before {
        display: none;
      }

      &::after {
        opacity: 1;
        background: $b-mc-med;
      }
    }
  }

  &--modal {
    border-top: 1px solid $b-nc-med;

    .tabs__navigation__items {
      margin-top: 0;
    }

    .tabs__navigation__item {
      justify-content: center;


      @media only screen and (min-width: $screen-sm) {
        flex: 1;
      }
    
      button {
        width: 100%;
      }
    }
  }
}

.tabs-form__navigation {
  padding: 1rem;
  background: $b-nc-25;

  &__items {
    margin: 0 auto;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% + 1px);
      width: 2px;
      background: linear-gradient(180deg, rgba($b-mc-med, 1) 0%, rgba($b-mc-med, 0.2) 100%);

      @media only screen and (min-width: $screen-m) {
        top: calc(50% - 1px);
        bottom: auto;
        left: 0;
        right: 0;
        width: auto;
        height: 2px;
        background: linear-gradient(90deg, rgba($b-mc-med, 1) 0%, rgba($b-mc-med, 0.2) 100%);
      }
    }

    @media only screen and (min-width: $screen-m) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }

  &__item {
    position: relative;
    display: flex;
    padding: 0;
    height: 2.5rem;
    flex-flow: row nowrap;
    align-items: center;
    background: $b-nc-25;
    border: 2px solid $b-nc-50;
    border-radius: 4rem;
    overflow: hidden;
    transition: $tr--m;
    z-index: 1;

    &:not(:last-child) {
      margin-bottom: 1rem;

      @media only screen and (min-width: $screen-m) {
        margin-bottom: 0;
      }
    }

    &--active {
      color: $b-nc-00;
      background: $b-mc-med;
      border-color: $b-mc-med;
      transition: $tr--m;

      .tabs-form__navigation__item__title::before {
        background: $b-nc-00;
        transition: $tr--m;
      }
    }

    &--completed {
      color: $b-mc-med;
      border-color: $b-mc-med;
      transition: $tr--m;

      .tabs-form__navigation__item__title::before {
        background: $b-mc-med;
        transition: $tr--m;
      }
    }

    &__edit {
      padding: 0;
      margin: -1px -1px -1px auto;
      align-self: stretch;
      background: $b-mc-med;
      width: 2.25rem;
      border: none;
      cursor: pointer;
      transition: $tr--m;

      &:hover {
        background: $b-uc-positive;
      }
    }

    &__icon {
      display: flex;
      margin: 0 0.5rem;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    &__title {
      position: relative;
      padding: 0 0.5rem;
      font-size: 0.75rem;

      &::before {
        content: "";
        position: absolute;
        left: -0.5px;
        top: 50%;
        width: 1px;
        height: 1.5rem;
        background: $b-nc-50;
        transition: $tr--m;
        transform: translateY(-50%);
      }
    }
  }
}
