.prescriber-medical-device {

  &__top{
    margin-bottom: -1px;
    
    @media only screen and (min-width: $screen-s) {
      display: flex;
      flex-flow: row nowrap;
    }
  }

  &__btn {
    flex: 0 0 1.5rem;
    border: none; background: none;
    cursor: pointer;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__img {
    margin: 0 auto 1rem auto; max-width: 4.5rem; width: 100%;

    @media only screen and (min-width: $screen-s) {
      display: flex; margin: 0; margin-right: 1rem;
      flex-flow: row nowrap;
    }

    @supports(object-fit: cover) {
      height: 4.5rem;

      img {
        object-fit: cover; height: 100%;
      }
    }
  }
}