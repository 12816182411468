//Loader Basic
@mixin animationLoader($size, $element) {
  animation: getLoaderAnimationName($size, $element) 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

@function getLoaderAnimationName($size, $element) {
  $str-size: "";
  @if ($size == "s") {
    $str-size: "--s";
  }
  $name: #{"loader--basic-" + $element + $str-size};
  @return $name;
}

.loader--basic {
  width: $spc-x-large;
  height: $spc-x-large;
  margin: 0 auto;
  @include animationLoader("m", 1);

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    margin: auto;
    width: calc($spc-small / 2);
    height: calc($spc-small / 2);
    background: $b-mc-med;
    border-radius: 50%;
  }

  &::before {
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    @include animationLoader("m", 2);
  }

  &::after {
    top: 0;
    left: auto;
    bottom: 0;
    right: 0;
    @include animationLoader("m", 3);
  }

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: $spc-x-large;
    width: $spc-x-large;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      margin: auto;
      width: calc($spc-small / 2);
      height: calc($spc-small / 2);
      background: $b-mc-med;
      border-radius: 50%;
    }

    &::before {
      top: 0;
      left: 0;
      bottom: auto;
      right: 0;
      @include animationLoader("m", 4);
    }

    &::after {
      top: auto;
      left: 0;
      bottom: 0;
      right: 0;
      @include animationLoader("m", 5);
    }
  }

  &--small {
    width: 1.188rem;
    height: 1.188rem;

    &::before,
    &::after {
      width: calc($spc-small/4);
      height: calc($spc-small/4);
    }

    &::before {
      @include animationLoader("s", 2);
    }

    &::after {
      @include animationLoader("s", 3);
    }

    span {
      width: 1.188rem;
      height: 1.188rem;

      &::before,
      &::after {
        width: calc($spc-small/4);
        height: calc($spc-small/4);
      }

      &::before {
        @include animationLoader("s", 4);
      }

      &::after {
        @include animationLoader("s", 5);
      }
    }
  }

  &--nc--00 {
    &::before,
    &::after {
      background: $b-nc-00;
    }
    span::before,
    span::after {
      background: $b-nc-00;
    }
  }
}

// Loader Progress General (Carto & Stats)
.container-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-loader__loader {
  border-radius: $border-rds--xl;
  width: $spc-x-large * 5;
  height: $spc-tiny;
  box-shadow: inset 0px 0px 0px 2px $b-nc-med;
  margin-bottom: $spc-tiny;
  position: relative;
  overflow: hidden;

  span {
    display: block;
    background-color: $b-mc-med;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
}

.container-loader__loader--patternlab > span {
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
}

.container-loader__text {
  margin: 0;
}

// Loader Carto
.container-loader__ic {
  fill: $b-mc-med;
  width: $spc-xx-large;
  height: auto;
  animation: pin--bounce 3s infinite ease-in-out alternate;
}

.container-loader__ic__ground {
  width: $spc-small;
  height: calc($spc-small / 2);
  background-color: $b-mc-dark;
  border-radius: $border-rds--circle;
  opacity: 0.5;
  filter: blur(0.75px);
  margin-bottom: $spc-x-small;
  animation: pin__ground--opa 3s infinite ease-in-out alternate;
}

// Loader Stats
.container-loader__stats {
  width: $spc-xx-large;
  height: $spc-xx-large;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
  margin-bottom: $spc-x-small;
}

.container-loader__stats--1,
.container-loader__stats--2,
.container-loader__stats--3 {
  background-color: $b-mc-med;
  width: calc($spc-small / 2);
  border-radius: $border-rds--l;
  transform-origin: center bottom;
}

.container-loader__stats--1 {
  height: 33%;
  animation: stats-scaleY 1.5s infinite ease-in-out alternate;
}

.container-loader__stats--2 {
  height: 66%;
  animation: stats-scaleY 1.5s infinite 0.1s ease-in-out alternate;
}

.container-loader__stats--3 {
  height: 100%;
  animation: stats-scaleY 1.5s infinite 0.2s ease-in-out alternate;
}
