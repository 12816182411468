// Download Input Range

.block--download-input-range {
  margin-bottom: $spc-tiny;
  width: 100%;
  overflow: auto;
}

.block--download-input-range--left {
  width: 33.33%;
  float: left;
  height: 118px;
  padding: 1rem;
  border: 2px solid $b-nc-light-bg;
  background-color: $b-nc-light-bg;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.block--download-input-range--left__date {
  color: $b-nc-med-dark;
  @include txt--s($main-font);
  margin-top: 0;
  margin-bottom: $spc-tiny;
}

.block--download-input-range--right {
  width: 66.66%;
  height: 118px;
  float: right;
  position: relative;
  border: 2px solid $b-nc-light-bg;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: $b-nc-white;
}

.block--download-input-range--right__input-range {
  height: auto;
  width: 100%;
  position: relative;
  margin-bottom: $spc-tiny;
  padding: 1.5rem 1.5rem $spc-tiny 1.5rem;
  .input--range__indications {
    width: calc(100% - 80px);
    position: absolute;
    bottom: -30px;
    right: 0;
    cursor: auto;
    .input--range__indications__text {
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.block--download-input-range--right__legend {
  border-top: 2px solid $b-nc-light-bg;
  padding: 3px 1.5rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  .legend--color {
    float: left;
  }
  .legend--color--mc-med {
    margin-left: $spc-medium;
  }
}

@media (max-width: 1000px) {
  .block--download-input-range--right,
  .block--download-input-range--left {
    width: 100%;
    float: none;
  }
  .block--download-input-range--left {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .block--download-input-range--right {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
}
