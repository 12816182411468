.coaching--item {
  border-radius: $border-rds--l;
  border: 1px solid $b-nc-light; 
  
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &__left {
    width: 100%;
    background: $b-nc-light;
    border: none; border-radius: $border-rds--l $border-rds--l 0 0;

    @media only screen and (min-width: $screen-sm) {
      border-radius: $border-rds--l 0 0 $border-rds--l;
    }
  }

  &__right {
    width: 100%;
    border: none; border-radius: 0 0 $border-rds--l $border-rds--l;

    @media only screen and (min-width: $screen-sm) {
      border-radius: 0 $border-rds--l $border-rds--l 0;
    }
  }

  .btn--download {
    width: 100%;
  }
  
  .block--download-input-range--left {
    border: none;
    float:none;
  }
}