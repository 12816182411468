.header-back {
  flex-wrap: wrap;

  &__informations {
    flex-wrap: wrap;

    &__profil {
      margin-top: $spc-x-small;

      @media only screen and (min-width: $screen-sm) {
        margin: 0 0 0 $spc-x-small;
      }
    }
  }
}

.header-list {
  position: relative;

  @media only screen and (min-width: $screen-m) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &__tabs {
    display: flex;
    margin-top: 1rem;
    flex-flow: column;
    align-items: center;

    @media only screen and (min-width: $screen-sm) {
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    @media only screen and (min-width: $screen-m) {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
}

.header-resource {
  position: relative;
  padding-top: 0.5rem;
  margin-bottom: $spc-x-small;

  @media only screen and (min-width: $screen-xs) {
    padding-top: 0;
    margin-bottom: 0;
  }

  &__btn-close {
    position: absolute;
    top: -1rem;
    left: -1rem;
    @include z-index("absolute");
  }

  &__patient {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: $screen-xs) {
      flex-flow: row nowrap;
      align-items: start;
    }
  }

  &__avatar {
    margin: 0.5rem;
    flex: 0 0 4.5rem;

    @media only screen and (min-width: $screen-xs) {
      margin: 1rem;
    }
  }

  &__informations {
    display: flex;
    flex: 1 1 100%;
    flex-flow: column nowrap;
    justify-content: center;
    margin: 0.5rem;
    text-align: center;

    @media only screen and (min-width: $screen-xs) {
      text-align: left;
      margin: 1rem 0;
    }
  }

  &__more {
    @media only screen and (min-width: $screen-xs) {
      display: flex;
      flex-flow: row wrap;
    }

    .label-value {
      margin: 0.5em 0.5rem 0 0;

      @media only screen and (min-width: $screen-xs) {
        margin: 0;
        margin-right: 1rem;
      }

      @media only screen and (min-width: $screen-s) {
        margin-right: 2rem;
      }
    }
  }

  &__name {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 0.5rem;

    @media only screen and (min-width: $screen-xs) {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
  }

  &__client-number {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: $screen-xs) {
      justify-content: flex-start;
    }

    svg {
      margin-left: 0.5rem;

      @media only screen and (min-width: $screen-s) {
        margin: 0;
      }
    }
  }

  &__metrics {
    display: flex;
    margin-top: 0.5rem;
    flex-flow: row wrap;
    align-self: stretch;
    flex: 1;

    @media only screen and (min-width: $screen-l) {
      margin-top: 0;
      justify-content: flex-end;
    }

    .label-value--big {
      flex: 1 0 50%;
      min-width: 6rem;
      border-top: 1px solid $b-nc-25;

      @media only screen and (min-width: $screen-xs) {
        flex-basis: 20%;

        &:not(:first-child) {
          border-left: 1px solid $b-nc-25;
        }
      }

      @media only screen and (min-width: $screen-l) {
        flex-grow: 0;
        border-top: none;
        border-left: 1px solid $b-nc-25;
      }

      &--warning,
      &--caution,
      &--positive {
        border-top: none;
        border-left: none;

        + .label-value--big {
          border-left: none;
        }
      }

      &:first-child {
        border-bottom-left-radius: $border-rds--l;

        @media only screen and (min-width: $screen-l) {
          border-bottom-left-radius: 0;
        }
      }

      &:last-child {
        border-bottom-right-radius: $border-rds--l;

        @media only screen and (min-width: $screen-l) {
          border-top-right-radius: $border-rds--l;
        }
      }
    }
  }

  &__treatment {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}
