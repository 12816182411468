// VARIABLES
//susy configuration
$susy: (
  gutter-position: split,
  gutters: 0.2
);

/**
 * @tokens Colors
 * @presenter Color
 */

/////// COLORS //////
// global
$b-mc-light: #4e77af; /* Primary light*/
$b-mc-med: #0f2685;
$b-mc-med-10: rgba(15, 38, 133, 0.1);
$b-mc-dark: darken($b-mc-med, 5%);

// accent
$b-ac-1: #b47701; //dark yellow
$b-ac-2: #b20106; //dark red
$b-ac-3: #2784d8; //blue
$b-ac-4: #735aff;
$b-ac-5: #ffd200;
$b-ac-6: #51a9f7;
$b-ac-7: #ebb902;
$b-ac-8: #4e189d;

//neutral
$b-nc-white: #ffffff;
$b-nc-light-bg: #f3f3f3;
$b-nc-light: #ece9e9;
$b-nc-med: #999999;
$b-nc-med-dark: #666666;
$b-nc-dark: #333333;
$b-nc-black: #000000;

// new neutrals
$b-nc-00: #ffffff;
$b-nc-15: #f3f3f3;
$b-nc-25: #ece9e9;
$b-nc-40: #bfbfbf;
$b-nc-50: #999999;
$b-nc-60: #666666;
$b-nc-85: #333333;
$b-nc-100: #000000;

// utility
$b-uc-positive: #00c2a3;
$b-uc-caution: #ff8700;
$b-uc-caution--dark: darken($b-uc-caution, 8%);
$b-uc-warning: #ed020a;
$b-uc-warning--dark: darken($b-uc-warning, 10%);
$b-uc-positive-renewals: #d3ebea;
$b-uc-caution-renewals: #ffe7cc;
$b-uc-warning-renewals: #fcd9da;

/**
 * @tokens Spacings
 * @presenter Spacing
 */

/////// SPACING ///////
$spc-x-tiny: 0.2rem; //4px
$spc-tiny: 0.5rem; //8px
$spc-x-small: 1rem; //16px
$spc-small: 1.5rem; //24px
$spc-medium: 2rem; //32px
$spc-large: 2.5rem; //40px
$spc-x-large: 3rem; //48px
$spc-xx-large: 3.5rem; //56px
$spc-xxx-large: 4rem; //64px

/**
 * @tokens Borders radius
 * @presenter BorderRadius
 */

/////// BORDER-RADIUS ///////
$border-rds--null: 0;
$border-rds--l: 4px;
$border-rds--xl: 8px;
$border-rds--xxl: 16px;
$border-rds--circle: 100%;

/**
 * @tokens Shadows
 * @presenter Shadow
 */

/////// BOX SHADOWS ///////
//neutral
$elevation-2-dp: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
$elevation-4-dp: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
$elevation-8-dp: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 3px rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.2); //Hover state of 2-dp
$elevation-16-dp: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.2); //Hover state of 4-dp or 8dp

//global colors
//light
$elevation-2-dp-mc-light: 0 2px 4px 0 rgba(0, 134, 163, 0.14), 0 3px 4px 0 rgba(0, 134, 163, 0.12), 0 1px 5px 0 rgba(0, 134, 163, 0.2);
$elevation-8-dp-mc-light: 0 8px 10px 1px rgba(0, 134, 163, 0.14), 0 3px 14px 3px rgba(0, 134, 163, 0.12), 0 4px 5px 0 rgba(0, 134, 163, 0.2);

/////// CONTAINERS ///////
$small-container: 48.75em;

/**
 * @tokens Transitions
 * @presenter Easing
 */

/////// TRANSITIONS ///////
$tr--s: all 0.3s ease;
$tr--m: all 0.5s ease;
$tr--l: all 0.75s ease-out;

/////// VERTICAL RYTHM ///////
$base-font-size: 1rem;
$base-line-height: 1.25;
$small-text: 0.75rem;
$body-text: $base-font-size;
$large-text: 1.25rem;
$main-heading: 2.25rem;
$secondary-heading: 1.75rem;
$third-heading: 1.25rem;
$fourth-heading: 1.125rem;
$fifth-heading: 1rem;

/**
 * @tokens Fonts
 * @presenter Font
 */

// font call
$main-font: "Heebo", arial, sans-serif;
$secondary-font: "Dosis", arial, sans-serif;

/**
 * @tokens Font weights
 * @presenter FontWeight
 */

// font weight
$fw--reg: 400;
$fw--med: 500;
$fw--bold: 700;

// z-indexes
// Usage : .modal { z-index : map-get($z-index, modal) }
$z-index: (
  element--absolute: 5,
  header: 10,
  overlay: 50,
  cookie-message: 100,
  modal: 200,
  upper: 9999
);
