.isLoading{
  // HOME
  .carousel{
    margin: 2rem auto;
    height: 622px;
    background: $b-nc-white;
  }

  // SEARCH BAR
  .search-bar{
    width: 80em;
    margin: 2rem auto;
    height: 149px;
    background: $b-nc-white;
  }

  // BASTIDE ACCESS
  .patients-count, .posts-count{
    width: 25em;
    height: 45px;
    margin-bottom: 8rem;
    background: $b-nc-white;
  }
  .bastide-table{
    width: 80em;
    margin: 2rem auto;
    height: 100vh;
    background-image: linear-gradient(transparent 20%, $b-nc-white 0%);
    background-size: 84px 84px;
  }

  // LISTS
  .list-main-item{
    width: 80em;
    margin: 2rem auto;
    height: 610px;
    background: $b-nc-white;
  }
  .list-second-items{
    width: 80em;
    margin: 2rem auto;
    height: 460px;
    background: $b-nc-white;
  }

  // SINGLE PAGES
  .single-page-header{
    width: 78em;
    height: 608px;
    margin: 0 auto;
    background-color: $b-nc-light-bg;
  }

  .single-page-sidebar{
    margin-top: -15%;
    position: relative;
    padding: 1rem 3.25rem;
    float: left;
  }

  .single-page-sidebar-content{
    background: $b-nc-light-bg;
    height: 700px;
    padding: 1.875rem;
  }

  .single-page-content{
    margin-top: 1rem;
    height: 700px;
  }
}
