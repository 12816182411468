.title-multiple-input {
  margin-bottom: 5rem;
  .label--radio-btn,
  .label--checkbox {
    margin-bottom: 0.75rem;
  }
  .input--radio-btn--textarea,
  .input--checkbox--textarea {
    margin-bottom:$spc-tiny;
  }
  hr {
    background-color: $b-nc-med;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.title-multiple-input--sub-part {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.coaching--response__value {
  padding: 0.5rem;
  border-left: 2px solid $b-mc-med;
  font-weight: 700;
  color: #000;
}