@media print {
  .global-layout{
    &__body {
      padding: 0;
    }
  }

  .card {
    padding: 0;
    box-shadow: none;
  }

  .print{
    &--display--none{
      display: none !important;
    }

    &--display--block{
      display: block !important;
    }
  }
}
